@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");

body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

.navbar {
    transition: all 0.5s;
    font-family: "Roboto", sans-serif !important;
    background: #fff;
    height: 75px;
}

.navbar .nav-link {
    color: #000;
    text-align: center;
}

.navbar .nav-link:hover,
.navbar .nav-link:focus {
    color: #189339;
    text-decoration: none;
}

.navbar .navbar-brand {
    color: #fff;
}

.navbar-logo {
    height: 3rem;
}

.navbar-logo1 {
    width: 100%;
}

.navbars-logo {
    height: 3rem;
    display: none;
}

.ml-10 {
    margin-left: 10px;
}

.download-logo {
    height: 2rem;
}

.navbar.active {
    background: #fff;
    box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.1);
}

.navbar.active .nav-link {
    color: #000;
}

.navbar.active .nav-link:hover,
.navbar.active .nav-link:focus {
    color: #189339;
    text-decoration: none;
}

.navbar.active .navbar-brand {
    color: #555;
}

.section_bg_gray {
    background-color: #fff;
    /* padding-bottom: 10px; */
}

.candp-page-heading {
    text-decoration: underline dashed 2px;
    text-underline-offset: 10px;
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 60px;
    text-align: center;
    color: #083b7c;
}

.mediaawards-top-heading {
    font-family: "Open Sans", sans-serif !important;
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    text-align: center;
    margin: auto;
}

.project-page-head {
    text-decoration: underline dashed 2px;
    text-underline-offset: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 48px;
    text-align: center;
    color: rgba(8, 59, 124, 1);
}

.custom-head-small {
    text-decoration: underline dashed 2px rgba(8, 59, 124, 1);
    text-underline-offset: 10px;
    font-family: "Open Sans", sans-serif !important;
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 60px;
    text-align: center;
    color: rgba(8, 59, 124, 1);
    margin: 0;
    position: relative;
    text-transform: uppercase;
}

.custom-head-small-services-home {
    border: 1px solid transparent;
    background-color: #e9f1fb;
    border-radius: 5px;
    margin: 0;
    color: rgb(30, 109, 15);
    font-size: 30px;
    position: relative;
    font-weight: bold;
    letter-spacing: 2px;
    font-family: "Roboto", sans-serif !important;
}

.head-blog {
    font-family: "Open Sans", sans-serif !important;
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 72px;
    text-align: center;
    color: #000000;
}

.head-blog-sub {
    font-family: "Open Sans", sans-serif !important;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 48px;
    color: #102540;
    flex-wrap: wrap;
    word-wrap: break-word;
}

.head-blog-sub-heading {
    font-family: "Open Sans", sans-serif !important;
    font-style: normal;
    font-size: 20px;
    line-height: 35px;
    color: #102540;
    flex-wrap: wrap;
    word-wrap: break-word;
}

.trending-blog-title {
    font-family: "Open Sans", sans-serif !important;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: rgba(0, 0, 0, 0.8);
}

.text-blog {
    font-family: "Open Sans", sans-serif !important;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
}

.text-blog-sub {
    font-family: "Open Sans", sans-serif !important;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 27px;

    color: rgba(0, 0, 0, 0.67);
}

.date-blog {
    font-family: "Open Sans", sans-serif !important;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: #000000;
}

.text-short-blog {
    text-align: left;
    font-size: medium;
    color: #000;
    font-family: "Open Sans", sans-serif !important;
}

.fonts {
    font-family: "Open Sans", sans-serif !important;
}

.fonts-poppins {
    font-family: poppins;
}

.font {
    font-family: "Roboto", sans-serif !important;
}

.pt-30 {
    padding-top: 3rem;
}

.pt-40 {
    padding-top: 4rem;
}

.pb-30 {
    padding-bottom: 3rem;
}

.pb-10 {
    padding-bottom: 1rem;
}

.service-card {
    padding: 15px;
    border-radius: 5px;
    position: relative;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.service-card:hover {
    -webkit-box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.2);
}

.service-card .service-card-image {
    padding: 15px 0;
}

.service-card .service-card-image img {
    height: 5rem;
}

.service-card .title {
    color: #555;
    margin-bottom: -10px;
    font-size: 1.2rem;
    font-family: "Roboto", sans-serif !important;
}

.service-line {
    width: 2rem;
    height: 2px;
    background-color: #1569c7;
    display: inline-block;
    font-family: "Open Sans", sans-serif !important;
}

.vertical-mid {
    margin: 0;
    position: absolute;
    top: 35%;
    right: 5%;
    left: 5%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.contact-form {
    border-radius: 5px;
}

.verticals-mid {
    position: relative;
    text-align: right;
    justify-content: right;
    font-weight: 500;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.navbar-brands {
    width: 100%;
}

.verify-h4 {
    color: #EB9481;
    text-align: center;
    width: 100%;
}

.verify-h5 {
    text-align: center;
    width: 100%;
}

.verify-h6 {
    text-align: left;
    width: 100%;
}

.span-account {
    color: #EB9481;
    text-align: center;
}

.create-account {
    color: #EB9481;
    font-weight: 600;
    text-decoration: underline;
    cursor: pointer;
}

.custom-btn {
    font-size: 16px;
    font-weight: 500;
    padding: 10px 30px;
    color: #ffffff;
    background-color: #1569c7;
}

#blog {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 75px;
    background: rgba(203, 226, 255, 0.15);
}

#clientsparters {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 75px;
    background: rgba(203, 226, 255, 0.15);
}

#rate-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 600px;
}

#rate-list::before {
    content: "";
    position: absolute;
    background: url("../public/assets/images/rate-list-ecowrap.png") no-repeat center center/cover;
    top: 75px;
    left: 0px;
    width: 100%;
    height: 600px;
    z-index: -1;
    opacity: 0.88;
    background-size: 100% 100%;
}

/* --------------------------------------------About Us page start-------------------------------------------- */
#about {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 600px;
}

#about::before {
    content: "";
    position: absolute;
    background: url("../public/assets/images/aboutUs/aboutus-image.png") no-repeat center center/cover;
    top: 75px;
    left: 0px;
    width: 100%;
    height: 600px;
    z-index: -1;
    opacity: 1.88;
}

.about-images {
    width: 90%;
    border-radius: 10%;
}

.service-cardss .service-card-img {
    padding: 15px 0;
}

.service-cardss .service-card-img img {
    height: 15rem;
    border: #000000;
    border-radius: 20%;
}

.service-cardss .title {
    color: #555;
    margin-bottom: -10px;
    font-size: 1.2rem;
    font-family: "Roboto", sans-serif !important;
}

.service-cardsss .service-card-img {
    padding: 30px 0;
}

.service-cardsss .service-card-img img {
    width: 100%;
    border-radius: 50%;
}

.service-cardsss .title {
    color: #555;
    margin-bottom: -10px;
    font-size: 1.2rem;
    font-family: "Roboto", sans-serif !important;
}

.title-service {
    background: #a8d8ad;
    box-shadow: 0px 3.69231px 3.69231px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    border: 1px solid #a0dfa0;
    width: 250px;
    max-width: 100%;
    padding: 6px;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    font-family: "Open Sans", sans-serif !important;
    font-style: normal;
    font-weight: 500;
    text-align: center;
    color: #000000;
}

/* --------------------------------------------About Us page end-------------------------------------------- */

/* --------------------------------------------Blog page start-------------------------------------------- */
.m-auto {
    margin: auto;
}

.max-width-1 {
    max-width: 80vw;
}

.my-2 {
    margin-top: 32px !important;
    margin-bottom: 32px !important;
}

.content-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 50px;
    z-index: 1;
}

.content-right {
    display: flex;
    align-items: center;
    justify-content: center;
}

.content-right img {
    height: 355px;
    border: 2px solid black;
    border-radius: 200px;
}

.home-articles {
    padding: 18px;
    background-color: rgb(248, 238, 238, 0.5);
    margin-top: 23px;
    position: relative;
}

.year-box {
    position: absolute;
    right: 0px;
    top: 100px;
    width: 234px;
    height: 255px;
    font-size: 18px;
}

.year-box div {
    margin: 12px 0px;
}

.home-article {
    display: flex;
    margin: 25px;
}

.home-article img {
    width: 300px;
}

.home-article-content {
    align-self: center;
    padding: 25px;
}

.home-article-content a {
    text-decoration: none;
    color: black;
}

/* --------------------------------------------Blog page end-------------------------------------------- */

/* --------------------------------------------Contact Us page start-------------------------------------------- */
#contact {
    margin-top: 75px;
}

.contact-icon {
    background: #1569c7;
    padding: 1rem;
    color: #ffffff;
    border-radius: 50%;
    height: 65px;
    width: 65px;
    display: block;
    margin: 1rem auto;
}

.contact-icon2 {
    color: black;
    font-size: 2rem;
    bottom: 10px;
}

.contact-icon i {
    font-size: 2rem;
}

/* --------------------------------------------Contact Us page end-------------------------------------------- */

/* --------------------------------------------Error page start-------------------------------------------- */
.rows {
    margin: 0;
}

.main_header_left {
    height: 43rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.main_header_left p {
    font-size: 1.5rem;
}

.main_header_left h1 {
    font-size: 3rem;
    margin: 1rem 0 4rem 0;
    text-transform: capitalize;
}

.main_header_left .txt_clr {
    color: #1569c7;
}

.main_header_left button {
    border: none;
    padding: 0.8rem 1.8rem;
    font-size: 0.8rem;
    border-radius: 5px;
    text-transform: uppercase;
    box-shadow: 0 8px 6px -6px black;
    outline: none;
}

.main_header_left button:hover {
    box-shadow: 0 8px 6px -6px var(--main-color);
}

.main_header_left .errimg img {
    width: 50rem;
    height: 25rem;
}

/* --------------------------------------------Error page end-------------------------------------------- */

/* --------------------------------------------Home page start-------------------------------------------- */
#home {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url("../public/assets/images/home/home-ecowrap1.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100vh;
}

.mother-teresa {
    text-align: right;
    font-weight: bold;
    font-size: 1.5rem;
    font-style: italic;
    font-family: "Open Sans", sans-serif !important;
}

.header-arrow {
    background: white;
    padding: 12px 20px;
    color: #1569c7;
    font-size: 24px;
    border-radius: 50%;
    box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.2);
    position: relative;
    bottom: 20px;
}

.pt-20 {
    padding-top: 2rem;
}

.pt-30 {
    padding-top: 3rem;
}

.pt-40 {
    padding-top: 4rem;
}

.pt-50 {
    padding-top: 5rem;
}

.pt-60 {
    padding-top: 6rem;
}

.pt-55 {
    padding-top: 5.5rem;
}

.pb-50 {
    padding-bottom: 5rem;
}

.home-images {
    max-height: 20rem;
    width: 20rem;
    border-radius: 50%;
}

.home-para {
    font-weight: bolder;
    font-size: x-large;
}

.home {
    display: flex;
    margin-top: 300px;
    padding: 10px;
    position: relative;
    height: 11.5%;
    background-color: rgba(178, 172, 172, 0.5);
}

.what-we-do {
    display: flex;
    padding: 10px;
    position: relative;
    background: #E1F7E9;
    height: 385px;
    justify-content: center;
    align-items: center;
}

.what-we-do-text {
    color: #189339;
    text-align: center;
    font-size: 30px;
    font-family: "Roboto", sans-serif !important;
    font-style: normal;
    font-weight: 800;
    line-height: 135.938%;
    padding: 20px 0px;
}

.what-span {
    color: #189339;
}

.we-do-span {
    color: #000;
}

.what-we-do-sub-text {
    color: #000;
    text-align: center;
    font-size: 18px;
    font-family: "Roboto", sans-serif !important;
    font-style: normal;
    font-weight: 500;
    line-height: 135.938%;
}

.mentors {
    display: flex;
    margin-top: 32px;
    padding: 10px;
    position: relative;
    background: #E1F7E9;
    height: 365px;
}

.mentors-card {
    padding: 15px;
    border-radius: 5px;
    position: relative;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.mentors-card .mentors-card-image {
    padding: 15px 0;
}

.mentors-card .mentors-card-image img {
    height: 6rem;
}

.mentors-card .title {
    color: #555;
    margin-bottom: -10px;
    font-size: 1.2rem;
    font-family: "Roboto", sans-serif !important;
    justify-content: center;
}

.home-image {
    max-height: 10rem;
    width: 10rem;
    border-radius: 10%;
}

.home-download {
    color: #000;
    text-decoration: none;
}

.home-download i {
    color: #000;
    text-decoration: none;
}

/* --------------------------------------------Home page end-------------------------------------------- */

/* --------------------------------------------Meida & awards page start-------------------------------------------- */
#awards-media {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 75px;
}

.awards-media-sub {
    background: url("../public/assets/images/mediaAndAwards/media-awards-ecowrap1.png") no-repeat center center/cover;
    background-size: 100% 100%;
    width: 100%;
}

.service-cards .service-card-im {
    padding: 15px 0;
}

.service-cards .service-card-im img {
    height: 15rem;
    border: #000000;
    border-radius: 5px;
}

.service-cards .title {
    color: #555;
    margin-bottom: -10px;
    font-size: 1.2rem;
    font-family: "Roboto", sans-serif !important;
}

/* --------------------------------------------Media & awards page end-------------------------------------------- */

/* --------------------------------------------Projects page start-------------------------------------------- */
#muskaan {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 650px;
}

#muskaan::before {
    content: "";
    position: absolute;
    background: url("../public/assets/images/projects/wp_eco.png") no-repeat center center/cover;
    top: 75px;
    left: 0px;
    width: 100%;
    height: 650px;
    z-index: -1;
    opacity: 0.88;
    background-size: 100% 100%;
}

#projects {
    margin-top: 75px;
}

#projects::before {
    top: 75px;
}

#competition {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

#competition::before {
    content: "";
    position: absolute;
    background: url("../public/assets/images/home/home-competion-ecowrap.png") no-repeat center center/cover;
    top: 35px;
    width: 100%;
    height: 100vh;
    z-index: -1;
    opacity: 0.88;
    background-size: 100% 100%;
}

#video-container {
    display: none;
}

#recycle-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 650px;
}

#recycle-right::before {
    content: "";
    position: absolute;
    background: url("../public/assets/images/projects/recycle-right-ecowrap.JPG") no-repeat center center/cover;
    top: 75px;
    left: 0px;
    width: 100%;
    height: 850px;
    z-index: -1;
    opacity: 0.88;
    background-size: 100% 100%;
}

.projects .card:hover {
    -webkit-box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.2);
}

.cards-img-top {
    height: 16rem;
}

.projects-image {
    border-radius: 5px;
    height: 20rem;
}

.mr-25 {
    margin-right: 25px;
}

.ml-25 {
    margin-left: 25px;
}

/* --------------------------------------------Projects page end-------------------------------------------- */

/* --------------------------------------------Footer section start-------------------------------------------- */
#footer {
    background-color: #D9FFE7;
    margin-top: 80px;
}

#footer-down {
    margin-top: -80px;
}

#footer a {
    color: black;
    text-decoration: none !important;
    font-family: "Roboto", sans-serif !important;
    -webkit-text-decoration-skip: objects;
}

#footer a:hover {
    color: #1569c7;
}

#footer ul.social li a i {
    margin-right: 5px;
    font-size: 25px;
    -webkit-transition: 0.5s all ease;
    -moz-transition: 0.5s all ease;
    transition: 0.5s all ease;
}

#footer ul.social li:hover a i {
    font-size: 30px;
    margin-top: -10px;
}

#footer ul.social li a,
#footer ul.quick-links li a {
    color: #000;
}

#footer ul.social li a:hover {
    color: #000;
}

#footer ul.quick-links li {
    padding: 8px 0;
}

#footer ul.quick-links li .footer-links:hover {
    color: #189339;
}

#footer ul li .footer-links:hover {
    color: #189339;
}

.footer-img {
    height: 3rem;
}

.footer-p {
    font-size: 0.8rem;
    margin-left: 0.1rem;
    padding: 0.2rem;
    font-weight: bold;
    text-align: justify;
}

.footer-li {
    color: #000;
    cursor: pointer;
    padding: 0.5rem;
    text-align: justify;
}

.anchor {
    background-color: #fff;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1em;
    transition: all 500ms ease;
    text-decoration: none;
    position: relative;
    cursor: pointer;
    overflow: hidden;
}

/* i {
    background-color: transparent;
    color: #0ebac5;
    font-size: 2em;
} */

.anchor::after {
    content: "";
    position: absolute;
    top: -100%;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    z-index: 1;
    cursor: pointer;
    transition: all 500ms ease-in;
}

.anchor.one:hover::after {
    background-color: #1D9BF0;
    top: 0;
}

.anchor:hover {
    box-shadow: 0 0 20px #0ebac5;
}

.anchor:hover .fa-brands {
    color: #fff;
    z-index: 2;
}

.anchor.two:hover::after {
    background-color: #25D366;
    top: 0;
}

.anchor.three:hover::after {
    background-color: #333;
    top: 0;
}

.anchor.four:hover::after {
    background-color: #0072b1;
    top: 0;
}

.nav-links {
    color: #000;
}

.nav-links:hover {
    color: #189339;
}

/* --------------------------------------------Footer section end-------------------------------------------- */
.navbar-nav .nav-item {
    color: #000;
    font-family: "Open Sans", sans-serif !important;
    font-style: normal;
    font-size: 14px;
    text-align: center;
    margin-left: 20px;
}

.navbar-nav .nav-item:hover {
    color: #77a1d7;
}

.navbar-nav .nav-item .active {
    color: #189339;
}

.navbar-nav .nav-item #nav-ratelist {
    background-color: #189339;
    color: white;
    border-radius: 17.5px;
}

.navbar-nav .nav-item #nav-ratelist:hover,
.navbar-nav .nav-item #nav-ratelist.active {
    background-color: #189339;
    color: white;
}

.cp {
    cursor: pointer;
}

.set-inner-HTML {
    font-family: "Open Sans", sans-serif !important;
    font-style: normal;
    color: #000;
}

.sliders {
    height: 250px;
    margin: auto;
    position: relative;
    width: 90%;
    display: grid;
    place-items: center;
    overflow: hidden;
}

.slider-track {
    display: flex;
    width: calc(250px * 10);
    animation: scroll 30s linear infinite;
}

.slider-track:hover {
    animation-play-state: paused;
}

@keyframes scroll {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(calc(-250px * 5));
    }
}

.slide {
    height: 200px;
    width: 250px;
    display: flex;
    align-items: center;
    padding: 15px;
    perspective: 100px;
}

.slide img {
    width: 100%;
    align-items: center;
    text-align: center;
    height: 100px;
    transition: transform 1s;
    /* width: 100%;
    aspect-ratio: 3/2;
    object-fit: contain;
    mix-blend-mode: color-burn; */
}

.slide img:hover {
    transform: translateY(10px);
}

.slick-slide>div {
    margin: 0 10px;
}

.slick-list {
    margin: 0 -10px;
}

.card-carsoul {
    /* background: white; */
    color: black;
    overflow: hidden;
    height: 350px;
}

.card-top>img {
    border-radius: 50%;
    border: 1px solid black;
    height: 100px;
    object-fit: cover;
    margin-bottom: 10px;
    text-align: center;
}

.card-bottom {
    margin: 10px 20px;
}

.single-testimonial-item {
    position: relative;
    box-shadow: 0 0 2px #dadfd3;
    border: 2px solid #189339;
    margin: 2px;
    padding: 20px;
    height: 310px;
    border-radius: 50px;
    background: linear-gradient(0deg, rgba(24, 147, 57, 0.62) 0%, rgba(24, 147, 57, 0.00) 94.5%);
}

.single-testimonial-item:after {
    background: rgb(239, 232, 232);
}

.single-testimonial-item h4 {
    font-size: 20px;
    font-style: normal;
    margin-bottom: 0;
}

.single-testimonial-item h4 span {
    display: block;
    font-size: 12px;
    font-weight: normal;
    margin-top: 5px;
}

.single-testimonial-item p {
    font-style: italic;
    text-align: center;
}

.single-testimonial-item div {
    text-align: center;
    font-weight: bold;
    font-size: 14px;
}

.slick-dots li.slick-active button:before {
    color: #189339;
}