/* Change navbar styling on small viewports */
@media (max-width: 991.98px) {
    .navbar {
        background: transparent;
    }

    .navbar .navbar-brand,
    .navbar .nav-link {
        color: #000;
    }

    #logo {
        display: none !important;
    }

    #logo_black {
        display: block !important;
    }

    #home,
    #recycle-right,
    #muskaan,
    #rate-list {
        height: 550px;
        padding: 3px 68px;
    }

    #blog,
    #clientsparters,
    #about,
    #contact,
    #projects {
        padding: 3px 20px;
    }

    #home::before,
    #blog::before,
    #projects::before,
    #recycle-right::before,
    #muskaan::before,
    #about::before,
    #contact::before,
    #rate-list::before {
        height: 500px;
    }
}

@media (max-width: 768px) {

    #competition::before {
        display: none;
    }

    #competition .container3 {
        display: none;
    }

    #video-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 100%;
        position: relative;
        min-height: calc(100vh - 75px);
    }

    #video-container::before {
        content: "";
        padding-top: 56.25%;
        display: block;
    }

    video {
        position: absolute;
        width: 100%;
        height: 100%;
    }
}

@media (max-width: 740px) {
    body {
        background-size: auto !important;
    }

    .mobile-text {
        margin-left: 46px;
    }

    .otp-text {
        margin-left: 46px;
    }

    .verify-h5 {
        margin-left: 0px
    }

    .verify-h6 {
        margin-left: 0px;
    }

    #phoneNumber {
        margin-left: 0px;
    }

    #otp {
        margin-left: 0px;
    }

    .password-error {
        margin-left: 0px;
        margin-bottom: 10px;
    }

    #mobileVerify {
        margin-left: 0px;
    }

    #otpVerify {
        margin-left: 0px;
    }

    .custom-head-big {
        font-size: 35px;
    }

    .candp-page-heading {
        font-size: 28px;
    }

    .mediaawards-top-sub-head {
        font-size: 28px;
    }

    .mediaawards-top-text {
        position: relative;
        padding: 20px;
    }

    .custom-head-small {
        font-size: 28px;
        bottom: 20px;
    }

    .route-home {
        font-size: 28px;
        bottom: 20px;
    }

    .vertical-mid {
        position: relative;
    }

    .zero {
        height: auto;
    }

    .mentors {
        height: auto;
    }

    .projects-image {
        width: 100%;
        height: 250px;
    }

    .frame-resp {
        height: 100%;
        width: 100%;
    }

    .font {
        text-align: center;
        justify-content: center;
    }

    #footer {
        height: auto;
    }
}