 
.data_form{ 
    background-color:white;
    border-radius: 7px; 
    height: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 350px;

    
    input{
        border-radius: 5px; 
        border: 1px solid black;
    } 

    button{
        background-color: blue;
        color: white;
        border: none;
        border-radius: 5px;
        box-shadow: 2px 2px 2px  grey;
        margin-top: 20px;
        padding: 10px;    
    }

    button:hover{
        background-color: darkblue;
        cursor: pointer;
    }

    select{
        border-radius: 5px;
        box-shadow: 1px 1px 1px 1px grey;
        border: none;
    }
 
}

.label_div{
    text-align: start;
    label{

        font-size: 16px;
    }
}