:root {
    --blue: #4191ff;
    --indigo: #7420ff;
    --purple: #793de6;
    --pink: #fc26a4;
    --red: #f83245;
    --orange: #f4772e;
    --yellow: #ffc926;
    --green: #1bc943;
    --teal: #18e1a5;
    --cyan: #11c5db;
    --white: #fff;
    --gray: #d1d2db;
    --gray-dark: #7a7b97;
    --primary: #3c44b1;
    --secondary: #f8f9ff;
    --success: #1bc943;
    --info: #11c5db;
    --warning: #f4772e;
    --danger: #f83245;
    --light: #f4f5fd;
    --dark: #7a7b97;
    --first: #4191ff;
    --second: #070919;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
}

*,
:after,
:before {
    box-sizing: border-box;
}

html {
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(7, 9, 25, 0);
}

.btn-get-started {
    font-family: "Open Sans", sans-serif !important;
    font-style: normal;
    font-size: 12px;
    background-color: #189339;
    user-select: none;
    border: none;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 2px;
    border-radius: 14px;
}

#get-started-button2 {
    font-family: "Open Sans", sans-serif !important;
    font-style: normal;
    font-size: 12px;
    background: rgba(8, 59, 124, 1);
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 0.25rem 0.55rem rgb(166 166 185 / 55%);
    text-align: center;
}

#get-started-button2:hover {
    background: #eb9481;
    color: #000;
    opacity: 1;
    transition-delay: .5s;
}

#headers .btn-schedule-pickup {
    font-family: "Open Sans", sans-serif !important;
    font-style: normal;
    font-weight: bold;
    background: #eb9481;
    color: #000000;
    border-radius: 5px;
    box-shadow: 0 0.25rem 0.55rem rgb(166 166 185 / 55%);
    text-align: center;
    padding: 10px 25px;
    position: fixed;
    right: 30px;
    bottom: 10px;
}

#headers .btn-schedule-pickup:hover {
    background-color: rgba(8, 59, 124, 1);
    color: #fff;
    opacity: 1;
    transition-delay: .5s;
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
    display: block;
}

body {
    margin: 0;
    font-size: 0.95rem;
    font-weight: 400;
    line-height: 1.5;
    color: #3b4966;
    text-align: left;
    background-color: #fff;
}

[tabindex="-1"]:focus {
    outline: 0 !important;
}

hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: 0.5rem;
}

p {
    margin-top: 0;
    margin-bottom: 1rem;
}

abbr[data-original-title],
abbr[title] {
    text-decoration: underline;
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
    cursor: help;
    border-bottom: 0;
    -webkit-text-decoration-skip-ink: none;
    text-decoration-skip-ink: none;
}

address {
    font-style: normal;
    line-height: inherit;
}

address,
dl,
ol,
ul {
    margin-bottom: 1rem;
}

dl,
ol,
ul {
    margin-top: 0;
}

ol ol,
ol ul,
ul ol,
ul ul {
    margin-bottom: 0;
}

dt {
    font-weight: 700;
}

dd {
    margin-bottom: 0.5rem;
    margin-left: 0;
}

blockquote {
    margin: 0 0 1rem;
}

b,
strong {
    font-weight: bolder;
}

small {
    font-size: 80%;
}

sub,
sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
}

sub {
    bottom: -0.25em;
}

sup {
    top: -0.5em;
}

a {
    color: #3c44b1;
    background-color: transparent;
}

a,
a:hover {
    text-decoration: none;
}

a:hover {
    color: #292e78;
}

a:not([href]):not([tabindex]),
a:not([href]):not([tabindex]):focus,
a:not([href]):not([tabindex]):hover {
    color: inherit;
    text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
    outline: 0;
}

code,
kbd,
pre,
samp {
    font-size: 1em;
}

pre {
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
}

figure {
    margin: 0 0 1rem;
}

img {
    border-style: none;
}

img,
svg {
    vertical-align: middle;
}

svg {
    overflow: hidden;
}

table {
    border-collapse: collapse;
}

caption {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    color: #d1d2db;
    text-align: left;
    caption-side: bottom;
}

th {
    text-align: inherit;
}

label {
    display: inline-block;
    margin-bottom: 0.5rem;
    font-family: "Open Sans", sans-serif !important;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #000000;
}

button {
    border-radius: 0;
}

button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
}

button,
input,
optgroup,
select,
textarea {
    margin: 0;
    font-size: inherit;
    line-height: inherit;
}

button,
input {
    overflow: visible;
}

button,
select {
    text-transform: none;
}

select {
    word-wrap: normal;
}

[type="button"],
[type="reset"],
[type="submit"],
button {
    -webkit-appearance: button;
}

[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled),
button:not(:disabled) {
    cursor: pointer;
}

[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner,
button::-moz-focus-inner {
    padding: 0;
    border-style: none;
}

input[type="checkbox"],
input[type="radio"] {
    box-sizing: border-box;
    padding: 0;
}

input[type="date"],
input[type="datetime-local"],
input[type="month"],
input[type="time"] {
    -webkit-appearance: listbox;
}

textarea {
    overflow: auto;
    resize: vertical;
}

fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
}

legend {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
    line-height: inherit;
    color: inherit;
    white-space: normal;
}

progress {
    vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
    height: auto;
}

[type="search"] {
    outline-offset: -2px;
    -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button;
}

output {
    display: inline-block;
}

summary {
    display: list-item;
    cursor: pointer;
}

template {
    display: none;
}

[hidden] {
    display: none !important;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
}

.h1,
h1 {
    font-size: 2.375rem;
}

.h2,
h2 {
    font-size: 1.9rem;
}

.h3,
h3 {
    font-size: 1.6625rem;
}

.h4,
h4 {
    font-size: 1.425rem;
}

.h5,
h5 {
    font-size: 1.1875rem;
}

.h6,
h6 {
    font-size: 0.95rem;
}

.lead {
    font-size: 1.1875rem;
    font-weight: 300;
}

.display-1 {
    font-size: 3.5rem;
}

.display-1,
.display-2 {
    font-weight: 300;
    line-height: 1.2;
}

.display-2 {
    font-size: 3rem;
    text-align: center;
}

.display-3 {
    font-size: 2.5rem;
}

.display-3,
.display-4 {
    font-weight: 300;
    line-height: 0.8;
}

.display-4 {
    font-size: 2.5rem;
    line-height: 1.5;
    margin: 5px 5px;
}

hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(7, 9, 25, 0.1);
}

.hr2 {
    border-top: 1px solid rgba(7, 9, 25, 0.4);
    margin-bottom: 0px;
}

.small,
small {
    font-size: 80%;
    font-weight: 400;
}

.mark,
mark {
    padding: 0.2em;
    background-color: #fcf8e3;
}

.list-inline,
.list-unstyled {
    padding-left: 0;
    list-style: none;
}

.list-inline-item {
    display: inline-block;
}

.list-inline-item:not(:last-child) {
    margin-right: 0.5rem;
}

.initialism {
    font-size: 90%;
    text-transform: uppercase;
}

.blockquote {
    margin-bottom: 1rem;
    font-size: 1.1875rem;
}

.blockquote-footer {
    display: block;
    font-size: 80%;
    color: #d1d2db;
}

.blockquote-footer:before {
    content: "\2014\00A0";
}

.img-fluid,
.img-thumbnail {
    max-width: 100%;
}

.img-map-contactus {
    width: 100%;
    max-width: 100%;
    height: auto;
}

.img-thumbnail {
    padding: 0.25rem;
    background-color: #fff;
    border: 1px solid #eeeff8;
    border-radius: 0.65rem;
}

.figure {
    display: inline-block;
}

.figure-img {
    margin-bottom: 0.5rem;
    line-height: 1;
}

.figure-caption {
    font-size: 90%;
    color: #d1d2db;
}

code {
    font-size: 87.5%;
    color: #fc26a4;
    word-break: break-word;
}

a>code {
    color: inherit;
}

kbd {
    padding: 0.2rem 0.4rem;
    font-size: 87.5%;
    color: #fff;
    background-color: #3b3e66;
    border-radius: 0.29rem;
}

kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700;
}

pre {
    display: block;
    font-size: 87.5%;
    color: #3b3e66;
}

pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal;
}

.pre-scrollable {
    max-height: 340px;
    overflow-y: scroll;
}

.container {
    width: 100%;
    padding-right: 20px;
    padding-left: 20px;
    margin-right: auto;
    margin-left: auto;
}

.container2 {
    width: 100%;
    padding-right: 20px;
    padding-left: 20px;
    margin-right: auto;
    margin-left: auto;
}

.container-blog-left {
    width: 98%;
    padding-right: 40px;
    padding-left: 40px;
    margin-right: auto;
    margin-left: auto;
    border-radius: 5px;
    background-color: #e8eff3;
}

.container-blog-right {
    width: 98%;
    padding-right: 40px;
    padding-left: 40px;
    margin-right: auto;
    margin-left: auto;
    border-radius: 5px;
    background-color: #f2dede;
}

.container-fluid {
    width: 100%;
    padding-right: 20px;
    padding-left: 20px;
    margin-right: auto;
    margin-left: auto;
}

.row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -20px;
    margin-left: -20px;
    padding-top: 10px;
}

.no-gutters {
    margin-right: 0;
    margin-left: 0;
}

.no-gutters>.col,
.no-gutters>[class*="col-"] {
    padding-right: 0;
    padding-left: 0;
}

.col,
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-0-5,
.col-md-1-5,
.col-md-2-5,
.col-md-3-5,
.col-md-4-5,
.col-md-5-5,
.col-md-6-5,
.col-md-7-5,
.col-md-8-5,
.col-md-9-5,
.col-md-10-5,
.col-md-11-5,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-auto {
    position: relative;
    width: 100%;
    padding-right: 20px;
    padding-left: 20px;
}

.col {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
}

.col-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
}

.col-1 {
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
}

.col-2 {
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
}

.col-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
}

.col-4 {
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
}

.col-5 {
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
}

.col-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
}

.col-7 {
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
}

.col-8 {
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
}

.col-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
}

.col-10 {
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
}

.col-11 {
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
}

.col-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
}

.order-first {
    -ms-flex-order: -1;
    order: -1;
}

.order-last {
    -ms-flex-order: 13;
    order: 13;
}

.order-0 {
    -ms-flex-order: 0;
    order: 0;
}

.order-1 {
    -ms-flex-order: 1;
    order: 1;
}

.order-2 {
    -ms-flex-order: 2;
    order: 2;
}

.order-3 {
    -ms-flex-order: 3;
    order: 3;
}

.order-4 {
    -ms-flex-order: 4;
    order: 4;
}

.order-5 {
    -ms-flex-order: 5;
    order: 5;
}

.order-6 {
    -ms-flex-order: 6;
    order: 6;
}

.order-7 {
    -ms-flex-order: 7;
    order: 7;
}

.order-8 {
    -ms-flex-order: 8;
    order: 8;
}

.order-9 {
    -ms-flex-order: 9;
    order: 9;
}

.order-10 {
    -ms-flex-order: 10;
    order: 10;
}

.order-11 {
    -ms-flex-order: 11;
    order: 11;
}

.order-12 {
    -ms-flex-order: 12;
    order: 12;
}

.offset-1 {
    margin-left: 8.33333%;
}

.offset-2 {
    margin-left: 16.66667%;
}

.offset-3 {
    margin-left: 25%;
}

.offset-4 {
    margin-left: 33.33333%;
}

.offset-5 {
    margin-left: 41.66667%;
}

.offset-6 {
    margin-left: 50%;
}

.offset-7 {
    margin-left: 58.33333%;
}

.offset-8 {
    margin-left: 66.66667%;
}

.offset-9 {
    margin-left: 75%;
}

.offset-10 {
    margin-left: 83.33333%;
}

.offset-11 {
    margin-left: 91.66667%;
}

.table {
    width: 100%;
    margin-bottom: 1rem;
    color: #3b3e66;
}

.table td,
.table th {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dcdef1;
}

.table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dcdef1;
}

.table tbody+tbody {
    border-top: 2px solid #dcdef1;
}

.table-sm td,
.table-sm th {
    padding: 0.3rem;
}

.table-bordered,
.table-bordered td,
.table-bordered th {
    border: 1px solid #dcdef1;
}

.table-bordered thead td,
.table-bordered thead th {
    border-bottom-width: 2px;
}

.table-borderless tbody+tbody,
.table-borderless td,
.table-borderless th,
.table-borderless thead th {
    border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: #f9fafe;
}

.table-hover tbody tr:hover {
    color: #3b3e66;
    background-color: #fffbf2;
}

.table-primary,
.table-primary>td,
.table-primary>th {
    background-color: #c8cbe9;
}

.table-primary tbody+tbody,
.table-primary td,
.table-primary th,
.table-primary thead th {
    border-color: #9a9ed6;
}

.table-hover .table-primary:hover,
.table-hover .table-primary:hover>td,
.table-hover .table-primary:hover>th {
    background-color: #b6bae2;
}

.table-secondary,
.table-secondary>td,
.table-secondary>th {
    background-color: #fdfdff;
}

.table-secondary tbody+tbody,
.table-secondary td,
.table-secondary th,
.table-secondary thead th {
    border-color: #fbfcff;
}

.table-hover .table-secondary:hover,
.table-hover .table-secondary:hover>td,
.table-hover .table-secondary:hover>th {
    background-color: #e4e4ff;
}

.table-success,
.table-success>td,
.table-success>th {
    background-color: #bff0ca;
}

.table-success tbody+tbody,
.table-success td,
.table-success th,
.table-success thead th {
    border-color: #88e39d;
}

.table-hover .table-success:hover,
.table-hover .table-success:hover>td,
.table-hover .table-success:hover>th {
    background-color: #aaebb9;
}

.table-info,
.table-info>td,
.table-info>th {
    background-color: #bceff5;
}

.table-info tbody+tbody,
.table-info td,
.table-info th,
.table-info thead th {
    border-color: #83e1ec;
}

.table-hover .table-info:hover,
.table-hover .table-info:hover>td,
.table-hover .table-info:hover>th {
    background-color: #a6eaf2;
}

.table-warning,
.table-warning>td,
.table-warning>th {
    background-color: #fcd9c4;
}

.table-warning tbody+tbody,
.table-warning td,
.table-warning th,
.table-warning thead th {
    border-color: #f9b892;
}

.table-hover .table-warning:hover,
.table-hover .table-warning:hover>td,
.table-hover .table-warning:hover>th {
    background-color: #fbc9ac;
}

.table-danger,
.table-danger>td,
.table-danger>th {
    background-color: #fdc6cb;
}

.table-danger tbody+tbody,
.table-danger td,
.table-danger th,
.table-danger thead th {
    border-color: #fb949e;
}

.table-hover .table-danger:hover,
.table-hover .table-danger:hover>td,
.table-hover .table-danger:hover>th {
    background-color: #fcadb5;
}

.table-light,
.table-light>td,
.table-light>th {
    background-color: #fcfcfe;
}

.table-light tbody+tbody,
.table-light td,
.table-light th,
.table-light thead th {
    border-color: #f9fafe;
}

.table-hover .table-light:hover,
.table-hover .table-light:hover>td,
.table-hover .table-light:hover>th {
    background-color: #e9e9f8;
}

.table-dark,
.table-dark>td,
.table-dark>th {
    background-color: #dadae2;
}

.table-dark tbody+tbody,
.table-dark td,
.table-dark th,
.table-dark thead th {
    border-color: #babac9;
}

.table-hover .table-dark:hover,
.table-hover .table-dark:hover>td,
.table-hover .table-dark:hover>th {
    background-color: #ccccd7;
}

.table-first,
.table-first>td,
.table-first>th {
    background-color: #cae0ff;
}

.table-first tbody+tbody,
.table-first td,
.table-first th,
.table-first thead th {
    border-color: #9cc6ff;
}

.table-hover .table-first:hover,
.table-hover .table-first:hover>td,
.table-hover .table-first:hover>th {
    background-color: #b1d1ff;
}

.table-second,
.table-second>td,
.table-second>th {
    background-color: #bababf;
}

.table-second tbody+tbody,
.table-second td,
.table-second th,
.table-second thead th {
    border-color: #7e7f87;
}

.table-hover .table-second:hover,
.table-hover .table-second:hover>td,
.table-hover .table-second:hover>th {
    background-color: #adadb3;
}

.table-active,
.table-active>td,
.table-active>th {
    background-color: #fffbf2;
}

.table-hover .table-active:hover,
.table-hover .table-active:hover>td,
.table-hover .table-active:hover>th {
    background-color: #fff4d8;
}

.table .thead-dark th {
    color: #fff;
    background-color: #3b3e66;
    border-color: #282a46;
}

.table .thead-light th {
    color: #3b3e66;
    background-color: rgba(239, 239, 247, 0.7);
    border-color: #dcdef1;
}

.table-dark {
    color: #fff;
    background-color: #3b3e66;
}

.table-dark td,
.table-dark th,
.table-dark thead th {
    border-color: #282a46;
}

.table-dark.table-bordered {
    border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: hsla(0, 0%, 100%, 0.05);
}

.table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: hsla(0, 0%, 100%, 0.075);
}

.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

.table-responsive>.table-bordered {
    border: 0;
}

.form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + 1.06rem + 2px);
    padding: 0.53rem 1.3rem;
    font-size: 0.95rem;
    font-weight: 400;
    line-height: 1.5;
    min-height: 45px;
    color: #3b3e66;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d1d2db;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control::-ms-expand {
    background-color: transparent;
    border: 0;
}

.form-control:focus {
    color: #3b3e66;
    background-color: #fff;
    border-color: #9297da;
    outline: 0;
    box-shadow: 0 0.313rem 0.719rem rgba(122, 123, 151, 0.1),
        0 0.156rem 0.125rem rgba(122, 123, 151, 0.06);
}

.form-control::-webkit-input-placeholder {
    color: #d1d2db;
    opacity: 1;
}

.form-control::-moz-placeholder {
    color: #d1d2db;
    opacity: 1;
}

.form-control:-ms-input-placeholder,
.form-control::-ms-input-placeholder {
    color: #d1d2db;
    opacity: 1;
}

.form-control::placeholder {
    color: #d1d2db;
    opacity: 1;
}

.form-control:disabled,
.form-control[readonly] {
    background-color: #eeeff8;
    opacity: 1;
}

select.form-control:focus::-ms-value {
    color: #3b3e66;
    background-color: #fff;
}

.form-control-file,
.form-control-range {
    display: block;
    width: 100%;
}

.col-form-label {
    padding-top: calc(0.53rem + 1px);
    padding-bottom: calc(0.53rem + 1px);
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.5;
}

.col-form-label-lg {
    padding-top: calc(0.84rem + 1px);
    padding-bottom: calc(0.84rem + 1px);
    font-size: 0.95rem;
    line-height: 1.5;
}

.col-form-label-sm {
    padding-top: calc(0.25rem + 1px);
    padding-bottom: calc(0.25rem + 1px);
    font-size: 0.83125rem;
    line-height: 1.5;
}

.form-control-plaintext {
    display: block;
    width: 100%;
    padding-top: 0.53rem;
    padding-bottom: 0.53rem;
    margin-bottom: 0;
    line-height: 1.5;
    color: #3b3e66;
    background-color: transparent;
    border: solid transparent;
    border-width: 1px 0;
}

.form-control-plaintext.form-control-lg,
.form-control-plaintext.form-control-sm {
    padding-right: 0;
    padding-left: 0;
}

.form-control-sm {
    height: calc(1.5em + 0.5rem + 2px);
    padding: 0.25rem 1.1rem;
    font-size: 0.83125rem;
}

.form-control-lg,
.form-control-sm {
    line-height: 1.5;
    border-radius: 0.29rem;
}

.form-control-lg {
    height: calc(1.5em + 1.68rem + 2px);
    padding: 0.84rem 1.8rem;
    font-size: 0.95rem;
}

select.form-control[multiple],
select.form-control[size],
textarea.form-control {
    height: auto;
}

.form-group {
    margin-bottom: 1rem;
}

.form-text {
    display: block;
    margin-top: 0.25rem;
}

.form-row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px;
}

.form-row>.col,
.form-row>[class*="col-"] {
    padding-right: 5px;
    padding-left: 5px;
}

.form-check {
    position: relative;
    display: block;
    padding-left: 1.25rem;
}

.form-check-input {
    position: absolute;
    margin: auto;
    margin-top: 0.3rem;
    align-items: center;
}

.form-check-input:disabled~.form-check-label {
    color: #d1d2db;
}

.form-check-label {
    margin-bottom: 0;
}

.form-check-inline {
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-align: center;
    align-items: center;
    padding-left: 0;
    margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0;
}

.valid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #1bc943;
}

.valid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: 0.55rem 1rem;
    margin-top: 0.1rem;
    font-size: 0.83125rem;
    line-height: 1.5;
    color: #fff;
    background-color: rgba(27, 201, 67, 0.85);
    border-radius: 0.29rem;
}

.form-control.is-valid,
.was-validated .form-control:valid {
    border-color: #1bc943;
    padding-right: calc(1.5em + 1.06rem);
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%231bc943' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: 100% calc(0.375em + 0.265rem);
    background-size: calc(0.75em + 0.53rem) calc(0.75em + 0.53rem);
}

.form-control.is-valid:focus,
.was-validated .form-control:valid:focus {
    border-color: #1bc943;
    box-shadow: 0 0 0 0.2rem rgba(27, 201, 67, 0.25);
}

.form-control.is-valid~.valid-feedback,
.form-control.is-valid~.valid-tooltip,
.was-validated .form-control:valid~.valid-feedback,
.was-validated .form-control:valid~.valid-tooltip {
    display: block;
}

.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
    padding-right: calc(1.5em + 1.06rem);
    background-position: top calc(0.375em + 0.265rem) right calc(0.375em + 0.265rem);
}

.custom-select.is-valid,
.was-validated .custom-select:valid {
    border-color: #1bc943;
    padding-right: calc((1em + 1.06rem) * 3 / 4 + 2.3rem);
    background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%237a7b97' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 1.3rem center/8px 10px,
        url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%231bc943' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E") #fff no-repeat center right 2.3rem / calc(0.75em + 0.53rem) calc(0.75em + 0.53rem);
}

.custom-select.is-valid:focus,
.was-validated .custom-select:valid:focus {
    border-color: #1bc943;
    box-shadow: 0 0 0 0.2rem rgba(27, 201, 67, 0.25);
}

.custom-select.is-valid~.valid-feedback,
.custom-select.is-valid~.valid-tooltip,
.form-control-file.is-valid~.valid-feedback,
.form-control-file.is-valid~.valid-tooltip,
.was-validated .custom-select:valid~.valid-feedback,
.was-validated .custom-select:valid~.valid-tooltip,
.was-validated .form-control-file:valid~.valid-feedback,
.was-validated .form-control-file:valid~.valid-tooltip {
    display: block;
}

.form-check-input.is-valid~.form-check-label,
.was-validated .form-check-input:valid~.form-check-label {
    color: #1bc943;
}

.form-check-input.is-valid~.valid-feedback,
.form-check-input.is-valid~.valid-tooltip,
.was-validated .form-check-input:valid~.valid-feedback,
.was-validated .form-check-input:valid~.valid-tooltip {
    display: block;
}

.custom-control-input.is-valid~.custom-control-label,
.was-validated .custom-control-input:valid~.custom-control-label {
    color: #1bc943;
}

.custom-control-input.is-valid~.custom-control-label:before,
.was-validated .custom-control-input:valid~.custom-control-label:before {
    border-color: #1bc943;
}

.custom-control-input.is-valid~.valid-feedback,
.custom-control-input.is-valid~.valid-tooltip,
.was-validated .custom-control-input:valid~.valid-feedback,
.was-validated .custom-control-input:valid~.valid-tooltip {
    display: block;
}

.custom-control-input.is-valid:checked~.custom-control-label:before,
.was-validated .custom-control-input:valid:checked~.custom-control-label:before {
    border-color: #33e45c;
    background-color: #33e45c;
}

.custom-control-input.is-valid:focus~.custom-control-label:before,
.was-validated .custom-control-input:valid:focus~.custom-control-label:before {
    box-shadow: 0 0 0 0.2rem rgba(27, 201, 67, 0.25);
}

.custom-control-input.is-valid:focus:not(:checked)~.custom-control-label:before,
.custom-file-input.is-valid~.custom-file-label,
.was-validated .custom-control-input:valid:focus:not(:checked)~.custom-control-label:before,
.was-validated .custom-file-input:valid~.custom-file-label {
    border-color: #1bc943;
}

.custom-file-input.is-valid~.valid-feedback,
.custom-file-input.is-valid~.valid-tooltip,
.was-validated .custom-file-input:valid~.valid-feedback,
.was-validated .custom-file-input:valid~.valid-tooltip {
    display: block;
}

.custom-file-input.is-valid:focus~.custom-file-label,
.was-validated .custom-file-input:valid:focus~.custom-file-label {
    border-color: #1bc943;
    box-shadow: 0 0 0 0.2rem rgba(27, 201, 67, 0.25);
}

.invalid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #f83245;
}

.invalid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: 0.55rem 1rem;
    margin-top: 0.1rem;
    font-size: 0.83125rem;
    line-height: 1.5;
    color: #fff;
    background-color: rgba(248, 50, 69, 0.85);
    border-radius: 0.29rem;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
    border-color: #f83245;
    padding-right: calc(1.5em + 1.06rem);
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23f83245' viewBox='-2 -2 7 7'%3E%3Cpath stroke='%23f83245' d='M0 0l3 3m0-3L0 3'/%3E%3Ccircle r='.5'/%3E%3Ccircle cx='3' r='.5'/%3E%3Ccircle cy='3' r='.5'/%3E%3Ccircle cx='3' cy='3' r='.5'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: 100% calc(0.375em + 0.265rem);
    background-size: calc(0.75em + 0.53rem) calc(0.75em + 0.53rem);
}

.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus {
    border-color: #f83245;
    box-shadow: 0 0 0 0.2rem rgba(248, 50, 69, 0.25);
}

.form-control.is-invalid~.invalid-feedback,
.form-control.is-invalid~.invalid-tooltip,
.was-validated .form-control:invalid~.invalid-feedback,
.was-validated .form-control:invalid~.invalid-tooltip {
    display: block;
}

.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
    padding-right: calc(1.5em + 1.06rem);
    background-position: top calc(0.375em + 0.265rem) right calc(0.375em + 0.265rem);
}

.custom-select.is-invalid,
.was-validated .custom-select:invalid {
    border-color: #f83245;
    padding-right: calc((1em + 1.06rem) * 3 / 4 + 2.3rem);
    background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%237a7b97' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 1.3rem center/8px 10px,
        url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23f83245' viewBox='-2 -2 7 7'%3E%3Cpath stroke='%23f83245' d='M0 0l3 3m0-3L0 3'/%3E%3Ccircle r='.5'/%3E%3Ccircle cx='3' r='.5'/%3E%3Ccircle cy='3' r='.5'/%3E%3Ccircle cx='3' cy='3' r='.5'/%3E%3C/svg%3E") #fff no-repeat center right 2.3rem / calc(0.75em + 0.53rem) calc(0.75em + 0.53rem);
}

.custom-select.is-invalid:focus,
.was-validated .custom-select:invalid:focus {
    border-color: #f83245;
    box-shadow: 0 0 0 0.2rem rgba(248, 50, 69, 0.25);
}

.custom-select.is-invalid~.invalid-feedback,
.custom-select.is-invalid~.invalid-tooltip,
.form-control-file.is-invalid~.invalid-feedback,
.form-control-file.is-invalid~.invalid-tooltip,
.was-validated .custom-select:invalid~.invalid-feedback,
.was-validated .custom-select:invalid~.invalid-tooltip,
.was-validated .form-control-file:invalid~.invalid-feedback,
.was-validated .form-control-file:invalid~.invalid-tooltip {
    display: block;
}

.form-check-input.is-invalid~.form-check-label,
.was-validated .form-check-input:invalid~.form-check-label {
    color: #f83245;
}

.form-check-input.is-invalid~.invalid-feedback,
.form-check-input.is-invalid~.invalid-tooltip,
.was-validated .form-check-input:invalid~.invalid-feedback,
.was-validated .form-check-input:invalid~.invalid-tooltip {
    display: block;
}

.custom-control-input.is-invalid~.custom-control-label,
.was-validated .custom-control-input:invalid~.custom-control-label {
    color: #f83245;
}

.custom-control-input.is-invalid~.custom-control-label:before,
.was-validated .custom-control-input:invalid~.custom-control-label:before {
    border-color: #f83245;
}

.custom-control-input.is-invalid~.invalid-feedback,
.custom-control-input.is-invalid~.invalid-tooltip,
.was-validated .custom-control-input:invalid~.invalid-feedback,
.was-validated .custom-control-input:invalid~.invalid-tooltip {
    display: block;
}

.custom-control-input.is-invalid:checked~.custom-control-label:before,
.was-validated .custom-control-input:invalid:checked~.custom-control-label:before {
    border-color: #fa6372;
    background-color: #fa6372;
}

.custom-control-input.is-invalid:focus~.custom-control-label:before,
.was-validated .custom-control-input:invalid:focus~.custom-control-label:before {
    box-shadow: 0 0 0 0.2rem rgba(248, 50, 69, 0.25);
}

.custom-control-input.is-invalid:focus:not(:checked)~.custom-control-label:before,
.custom-file-input.is-invalid~.custom-file-label,
.was-validated .custom-control-input:invalid:focus:not(:checked)~.custom-control-label:before,
.was-validated .custom-file-input:invalid~.custom-file-label {
    border-color: #f83245;
}

.custom-file-input.is-invalid~.invalid-feedback,
.custom-file-input.is-invalid~.invalid-tooltip,
.was-validated .custom-file-input:invalid~.invalid-feedback,
.was-validated .custom-file-input:invalid~.invalid-tooltip {
    display: block;
}

.custom-file-input.is-invalid:focus~.custom-file-label,
.was-validated .custom-file-input:invalid:focus~.custom-file-label {
    border-color: #f83245;
    box-shadow: 0 0 0 0.2rem rgba(248, 50, 69, 0.25);
}

.form-inline {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -ms-flex-align: center;
    align-items: center;
}

.form-inline .form-check {
    width: 100%;
}

.btn {
    display: inline-block;
    font-weight: 400;
    color: #3b3e66;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 2px solid transparent;
    padding: 0.50rem 1.3rem;
    font-size: 0.95rem;
    line-height: 1.5;
    border-radius: 0.65rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn:hover {
    color: #232862;
    text-decoration: none;
}

.btn.focus,
.btn:focus {
    outline: 0;
    box-shadow: 0 0.313rem 0.719rem rgba(122, 123, 151, 0.1),
        0 0.156rem 0.125rem rgba(122, 123, 151, 0.06);
}

.btn.disabled,
.btn:disabled {
    opacity: 0.65;
}

a.btn.disabled,
fieldset:disabled a.btn {
    pointer-events: none;
}

.btn-primary,
.btn-primary:hover {
    color: #fff;
    background-color: #3c44b1;
    border-color: #3c44b1;
}

.btn-primary.focus,
.btn-primary:focus {
    box-shadow: 0 0 0 0 transparent;
}

.btn-primary.disabled,
.btn-primary:disabled {
    color: #fff;
    background-color: #3c44b1;
    border-color: #3c44b1;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #2f358b;
    border-color: #2c3281;
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show>.btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 transparent;
}

.btn-secondary,
.btn-secondary:hover {
    background-color: #f8f9ff;
    color: #3c44b1;
}

.btn-secondary:hover {
    border-color: #f8f9ff;
}

.btn-secondary.focus,
.btn-secondary:focus {
    box-shadow: 0 0 0 0 transparent;
}

.btn-secondary.disabled,
.btn-secondary:disabled {
    color: #3b3e66;
    background-color: #f8f9ff;
    border-color: #f8f9ff;
}

.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show>.btn-secondary.dropdown-toggle {
    color: #3b3e66;
    background-color: #c5cdff;
    border-color: #b8c2ff;
}

.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show>.btn-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 transparent;
}

.btn-success,
.btn-success:hover {
    color: #fff;
    background-color: #1bc943;
    border-color: #1bc943;
}

.btn-success.focus,
.btn-success:focus {
    box-shadow: 0 0 0 0 transparent;
}

.btn-success.disabled,
.btn-success:disabled {
    color: #fff;
    background-color: #1bc943;
    border-color: #1bc943;
}

.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active,
.show>.btn-success.dropdown-toggle {
    color: #fff;
    background-color: #159c34;
    border-color: #139130;
}

.btn-success:not(:disabled):not(.disabled).active:focus,
.btn-success:not(:disabled):not(.disabled):active:focus,
.show>.btn-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 transparent;
}

.btn-info,
.btn-info:hover {
    color: #fff;
    background-color: #11c5db;
    border-color: #11c5db;
}

.btn-info.focus,
.btn-info:focus {
    box-shadow: 0 0 0 0 transparent;
}

.btn-info.disabled,
.btn-info:disabled {
    color: #fff;
    background-color: #11c5db;
    border-color: #11c5db;
}

.btn-info:not(:disabled):not(.disabled).active,
.btn-info:not(:disabled):not(.disabled):active,
.show>.btn-info.dropdown-toggle {
    color: #fff;
    background-color: #0d9aac;
    border-color: #0c90a0;
}

.btn-info:not(:disabled):not(.disabled).active:focus,
.btn-info:not(:disabled):not(.disabled):active:focus,
.show>.btn-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 transparent;
}

.btn-warning,
.btn-warning:hover {
    color: #fff;
    background-color: #f4772e;
    border-color: #f4772e;
}

.btn-warning.focus,
.btn-warning:focus {
    box-shadow: 0 0 0 0 transparent;
}

.btn-warning.disabled,
.btn-warning:disabled {
    color: #fff;
    background-color: #f4772e;
    border-color: #f4772e;
}

.btn-warning:not(:disabled):not(.disabled).active,
.btn-warning:not(:disabled):not(.disabled):active,
.show>.btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #e35b0c;
    border-color: #d7560b;
}

.btn-warning:not(:disabled):not(.disabled).active:focus,
.btn-warning:not(:disabled):not(.disabled):active:focus,
.show>.btn-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 transparent;
}

.btn-danger,
.btn-danger:hover {
    color: #fff;
    background-color: #f83245;
    border-color: #f83245;
}

.btn-danger.focus,
.btn-danger:focus {
    box-shadow: 0 0 0 0 transparent;
}

.btn-danger.disabled,
.btn-danger:disabled {
    color: #fff;
    background-color: #f83245;
    border-color: #f83245;
}

.btn-danger:not(:disabled):not(.disabled).active,
.btn-danger:not(:disabled):not(.disabled):active,
.show>.btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #ef081e;
    border-color: #e3081d;
}

.btn-danger:not(:disabled):not(.disabled).active:focus,
.btn-danger:not(:disabled):not(.disabled):active:focus,
.show>.btn-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 transparent;
}

.btn-light,
.btn-light:hover {
    color: #3b3e66;
    background-color: #f4f5fd;
    border-color: #f4f5fd;
}

.btn-light.focus,
.btn-light:focus {
    box-shadow: 0 0 0 0 transparent;
}

.btn-light.disabled,
.btn-light:disabled {
    color: #3b3e66;
    background-color: #f4f5fd;
    border-color: #f4f5fd;
}

.btn-light:not(:disabled):not(.disabled).active,
.btn-light:not(:disabled):not(.disabled):active,
.show>.btn-light.dropdown-toggle {
    color: #3b3e66;
    background-color: #c9cef5;
    border-color: #bec4f3;
}

.btn-light:not(:disabled):not(.disabled).active:focus,
.btn-light:not(:disabled):not(.disabled):active:focus,
.show>.btn-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 transparent;
}

#btn-form-contactus,
#btn-form-contactus:hover {
    color: #fff;
    background-color: rgb(34, 34, 59);
    border-color: black;
    border-radius: 5rem;
}

.btn-dark,
.btn-dark:hover {
    color: #fff;
    background-color: #7a7b97;
    border-color: #7a7b97;
}

.btn-dark.focus,
.btn-dark:focus {
    box-shadow: 0 0 0 0 transparent;
}

.btn-dark.disabled,
.btn-dark:disabled {
    color: #fff;
    background-color: #7a7b97;
    border-color: #7a7b97;
}

.btn-dark:not(:disabled):not(.disabled).active,
.btn-dark:not(:disabled):not(.disabled):active,
.show>.btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #61627d;
    border-color: #5c5d75;
}

.btn-dark:not(:disabled):not(.disabled).active:focus,
.btn-dark:not(:disabled):not(.disabled):active:focus,
.show>.btn-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 transparent;
}

.btn-first,
.btn-first:hover {
    color: #fff;
    background-color: #4191ff;
    border-color: #4191ff;
}

.btn-first.focus,
.btn-first:focus {
    box-shadow: 0 0 0 0 transparent;
}

.btn-first.disabled,
.btn-first:disabled {
    color: #fff;
    background-color: #4191ff;
    border-color: #4191ff;
}

.btn-first:not(:disabled):not(.disabled).active,
.btn-first:not(:disabled):not(.disabled):active,
.show>.btn-first.dropdown-toggle {
    color: #fff;
    background-color: #0e73ff;
    border-color: #016cff;
}

.btn-first:not(:disabled):not(.disabled).active:focus,
.btn-first:not(:disabled):not(.disabled):active:focus,
.show>.btn-first.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 transparent;
}

.btn-second,
.btn-second:hover {
    color: #fff;
    background-color: #070919;
    border-color: #070919;
}

.btn-second.focus,
.btn-second:focus {
    box-shadow: 0 0 0 0 transparent;
}

.btn-second.disabled,
.btn-second:disabled {
    color: #fff;
    background-color: #070919;
    border-color: #070919;
}

.btn-second:not(:disabled):not(.disabled).active,
.btn-second:not(:disabled):not(.disabled):active,
.show>.btn-second.dropdown-toggle {
    color: #fff;
    background-color: #000;
    border-color: #000;
}

.btn-second:not(:disabled):not(.disabled).active:focus,
.btn-second:not(:disabled):not(.disabled):active:focus,
.show>.btn-second.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 transparent;
}

.btn-outline-primary {
    color: #3c44b1;
    border-color: #3c44b1;
}

.btn-outline-primary:hover {
    color: #fff;
    background-color: #3c44b1;
    border-color: #3c44b1;
}

.btn-outline-primary.focus,
.btn-outline-primary:focus {
    box-shadow: 0 0 0 0 transparent;
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
    color: #3c44b1;
    background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show>.btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #3c44b1;
    border-color: #3c44b1;
}

.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 transparent;
}

.btn-outline-secondary {
    color: #f8f9ff;
    border-color: #f8f9ff;
}

.btn-outline-secondary:hover {
    color: #3b3e66;
    background-color: #f8f9ff;
    border-color: #f8f9ff;
}

.btn-outline-secondary.focus,
.btn-outline-secondary:focus {
    box-shadow: 0 0 0 0 transparent;
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
    color: #f8f9ff;
    background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.show>.btn-outline-secondary.dropdown-toggle {
    color: #3b3e66;
    background-color: #f8f9ff;
    border-color: #f8f9ff;
}

.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 transparent;
}

.btn-outline-success {
    color: #1bc943;
    border-color: #1bc943;
}

.btn-outline-success:hover {
    color: #fff;
    background-color: #1bc943;
    border-color: #1bc943;
}

.btn-outline-success.focus,
.btn-outline-success:focus {
    box-shadow: 0 0 0 0 transparent;
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
    color: #1bc943;
    background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled).active,
.btn-outline-success:not(:disabled):not(.disabled):active,
.show>.btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #1bc943;
    border-color: #1bc943;
}

.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 transparent;
}

.btn-outline-info {
    color: #11c5db;
    border-color: #11c5db;
}

.btn-outline-info:hover {
    color: #fff;
    background-color: #11c5db;
    border-color: #11c5db;
}

.btn-outline-info.focus,
.btn-outline-info:focus {
    box-shadow: 0 0 0 0 transparent;
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
    color: #11c5db;
    background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled).active,
.btn-outline-info:not(:disabled):not(.disabled):active,
.show>.btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #11c5db;
    border-color: #11c5db;
}

.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 transparent;
}

.btn-outline-warning {
    color: #f4772e;
    border-color: #f4772e;
}

.btn-outline-warning:hover {
    color: #fff;
    background-color: #f4772e;
    border-color: #f4772e;
}

.btn-outline-warning.focus,
.btn-outline-warning:focus {
    box-shadow: 0 0 0 0 transparent;
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
    color: #f4772e;
    background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled).active,
.btn-outline-warning:not(:disabled):not(.disabled):active,
.show>.btn-outline-warning.dropdown-toggle {
    color: #fff;
    background-color: #f4772e;
    border-color: #f4772e;
}

.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 transparent;
}

.btn-outline-danger {
    color: #f83245;
    border-color: #f83245;
}

.btn-outline-danger:hover {
    color: #fff;
    background-color: #f83245;
    border-color: #f83245;
}

.btn-outline-danger.focus,
.btn-outline-danger:focus {
    box-shadow: 0 0 0 0 transparent;
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
    color: #f83245;
    background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled).active,
.btn-outline-danger:not(:disabled):not(.disabled):active,
.show>.btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #f83245;
    border-color: #f83245;
}

.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 transparent;
}

.btn-outline-light {
    color: #f4f5fd;
    border-color: #f4f5fd;
}

.btn-outline-light:hover {
    color: #3b3e66;
    background-color: #f4f5fd;
    border-color: #f4f5fd;
}

.btn-outline-light.focus,
.btn-outline-light:focus {
    box-shadow: 0 0 0 0 transparent;
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
    color: #f4f5fd;
    background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled).active,
.btn-outline-light:not(:disabled):not(.disabled):active,
.show>.btn-outline-light.dropdown-toggle {
    color: #3b3e66;
    background-color: #f4f5fd;
    border-color: #f4f5fd;
}

.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 transparent;
}

.btn-outline-dark {
    color: #7a7b97;
    border-color: #7a7b97;
}

.btn-outline-dark:hover {
    color: #fff;
    background-color: #7a7b97;
    border-color: #7a7b97;
}

.btn-outline-dark.focus,
.btn-outline-dark:focus {
    box-shadow: 0 0 0 0 transparent;
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
    color: #7a7b97;
    background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled).active,
.btn-outline-dark:not(:disabled):not(.disabled):active,
.show>.btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #7a7b97;
    border-color: #7a7b97;
}

.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 transparent;
}

.btn-outline-first {
    color: #4191ff;
    border-color: #4191ff;
}

.btn-outline-first:hover {
    color: #fff;
    background-color: #4191ff;
    border-color: #4191ff;
}

.btn-outline-first.focus,
.btn-outline-first:focus {
    box-shadow: 0 0 0 0 transparent;
}

.btn-outline-first.disabled,
.btn-outline-first:disabled {
    color: #4191ff;
    background-color: transparent;
}

.btn-outline-first:not(:disabled):not(.disabled).active,
.btn-outline-first:not(:disabled):not(.disabled):active,
.show>.btn-outline-first.dropdown-toggle {
    color: #fff;
    background-color: #4191ff;
    border-color: #4191ff;
}

.btn-outline-first:not(:disabled):not(.disabled).active:focus,
.btn-outline-first:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-first.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 transparent;
}

.btn-outline-second {
    color: #070919;
    border-color: #070919;
}

.btn-outline-second:hover {
    color: #fff;
    background-color: #070919;
    border-color: #070919;
}

.btn-outline-second.focus,
.btn-outline-second:focus {
    box-shadow: 0 0 0 0 transparent;
}

.btn-outline-second.disabled,
.btn-outline-second:disabled {
    color: #070919;
    background-color: transparent;
}

.btn-outline-second:not(:disabled):not(.disabled).active,
.btn-outline-second:not(:disabled):not(.disabled):active,
.show>.btn-outline-second.dropdown-toggle {
    color: #fff;
    background-color: #070919;
    border-color: #070919;
}

.btn-outline-second:not(:disabled):not(.disabled).active:focus,
.btn-outline-second:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-second.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 transparent;
}

.btn-group-lg>.btn,
.btn-lg {
    padding: 0.84rem 1.8rem;
    font-size: 0.95rem;
    line-height: 1.5;
    border-radius: 0.75rem;
}

.btn-group-sm>.btn,
.btn-sm {
    padding: 0.25rem 1.1rem;
    font-size: 0.83125rem;
    line-height: 1.5;
    border-radius: 0.29rem;
}

.btn-block {
    display: block;
    width: 100%;
}

.btn-block+.btn-block {
    margin-top: 0.5rem;
}

input[type="button"].btn-block,
input[type="reset"].btn-block,
input[type="submit"].btn-block {
    width: 100%;
}

.fade {
    transition: opacity 0.15s linear;
}

.fade:not(.show) {
    opacity: 0;
}

.collapse:not(.show) {
    display: none;
}

.collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    transition: height 0.35s ease;
}

.dropdown,
.dropleft,
.dropright,
.dropup {
    position: relative;
}

.dropdown-toggle {
    white-space: nowrap;
}

.dropdown-toggle:after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty:after {
    margin-left: 0;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 11rem;
    padding: 0.5rem 0;
    margin: 0.325rem 0 0;
    font-size: 0.95rem;
    color: #3b3e66;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(7, 9, 25, 0.15);
    border-radius: 0.29rem;
}

.dropdown-menu-left {
    right: auto;
    left: 0;
}

.dropdown-menu-right {
    right: 0;
    left: auto;
}

.dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: 0.325rem;
}

.dropup .dropdown-toggle:after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0;
    border-right: 0.3em solid transparent;
    border-bottom: 0.3em solid;
    border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty:after {
    margin-left: 0;
}

.dropright .dropdown-menu {
    top: 0;
    right: auto;
    left: 100%;
    margin-top: 0;
    margin-left: 0.325rem;
}

.dropright .dropdown-toggle:after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid transparent;
    border-right: 0;
    border-bottom: 0.3em solid transparent;
    border-left: 0.3em solid;
}

.dropright .dropdown-toggle:empty:after {
    margin-left: 0;
}

.dropright .dropdown-toggle:after {
    vertical-align: 0;
}

.dropleft .dropdown-menu {
    top: 0;
    right: 100%;
    left: auto;
    margin-top: 0;
    margin-right: 0.325rem;
}

.dropleft .dropdown-toggle:after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    display: none;
}

.dropleft .dropdown-toggle:before {
    display: inline-block;
    margin-right: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid transparent;
    border-right: 0.3em solid;
    border-bottom: 0.3em solid transparent;
}

.dropleft .dropdown-toggle:empty:after {
    margin-left: 0;
}

.dropleft .dropdown-toggle:before {
    vertical-align: 0;
}

.dropdown-menu[x-placement^="bottom"],
.dropdown-menu[x-placement^="left"],
.dropdown-menu[x-placement^="right"],
.dropdown-menu[x-placement^="top"] {
    right: auto;
    bottom: auto;
}

.dropdown-divider {
    height: 0;
    margin: 0.5rem 0;
    overflow: hidden;
    border-top: 1px solid #eeeff8;
}

.dropdown-item {
    display: block;
    width: 100%;
    padding: 0.55rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #7a7b97;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
}

.dropdown-item:focus,
.dropdown-item:hover {
    color: #070919;
    text-decoration: none;
    background-color: #f4f5fd;
}

.dropdown-item.active,
.dropdown-item:active {
    color: #000;
    text-decoration: none;
    background-color: #eeeff8;
}

.dropdown-item.disabled,
.dropdown-item:disabled {
    color: #d1d2db;
    pointer-events: none;
    background-color: transparent;
}

.dropdown-header {
    display: block;
    padding: 0.5rem 1.5rem;
    margin-bottom: 0;
    font-size: 0.83125rem;
    color: #d1d2db;
    white-space: nowrap;
}

.dropdown-item-text {
    display: block;
    padding: 0.55rem 1.5rem;
    color: #7a7b97;
}

.btn-group,
.btn-group-vertical {
    position: relative;
    display: -ms-inline-flexbox;
    display: inline-flex;
    vertical-align: middle;
}

.btn-group-vertical>.btn,
.btn-group>.btn {
    position: relative;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
}

.btn-group-vertical>.btn.active,
.btn-group-vertical>.btn:active,
.btn-group-vertical>.btn:focus,
.btn-group-vertical>.btn:hover,
.btn-group>.btn.active,
.btn-group>.btn:active,
.btn-group>.btn:focus,
.btn-group>.btn:hover {
    z-index: 1;
}

.btn-toolbar {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

.btn-toolbar .input-group {
    width: auto;
}

.btn-group>.btn-group:not(:first-child),
.btn-group>.btn:not(:first-child) {
    margin-left: -2px;
}

.btn-group>.btn-group:not(:last-child)>.btn,
.btn-group>.btn:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.btn-group>.btn-group:not(:first-child)>.btn,
.btn-group>.btn:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
    padding-right: 0.975rem;
    padding-left: 0.975rem;
}

.dropdown-toggle-split:after,
.dropright .dropdown-toggle-split:after,
.dropup .dropdown-toggle-split:after {
    margin-left: 0;
}

.dropleft .dropdown-toggle-split:before {
    margin-right: 0;
}

.btn-group-sm>.btn+.dropdown-toggle-split,
.btn-sm+.dropdown-toggle-split {
    padding-right: 0.825rem;
    padding-left: 0.825rem;
}

.btn-group-lg>.btn+.dropdown-toggle-split,
.btn-lg+.dropdown-toggle-split {
    padding-right: 1.35rem;
    padding-left: 1.35rem;
}

.btn-group-vertical {
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-pack: center;
    justify-content: center;
}

.btn-group-vertical>.btn,
.btn-group-vertical>.btn-group {
    width: 100%;
}

.btn-group-vertical>.btn-group:not(:first-child),
.btn-group-vertical>.btn:not(:first-child) {
    margin-top: -2px;
}

.btn-group-vertical>.btn-group:not(:last-child)>.btn,
.btn-group-vertical>.btn:not(:last-child):not(.dropdown-toggle) {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.btn-group-vertical>.btn-group:not(:first-child)>.btn,
.btn-group-vertical>.btn:not(:first-child) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.btn-group-toggle>.btn,
.btn-group-toggle>.btn-group>.btn {
    margin-bottom: 0;
}

.btn-group-toggle>.btn-group>.btn input[type="checkbox"],
.btn-group-toggle>.btn-group>.btn input[type="radio"],
.btn-group-toggle>.btn input[type="checkbox"],
.btn-group-toggle>.btn input[type="radio"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none;
}

.input-group {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: stretch;
    align-items: stretch;
    width: 100%;
}

.input-group>.custom-file,
.input-group>.custom-select,
.input-group>.form-control,
.input-group>.form-control-plaintext {
    position: relative;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0;
}

.input-group>.custom-file+.custom-file,
.input-group>.custom-file+.custom-select,
.input-group>.custom-file+.form-control,
.input-group>.custom-select+.custom-file,
.input-group>.custom-select+.custom-select,
.input-group>.custom-select+.form-control,
.input-group>.form-control+.custom-file,
.input-group>.form-control+.custom-select,
.input-group>.form-control+.form-control,
.input-group>.form-control-plaintext+.custom-file,
.input-group>.form-control-plaintext+.custom-select,
.input-group>.form-control-plaintext+.form-control {
    margin-left: -1px;
}

.input-group>.custom-file .custom-file-input:focus~.custom-file-label,
.input-group>.custom-select:focus,
.input-group>.form-control:focus {
    z-index: 3;
}

.input-group>.custom-file .custom-file-input:focus {
    z-index: 4;
}

.input-group>.custom-select:not(:last-child),
.input-group>.form-control:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input-group>.custom-select:not(:first-child),
.input-group>.form-control:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.input-group>.custom-file {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
}

.input-group>.custom-file:not(:last-child) .custom-file-label,
.input-group>.custom-file:not(:last-child) .custom-file-label:after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input-group>.custom-file:not(:first-child) .custom-file-label {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.input-group-append,
.input-group-prepend {
    display: -ms-flexbox;
    display: flex;
}

.input-group-append .btn,
.input-group-prepend .btn {
    position: relative;
    z-index: 2;
}

.input-group-append .btn:focus,
.input-group-prepend .btn:focus {
    z-index: 3;
}

.input-group-append .btn+.btn,
.input-group-append .btn+.input-group-text,
.input-group-append .input-group-text+.btn,
.input-group-append .input-group-text+.input-group-text,
.input-group-prepend .btn+.btn,
.input-group-prepend .btn+.input-group-text,
.input-group-prepend .input-group-text+.btn,
.input-group-prepend .input-group-text+.input-group-text {
    margin-left: -1px;
}

.input-group-prepend {
    margin-right: -1px;
}

.input-group-append {
    margin-left: -1px;
}

.input-group-text {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    padding: 0.53rem 1.3rem;
    margin-bottom: 0;
    font-size: 0.95rem;
    font-weight: 400;
    line-height: 1.5;
    color: #3b3e66;
    text-align: center;
    white-space: nowrap;
    background-color: #f8f9ff;
    border: 1px solid #d1d2db;
    border-radius: 0.29rem;
}

.input-group-text input[type="checkbox"],
.input-group-text input[type="radio"] {
    margin-top: 0;
}

.input-group-lg>.custom-select,
.input-group-lg>.form-control:not(textarea) {
    height: calc(1.5em + 1.68rem + 2px);
}

.input-group-lg>.custom-select,
.input-group-lg>.form-control,
.input-group-lg>.input-group-append>.btn,
.input-group-lg>.input-group-append>.input-group-text,
.input-group-lg>.input-group-prepend>.btn,
.input-group-lg>.input-group-prepend>.input-group-text {
    padding: 0.84rem 1.8rem;
    font-size: 0.95rem;
    line-height: 1.5;
    border-radius: 0.29rem;
}

.input-group-sm>.custom-select,
.input-group-sm>.form-control:not(textarea) {
    height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm>.custom-select,
.input-group-sm>.form-control,
.input-group-sm>.input-group-append>.btn,
.input-group-sm>.input-group-append>.input-group-text,
.input-group-sm>.input-group-prepend>.btn,
.input-group-sm>.input-group-prepend>.input-group-text {
    padding: 0.25rem 1.1rem;
    font-size: 0.83125rem;
    line-height: 1.5;
    border-radius: 0.29rem;
}

.input-group-lg>.custom-select,
.input-group-sm>.custom-select {
    padding-right: 2.3rem;
}

.input-group>.input-group-append:last-child>.btn:not(:last-child):not(.dropdown-toggle),
.input-group>.input-group-append:last-child>.input-group-text:not(:last-child),
.input-group>.input-group-append:not(:last-child)>.btn,
.input-group>.input-group-append:not(:last-child)>.input-group-text,
.input-group>.input-group-prepend>.btn,
.input-group>.input-group-prepend>.input-group-text {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input-group>.input-group-append>.btn,
.input-group>.input-group-append>.input-group-text,
.input-group>.input-group-prepend:first-child>.btn:not(:first-child),
.input-group>.input-group-prepend:first-child>.input-group-text:not(:first-child),
.input-group>.input-group-prepend:not(:first-child)>.btn,
.input-group>.input-group-prepend:not(:first-child)>.input-group-text {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.custom-control {
    position: relative;
    display: block;
    min-height: 1.425rem;
    padding-left: 2.1rem;
}

.custom-control-inline {
    display: -ms-inline-flexbox;
    display: inline-flex;
    margin-right: 1.2rem;
}

.custom-control-input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}

.custom-control-input:checked~.custom-control-label:before {
    color: #fff;
    border-color: #3c44b1;
    background-color: #3c44b1;
}

.custom-control-input:focus~.custom-control-label:before {
    box-shadow: 0 0.313rem 0.719rem rgba(122, 123, 151, 0.1),
        0 0.156rem 0.125rem rgba(122, 123, 151, 0.06);
}

.custom-control-input:focus:not(:checked)~.custom-control-label:before {
    border-color: #9297da;
}

.custom-control-input:not(:disabled):active~.custom-control-label:before {
    color: #fff;
    background-color: #b8bce7;
    border-color: #b8bce7;
}

.custom-control-input:disabled~.custom-control-label {
    color: #d1d2db;
}

.custom-control-input:disabled~.custom-control-label:before {
    background-color: #eeeff8;
}

.custom-control-label {
    position: relative;
    margin-bottom: 0;
    vertical-align: top;
}

.custom-control-label:before {
    pointer-events: none;
    background-color: #fff;
    border: 1px solid #d1d2db;
}

.custom-control-label:after,
.custom-control-label:before {
    position: absolute;
    top: 0.0625rem;
    left: -2.1rem;
    display: block;
    width: 1.3rem;
    height: 1.3rem;
    content: "";
}

.custom-control-label:after {
    background: no-repeat 50%/50% 50%;
}

.custom-checkbox .custom-control-label:before {
    border-radius: 0.29rem;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label:after {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3E%3C/svg%3E");
}

.custom-checkbox .custom-control-input:indeterminate~.custom-control-label:before {
    border-color: #3c44b1;
    background-color: #3c44b1;
}

.custom-checkbox .custom-control-input:indeterminate~.custom-control-label:after {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E");
}

.custom-checkbox .custom-control-input:disabled:checked~.custom-control-label:before {
    background-color: rgba(60, 68, 177, 0.5);
}

.custom-checkbox .custom-control-input:disabled:indeterminate~.custom-control-label:before {
    background-color: rgba(60, 68, 177, 0.5);
}

.custom-radio .custom-control-label:before {
    border-radius: 50%;
}

.custom-radio .custom-control-input:checked~.custom-control-label:after {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
}

.custom-radio .custom-control-input:disabled:checked~.custom-control-label:before {
    background-color: rgba(60, 68, 177, 0.5);
}

.custom-switch {
    padding-left: 3.4rem;
}

.custom-switch .custom-control-label:before {
    left: -3.4rem;
    width: 2.6rem;
    pointer-events: all;
    border-radius: 0.65rem;
}

.custom-switch .custom-control-label:after {
    top: calc(0.0625rem + 2px);
    left: calc(-3.4rem + 2px);
    width: calc(1.3rem - 4px);
    height: calc(1.3rem - 4px);
    background-color: #d1d2db;
    border-radius: 0.65rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.custom-switch .custom-control-input:checked~.custom-control-label:after {
    background-color: #fff;
    transform: translateX(1.3rem);
}

.custom-switch .custom-control-input:disabled:checked~.custom-control-label:before {
    background-color: rgba(60, 68, 177, 0.5);
}

.custom-select {
    display: inline-block;
    width: 100%;
    height: calc(1.5em + 1.06rem + 2px);
    padding: 0.53rem 2.3rem 0.53rem 1.3rem;
    font-size: 0.95rem;
    font-weight: 400;
    line-height: 1.5;
    color: #3b3e66;
    vertical-align: middle;
    background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%237a7b97' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 1.3rem center/8px 10px;
    background-color: #fff;
    border: 1px solid #d1d2db;
    border-radius: 0.29rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.custom-select:focus {
    border-color: #9297da;
    outline: 0;
    box-shadow: 0 0.313rem 0.719rem rgba(122, 123, 151, 0.1),
        0 0.156rem 0.125rem rgba(122, 123, 151, 0.06);
}

.custom-select:focus::-ms-value {
    color: #3b3e66;
    background-color: #fff;
}

.custom-select[multiple],
.custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 1.3rem;
    background-image: none;
}

.custom-select:disabled {
    color: #d1d2db;
    background-color: #f4f5fd;
}

.custom-select::-ms-expand {
    display: none;
}

.custom-select-sm {
    height: calc(1.5em + 0.5rem + 2px);
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 1.1rem;
    font-size: 0.83125rem;
}

.custom-select-lg {
    height: calc(1.5em + 1.68rem + 2px);
    padding-top: 0.84rem;
    padding-bottom: 0.84rem;
    padding-left: 1.8rem;
    font-size: 0.95rem;
}

.custom-file {
    display: inline-block;
    margin-bottom: 0;
}

.custom-file,
.custom-file-input {
    position: relative;
    width: 100%;
    height: calc(1.5em + 1.06rem + 2px);
}

.custom-file-input {
    z-index: 2;
    margin: 0;
    opacity: 0;
}

.custom-file-input:focus~.custom-file-label {
    border-color: #9297da;
    box-shadow: 0 0.313rem 0.719rem rgba(122, 123, 151, 0.1),
        0 0.156rem 0.125rem rgba(122, 123, 151, 0.06);
}

.custom-file-input:disabled~.custom-file-label {
    background-color: #eeeff8;
}

.custom-file-input:lang(en)~.custom-file-label:after {
    content: "Browse";
}

.custom-file-input~.custom-file-label[data-browse]:after {
    content: attr(data-browse);
}

.custom-file-label {
    left: 0;
    z-index: 1;
    height: calc(1.5em + 1.06rem + 2px);
    font-weight: 400;
    background-color: #fff;
    border: 1px solid #d1d2db;
    border-radius: 0.29rem;
}

.custom-file-label,
.custom-file-label:after {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.53rem 1.3rem;
    line-height: 1.5;
    color: #3b3e66;
}

.custom-file-label:after {
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 1.06rem);
    content: "Browse";
    background-color: #f8f9ff;
    border-left: inherit;
    border-radius: 0 0.29rem 0.29rem 0;
}

.custom-range {
    width: 100%;
    height: 1.4rem;
    padding: 0;
    background-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.custom-range:focus {
    outline: none;
}

.custom-range:focus::-webkit-slider-thumb {
    box-shadow: 0 0 0 1px #fff, 0 0.313rem 0.719rem rgba(122, 123, 151, 0.1),
        0 0.156rem 0.125rem rgba(122, 123, 151, 0.06);
}

.custom-range:focus::-moz-range-thumb {
    box-shadow: 0 0 0 1px #fff, 0 0.313rem 0.719rem rgba(122, 123, 151, 0.1),
        0 0.156rem 0.125rem rgba(122, 123, 151, 0.06);
}

.custom-range:focus::-ms-thumb {
    box-shadow: 0 0 0 1px #fff, 0 0.313rem 0.719rem rgba(122, 123, 151, 0.1),
        0 0.156rem 0.125rem rgba(122, 123, 151, 0.06);
}

.custom-range::-moz-focus-outer {
    border: 0;
}

.custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #3c44b1;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
    appearance: none;
}

.custom-range::-webkit-slider-thumb:active {
    background-color: #b8bce7;
}

.custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #eeeff8;
    border-color: transparent;
    border-radius: 1rem;
}

.custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #3c44b1;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
    -moz-appearance: none;
    appearance: none;
}

.custom-range::-moz-range-thumb:active {
    background-color: #b8bce7;
}

.custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #eeeff8;
    border-color: transparent;
    border-radius: 1rem;
}

.custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #3c44b1;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
    appearance: none;
}

.custom-range::-ms-thumb:active {
    background-color: #b8bce7;
}

.custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem;
}

.custom-range::-ms-fill-lower,
.custom-range::-ms-fill-upper {
    background-color: #eeeff8;
    border-radius: 1rem;
}

.custom-range::-ms-fill-upper {
    margin-right: 15px;
}

.custom-range:disabled::-webkit-slider-thumb {
    background-color: #dfe0ea;
}

.custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default;
}

.custom-range:disabled::-moz-range-thumb {
    background-color: #dfe0ea;
}

.custom-range:disabled::-moz-range-track {
    cursor: default;
}

.custom-range:disabled::-ms-thumb {
    background-color: #dfe0ea;
}

.custom-control-label:before,
.custom-file-label,
.custom-select {
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
}

.nav {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.nav-link {
    display: block;
    padding: 0.5rem 1rem;
}

.nav-link:focus,
.nav-link:hover {
    text-decoration: none;
}

.nav-link.disabled {
    color: #d1d2db;
    pointer-events: none;
    cursor: default;
}

.nav-tabs {
    border-bottom: 1px solid #d1d2db;
}

.nav-tabs .nav-item {
    margin-bottom: -1px;
}

.nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.29rem;
    border-top-right-radius: 0.29rem;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
    border-color: #d1d2db;
}

.nav-tabs .nav-link.disabled {
    color: #d1d2db;
    background-color: transparent;
    border-color: transparent;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    color: #070919;
    background-color: #fff;
    border-color: #d1d2db #d1d2db #fff;
}

.nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.nav-pills .nav-link {
    border-radius: 0.29rem;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #fff;
    background-color: #3c44b1;
}

.nav-fill .nav-item {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    text-align: center;
}

.nav-justified .nav-item {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    text-align: center;
}

.tab-content>.tab-pane {
    display: none;
}

.tab-content>.active {
    display: block;
}

.navbar {
    position: relative;
    padding: 0.5rem 1rem;
}

.navbar,
.navbar>.container,
.navbar>.container-fluid {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.navbar-brand {
    display: inline-block;
    padding-top: 0.32187rem;
    padding-bottom: 0.32187rem;
    margin-right: 1rem;
    font-size: 1.1875rem;
    line-height: inherit;
    white-space: nowrap;
}

.navbar-brand:focus,
.navbar-brand:hover {
    text-decoration: none;
}

.navbar-nav {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    background: white;
}

.navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0;
}

.navbar-nav .dropdown-menu {
    position: static;
    float: none;
}

.navbar-text {
    display: inline-block;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.navbar-collapse {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-align: center;
    align-items: center;
}

.navbar-toggler {
    padding: .25rem .75rem;
    font-size: 1.1875rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: .65rem;
}

.navbar-toggler:focus,
.navbar-toggler:hover {
    text-decoration: none;
}

.navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: "";
    background: no-repeat 50%;
    background-size: 100% 100%;
}

@media (max-width:575.98px) {

    .navbar-expand-sm>.container,
    .navbar-expand-sm>.container-fluid {
        padding-right: 0;
        padding-left: 0
    }
}

@media (min-width:576px) {
    .navbar-expand-sm {
        -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
        -ms-flex-pack: start;
        justify-content: flex-start
    }

    .navbar-expand-sm .navbar-nav {
        -ms-flex-direction: row;
        flex-direction: row
    }

    .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem
    }

    .navbar-expand-sm>.container,
    .navbar-expand-sm>.container-fluid {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap
    }

    .navbar-expand-sm .navbar-collapse {
        display: -ms-flexbox !important;
        display: flex !important;
        -ms-flex-preferred-size: auto;
        flex-basis: auto
    }

    .navbar-expand-sm .navbar-toggler {
        display: none
    }
}

@media (max-width:767.98px) {

    .navbar-expand-md>.container,
    .navbar-expand-md>.container-fluid {
        padding-right: 0;
        padding-left: 0
    }
}

@media (min-width:768px) {
    .navbar-expand-md {
        -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
        -ms-flex-pack: start;
        justify-content: flex-start
    }

    .navbar-expand-md .navbar-nav {
        -ms-flex-direction: row;
        flex-direction: row
    }

    .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-md .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem
    }

    .navbar-expand-md>.container,
    .navbar-expand-md>.container-fluid {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap
    }

    .navbar-expand-md .navbar-collapse {
        display: -ms-flexbox !important;
        display: flex !important;
        -ms-flex-preferred-size: auto;
        flex-basis: auto
    }

    .navbar-expand-md .navbar-toggler {
        display: none
    }
}

@media (max-width:991.98px) {

    .navbar-expand-lg>.container,
    .navbar-expand-lg>.container-fluid {
        padding-right: 0;
        padding-left: 0
    }
}

@media (min-width:992px) {
    .navbar-expand-lg {
        -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
        -ms-flex-pack: start;
        justify-content: flex-start
    }

    .navbar-expand-lg .navbar-nav {
        -ms-flex-direction: row;
        flex-direction: row
    }

    .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem
    }

    .navbar-expand-lg>.container,
    .navbar-expand-lg>.container-fluid {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap
    }

    .navbar-expand-lg .navbar-collapse {
        display: -ms-flexbox !important;
        display: flex !important;
        -ms-flex-preferred-size: auto;
        flex-basis: auto
    }

    .navbar-expand-lg .navbar-toggler {
        display: none
    }
}

@media (max-width:1199.98px) {

    .navbar-expand-xl>.container,
    .navbar-expand-xl>.container-fluid {
        padding-right: 0;
        padding-left: 0
    }
}

@media (min-width:1200px) {
    .navbar-expand-xl {
        -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
        -ms-flex-pack: start;
        justify-content: flex-start
    }

    .navbar-expand-xl .navbar-nav {
        -ms-flex-direction: row;
        flex-direction: row
    }

    .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem
    }

    .navbar-expand-xl>.container,
    .navbar-expand-xl>.container-fluid {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap
    }

    .navbar-expand-xl .navbar-collapse {
        display: -ms-flexbox !important;
        display: flex !important;
        -ms-flex-preferred-size: auto;
        flex-basis: auto
    }

    .navbar-expand-xl .navbar-toggler {
        display: none
    }
}

.navbar-expand {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

.navbar-expand>.container,
.navbar-expand>.container-fluid {
    padding-right: 0;
    padding-left: 0;
}

.navbar-expand .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
    position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
}

.navbar-expand>.container,
.navbar-expand>.container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
}

.navbar-expand .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
}

.navbar-expand .navbar-toggler {
    display: none;
}

.navbar-light .navbar-brand,
.navbar-light .navbar-brand:focus,
.navbar-light .navbar-brand:hover {
    color: rgba(7, 9, 25, 0.9);
}

.navbar-light .navbar-nav .nav-link {
    color: rgba(7, 9, 25, 0.5);
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
    color: rgba(7, 9, 25, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(7, 9, 25, 0.3);
}

.navbar-light .navbar-nav .active>.nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show>.nav-link {
    color: rgba(7, 9, 25, 0.9);
}

.navbar-light .navbar-toggler {
    color: rgba(7, 9, 25, .5);
    border-color: rgba(7, 9, 25, .1);
}

.navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(7, 9, 25, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-light .navbar-text {
    color: rgba(7, 9, 25, 0.5);
}

.navbar-light .navbar-text a,
.navbar-light .navbar-text a:focus,
.navbar-light .navbar-text a:hover {
    color: rgba(7, 9, 25, 0.9);
}

.navbar-dark .navbar-brand,
.navbar-dark .navbar-brand:focus,
.navbar-dark .navbar-brand:hover {
    color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
    color: hsla(0, 0%, 100%, 0.5);
}

.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
    color: hsla(0, 0%, 100%, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
    color: hsla(0, 0%, 100%, 0.25);
}

.navbar-dark .navbar-nav .active>.nav-link,
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .show>.nav-link {
    color: #fff;
}

.navbar-dark .navbar-toggler {
    color: hsla(0, 0%, 100%, .5);
    border-color: hsla(0, 0%, 100%, .1);
}

.navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-dark .navbar-text {
    color: hsla(0, 0%, 100%, 0.5);
}

.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:focus,
.navbar-dark .navbar-text a:hover {
    color: #fff;
}

.card {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 2px solid rgba(122, 123, 151, 0.3);
    border-radius: 5px;
}


.card-service-image {
    background: rgba(255, 255, 255, 0.49);
    border: 0.5px solid rgba(29, 115, 225, 0.61);
    box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    height: 200px;
    max-width: 300px;
    margin: auto;
}

.card-service-text {
    background: rgba(217, 217, 217, 0.25);
    border: 0.5px solid rgba(29, 115, 225, 0.61);
    box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    height: 200px;
    padding-top: 30px;
    padding-left: 10px;
    padding-right: 10px;
    max-width: 300px;
    margin: auto;
}

.card-aboutus-pbe {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0 solid rgba(122, 123, 151, 0.3);
    height: 100%;
    min-height: 200px;
    width: 100%;
    border-radius: 2rem;
    box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.card1 {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fffbe6;
    background-clip: border-box;
    border: 0 solid rgba(122, 123, 151, 0.3);
    border-radius: 0.65rem;
}

.blog-details-card {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0 solid rgba(122, 123, 151, 0.3);
    border-radius: 5px;
    box-shadow: 0 0.46875rem 2.1875rem rgba(0, 0, 0, 0.03), 0 0.9375rem 1.40625rem rgba(0, 0, 0, 0.1), 0 0.25rem 0.53125rem rgba(0, 0, 0, 0.05), 0 0.125rem 0.1875rem rgba(0, 0, 0, 0.03);
}

.blog-details-card img {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.scard-candp {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    background-clip: border-box;
    background: rgba(217, 250, 221, 0.61);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    max-width: 200px;
    margin: auto;
    margin-bottom: 10px;
}

.card2 {
    background: rgba(201, 219, 242, 0.1);
    box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    min-height: 315px;
    width: 95%;
    max-width: 500px;
    margin: auto;
}

.card-candp {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0 solid rgba(122, 123, 151, 0.3);
    border-radius: 0.65rem;
    height: 100%;
    min-height: 150px;
    width: 95%;
    max-width: 400px;
    margin: auto;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.card-pricinglist {
    position: relative;
    display: flex;
    display: -ms-flexbox;
    flex-direction: column;
    -ms-flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #ECF3FE;
    background-clip: border-box;
    border: 2px solid rgba(122, 123, 151, 0.3);
    border-radius: 5px;
    height: 100%;
    min-height: 150px;
    width: 100%;
    max-width: 1000px;
    margin: auto;
}

.mx-wdth-500px {
    max-width: 500px;
}

.card3 {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-clip: border-box;
    border: 0 solid rgb(190, 190, 199);
    height: 100%;
    min-height: 150px;
    width: 100%;
    margin: auto;
    background: rgba(217, 217, 217, 0.07);
    box-shadow: 0px 3.89416px 3.89416px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding-top: 20px;
    padding-bottom: 10px;
}

#card-projects1,
#card-projects2 {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0 solid rgba(122, 123, 151, 0.3);
    border-radius: 0.65rem;
    height: 100%;
    min-height: 150px;
}

.card-services {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0 solid rgba(122, 123, 151, 0.3);
    border-radius: 0.65rem;
}

#project-card-sec {
    padding-left: 15%;
    padding-right: 15%;
}

#card-projects2 {
    padding-left: 5%;
}

#card-projects1 {
    padding-right: 5%;
}

.card>hr {
    margin-right: 0;
    margin-left: 0;
}

.card>.list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.65rem;
    border-top-right-radius: 0.65rem;
}

.card>.list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.65rem;
    border-bottom-left-radius: 0.65rem;
}

.card-body {
    margin-top: 10px;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1.25rem;
}

.card-title {
    margin-bottom: 0.75rem;
}

.card-title2 {
    margin-bottom: 0.75rem;
    height: 100px;
}

.card-subtitle {
    margin-top: -0.375rem;
}

.card-subtitle,
.card-text:last-child {
    margin-bottom: 0;
}

.card-link:hover {
    text-decoration: none;
}

.card-link+.card-link {
    margin-left: 1.25rem;
}

.card-header {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: #fffbe6;
    border-bottom: 0 solid rgba(122, 123, 151, 0.3);
}

.card-header2 {
    height: 100px;
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: #fff;
    border-bottom: 0 solid rgba(122, 123, 151, 0.3);
}

.card-header:first-child {
    border-radius: 0.65rem 0.65rem 0 0;
}

.card-header+.list-group .list-group-item:first-child {
    border-top: 0;
}

.card-footer {
    padding: 0.75rem 1.25rem;
    background-color: #fffbe6;
    border-top: 0 solid rgba(122, 123, 151, 0.3);
}

.card-footer:last-child {
    border-radius: 0 0 0.65rem 0.65rem;
}

.card-header-tabs {
    margin-bottom: -0.75rem;
    border-bottom: 0;
}

.card-header-pills,
.card-header-tabs {
    margin-right: -0.625rem;
    margin-left: -0.625rem;
}

.card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1.25rem;
}

.card-img {
    width: 100%;
    border-radius: 0.65rem;
}

.card-img-top {
    width: 100%;
    border-top-left-radius: 0.65rem;
    border-top-right-radius: 0.65rem;
}

.card-img-bottom {
    width: 100%;
    border-bottom-right-radius: 0.65rem;
    border-bottom-left-radius: 0.65rem;
}

.card-deck {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
}

.card-deck .card {
    margin-bottom: 20px;
}

.card-group {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
}

.card-group>.card {
    margin-bottom: 20px;
}

.card-columns .card {
    margin-bottom: 0.75rem;
}

.accordion>.card {
    overflow: hidden;
}

.accordion>.card:not(:first-of-type) .card-header:first-child {
    border-radius: 0;
}

.accordion>.card:not(:first-of-type):not(:last-of-type) {
    border-bottom: 0;
    border-radius: 0;
}

.accordion>.card:first-of-type {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.accordion>.card:last-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.accordion>.card .card-header {
    margin-bottom: 0;
}

.breadcrumb {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 0.75rem 1rem;
    margin-bottom: 1rem;
    list-style: none;
    background-color: #f4f5fd;
    border-radius: 0.65rem;
}

.breadcrumb-item+.breadcrumb-item {
    padding-left: 0.5rem;
}

.breadcrumb-item+.breadcrumb-item:before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #d1d2db;
    content: "/";
}

.breadcrumb-item+.breadcrumb-item:hover:before {
    text-decoration: underline;
    text-decoration: none;
}

.breadcrumb-item.active {
    color: #d1d2db;
}

.pagination {
    display: -ms-flexbox;
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 0.65rem;
}

.page-link {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #3b3e66;
    background-color: #fff;
    border: 1px solid #e6e7f1;
}

.page-link:hover {
    z-index: 2;
    color: #292e78;
    text-decoration: none;
    background-color: #f8f9ff;
    border-color: #3c44b1;
}

.page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0.313rem 0.719rem rgba(122, 123, 151, 0.1),
        0 0.156rem 0.125rem rgba(122, 123, 151, 0.06);
}

.page-item:first-child .page-link {
    margin-left: 0;
    border-top-left-radius: 0.65rem;
    border-bottom-left-radius: 0.65rem;
}

.page-item:last-child .page-link {
    border-top-right-radius: 0.65rem;
    border-bottom-right-radius: 0.65rem;
}

.page-item.active .page-link {
    z-index: 1;
    color: #fff;
    background-color: #3c44b1;
    border-color: #3c44b1;
}

.page-item.disabled .page-link {
    color: #d1d2db;
    pointer-events: none;
    cursor: auto;
    background-color: #fff;
    border-color: #eeeff8;
}

.pagination-lg .page-link {
    padding: 0.75rem 1.5rem;
    font-size: 1.1875rem;
    line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
    border-top-left-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
}

.pagination-lg .page-item:last-child .page-link {
    border-top-right-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
}

.pagination-sm .page-link {
    padding: 0.25rem 0.5rem;
    font-size: 0.83125rem;
    line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
    border-top-left-radius: 0.29rem;
    border-bottom-left-radius: 0.29rem;
}

.pagination-sm .page-item:last-child .page-link {
    border-top-right-radius: 0.29rem;
    border-bottom-right-radius: 0.29rem;
}

.badge {
    display: inline-block;
    padding: 0 0.7em;
    font-size: 70%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.2rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

a.badge:focus,
a.badge:hover {
    text-decoration: none;
}

.badge:empty {
    display: none;
}

.btn .badge {
    position: relative;
    top: -1px;
}

.badge-pill {
    padding-right: 0.85em;
    padding-left: 0.85em;
    border-radius: 10rem;
}

.badge-primary {
    color: #fff;
    background-color: #3c44b1;
}

a.badge-primary:focus,
a.badge-primary:hover {
    color: #fff;
    background-color: #2f358b;
}

a.badge-primary.focus,
a.badge-primary:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(60, 68, 177, 0.5);
}

.badge-secondary {
    color: #3b3e66;
    background-color: #f8f9ff;
}

a.badge-secondary:focus,
a.badge-secondary:hover {
    color: #3b3e66;
    background-color: #c5cdff;
}

a.badge-secondary.focus,
a.badge-secondary:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 255, 0.5);
}

.badge-success {
    color: #fff;
    background-color: #1bc943;
}

a.badge-success:focus,
a.badge-success:hover {
    color: #fff;
    background-color: #159c34;
}

a.badge-success.focus,
a.badge-success:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(27, 201, 67, 0.5);
}

.badge-info {
    color: #fff;
    background-color: #11c5db;
}

a.badge-info:focus,
a.badge-info:hover {
    color: #fff;
    background-color: #0d9aac;
}

a.badge-info.focus,
a.badge-info:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(17, 197, 219, 0.5);
}

.badge-warning {
    color: #fff;
    background-color: #f4772e;
}

a.badge-warning:focus,
a.badge-warning:hover {
    color: #fff;
    background-color: #e35b0c;
}

a.badge-warning.focus,
a.badge-warning:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(244, 119, 46, 0.5);
}

.badge-danger {
    color: #fff;
    background-color: #f83245;
}

a.badge-danger:focus,
a.badge-danger:hover {
    color: #fff;
    background-color: #ef081e;
}

a.badge-danger.focus,
a.badge-danger:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 50, 69, 0.5);
}

.badge-light {
    color: #3b3e66;
    background-color: #f4f5fd;
}

a.badge-light:focus,
a.badge-light:hover {
    color: #3b3e66;
    background-color: #c9cef5;
}

a.badge-light.focus,
a.badge-light:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(244, 245, 253, 0.5);
}

.badge-dark {
    color: #fff;
    background-color: #7a7b97;
}

a.badge-dark:focus,
a.badge-dark:hover {
    color: #fff;
    background-color: #61627d;
}

a.badge-dark.focus,
a.badge-dark:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(122, 123, 151, 0.5);
}

.badge-first {
    color: #fff;
    background-color: #4191ff;
}

a.badge-first:focus,
a.badge-first:hover {
    color: #fff;
    background-color: #0e73ff;
}

a.badge-first.focus,
a.badge-first:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(65, 145, 255, 0.5);
}

.badge-second {
    color: #fff;
    background-color: #070919;
}

a.badge-second:focus,
a.badge-second:hover {
    color: #fff;
    background-color: #000;
}

a.badge-second.focus,
a.badge-second:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(7, 9, 25, 0.5);
}

.jumbotron {
    padding: 2rem 1rem;
    margin-bottom: 2rem;
    background-color: #f4f5fd;
    border-radius: 0.75rem;
}

.jumbotron-fluid {
    padding-right: 0;
    padding-left: 0;
    border-radius: 0;
}

.alert {
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.65rem;
}

.alert-heading {
    color: inherit;
}

.alert-link {
    font-weight: 700;
}

.alert-dismissible {
    padding-right: 3.925rem;
}

.alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit;
}

.alert-primary {
    color: #232868;
    background-color: #d8daef;
    border-color: #c8cbe9;
}

.alert-primary hr {
    border-top-color: #b6bae2;
}

.alert-primary .alert-link {
    color: #161942;
}

.alert-secondary {
    color: #848691;
    background-color: #fefeff;
    border-color: #fdfdff;
}

.alert-secondary hr {
    border-top-color: #e4e4ff;
}

.alert-secondary .alert-link {
    color: #6b6d77;
}

.alert-success {
    color: #116d2f;
    background-color: #d1f4d9;
    border-color: #bff0ca;
}

.alert-success hr {
    border-top-color: #aaebb9;
}

.alert-success .alert-link {
    color: #0a411c;
}

.alert-info {
    color: #0c6b7e;
    background-color: #cff3f8;
    border-color: #bceff5;
}

.alert-info hr {
    border-top-color: #a6eaf2;
}

.alert-info .alert-link {
    color: #08434f;
}

.alert-warning {
    color: #824224;
    background-color: #fde4d5;
    border-color: #fcd9c4;
}

.alert-warning hr {
    border-top-color: #fbc9ac;
}

.alert-warning .alert-link {
    color: #5a2e19;
}

.alert-danger {
    color: #841e30;
    background-color: #fed6da;
    border-color: #fdc6cb;
}

.alert-danger hr {
    border-top-color: #fcadb5;
}

.alert-danger .alert-link {
    color: #5a1521;
}

.alert-light {
    color: #828490;
    background-color: #fdfdff;
    border-color: #fcfcfe;
}

.alert-light hr {
    border-top-color: #e9e9f8;
}

.alert-light .alert-link {
    color: #696b76;
}

.alert-dark {
    color: #43445b;
    background-color: #e4e5ea;
    border-color: #dadae2;
}

.alert-dark hr {
    border-top-color: #ccccd7;
}

.alert-dark .alert-link {
    color: #2d2e3e;
}

.alert-first {
    color: #255091;
    background-color: #d9e9ff;
    border-color: #cae0ff;
}

.alert-first hr {
    border-top-color: #b1d1ff;
}

.alert-first .alert-link {
    color: #1b3a68;
}

.alert-second {
    color: #070919;
    background-color: #cdced1;
    border-color: #bababf;
}

.alert-second hr {
    border-top-color: #adadb3;
}

.alert-second .alert-link {
    color: #000;
}

@keyframes a {
    0% {
        background-position: 1rem 0;
    }

    to {
        background-position: 0 0;
    }
}

.progress {
    height: 1rem;
    overflow: hidden;
    font-size: 0.7125rem;
    background-color: #f4f5fd;
    border-radius: 0.65rem;
}

.progress,
.progress-bar {
    display: -ms-flexbox;
    display: flex;
}

.progress-bar {
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #3c44b1;
    transition: width 0.6s ease;
}

.progress-bar-striped {
    background-image: linear-gradient(45deg,
            hsla(0, 0%, 100%, 0.15) 25%,
            transparent 0,
            transparent 50%,
            hsla(0, 0%, 100%, 0.15) 0,
            hsla(0, 0%, 100%, 0.15) 75%,
            transparent 0,
            transparent);
    background-size: 1rem 1rem;
}

.progress-bar-animated {
    animation: a 1s linear infinite;
}

.active-tabs {
    background: white;
    border-bottom: 1px solid transparent;
}

.media {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
    align-items: flex-start;
}

.media-body {
    -ms-flex: 1;
    flex: 1;
}

.list-group {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
}

.list-group-item-action {
    width: 100%;
    color: #7a7b97;
    text-align: inherit;
}

.list-group-item-action:focus,
.list-group-item-action:hover {
    z-index: 1;
    color: #070919;
    text-decoration: none;
    background-color: #f4f5fd;
}

.list-group-item-action:active {
    color: #000;
    background-color: #eeeff8;
}

.list-group-item {
    position: relative;
    display: block;
    padding: 0.75rem 1.25rem;
    margin-bottom: -1px;
    background-color: #fffbe6;
    border: 1px solid rgba(7, 9, 25, 0.125);
}

.list-group-item:first-child {
    border-top-left-radius: 0.65rem;
    border-top-right-radius: 0.65rem;
}

.list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 0.65rem;
    border-bottom-left-radius: 0.65rem;
}

.list-group-item.disabled,
.list-group-item:disabled {
    color: #d1d2db;
    pointer-events: none;
    background-color: #fff;
}

.list-group-item.active {
    z-index: 2;
    color: #000;
    background-color: #eeeff8;
    border-color: #eeeff8;
}

.list-group-horizontal {
    -ms-flex-direction: row;
    flex-direction: row;
}

.list-group-horizontal .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
}

.list-group-horizontal .list-group-item:first-child {
    border-top-left-radius: 0.65rem;
    border-bottom-left-radius: 0.65rem;
    border-top-right-radius: 0;
}

.list-group-horizontal .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.65rem;
    border-bottom-right-radius: 0.65rem;
    border-bottom-left-radius: 0;
}

.list-group-flush .list-group-item {
    border-right: 0;
    border-left: 0;
    border-radius: 0;
}

.list-group-flush .list-group-item:last-child {
    margin-bottom: -1px;
}

.list-group-flush:first-child .list-group-item:first-child {
    border-top: 0;
}

.list-group-flush:last-child .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom: 0;
}

.list-group-item-primary {
    color: #232868;
    background-color: #c8cbe9;
}

.list-group-item-primary.list-group-item-action:focus,
.list-group-item-primary.list-group-item-action:hover {
    color: #232868;
    background-color: #b6bae2;
}

.list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #232868;
    border-color: #232868;
}

.list-group-item-secondary {
    color: #848691;
    background-color: #fdfdff;
}

.list-group-item-secondary.list-group-item-action:focus,
.list-group-item-secondary.list-group-item-action:hover {
    color: #848691;
    background-color: #e4e4ff;
}

.list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #848691;
    border-color: #848691;
}

.list-group-item-success {
    color: #116d2f;
    background-color: #bff0ca;
}

.list-group-item-success.list-group-item-action:focus,
.list-group-item-success.list-group-item-action:hover {
    color: #116d2f;
    background-color: #aaebb9;
}

.list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #116d2f;
    border-color: #116d2f;
}

.list-group-item-info {
    color: #0c6b7e;
    background-color: #bceff5;
}

.list-group-item-info.list-group-item-action:focus,
.list-group-item-info.list-group-item-action:hover {
    color: #0c6b7e;
    background-color: #a6eaf2;
}

.list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0c6b7e;
    border-color: #0c6b7e;
}

.list-group-item-warning {
    color: #824224;
    background-color: #fcd9c4;
}

.list-group-item-warning.list-group-item-action:focus,
.list-group-item-warning.list-group-item-action:hover {
    color: #824224;
    background-color: #fbc9ac;
}

.list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #824224;
    border-color: #824224;
}

.list-group-item-danger {
    color: #841e30;
    background-color: #fdc6cb;
}

.list-group-item-danger.list-group-item-action:focus,
.list-group-item-danger.list-group-item-action:hover {
    color: #841e30;
    background-color: #fcadb5;
}

.list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #841e30;
    border-color: #841e30;
}

.list-group-item-light {
    color: #828490;
    background-color: #fcfcfe;
}

.list-group-item-light.list-group-item-action:focus,
.list-group-item-light.list-group-item-action:hover {
    color: #828490;
    background-color: #e9e9f8;
}

.list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #828490;
    border-color: #828490;
}

.list-group-item-dark {
    color: #43445b;
    background-color: #dadae2;
}

.list-group-item-dark.list-group-item-action:focus,
.list-group-item-dark.list-group-item-action:hover {
    color: #43445b;
    background-color: #ccccd7;
}

.list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #43445b;
    border-color: #43445b;
}

.list-group-item-first {
    color: #255091;
    background-color: #cae0ff;
}

.list-group-item-first.list-group-item-action:focus,
.list-group-item-first.list-group-item-action:hover {
    color: #255091;
    background-color: #b1d1ff;
}

.list-group-item-first.list-group-item-action.active {
    color: #fff;
    background-color: #255091;
    border-color: #255091;
}

.list-group-item-second {
    color: #070919;
    background-color: #bababf;
}

.list-group-item-second.list-group-item-action:focus,
.list-group-item-second.list-group-item-action:hover {
    color: #070919;
    background-color: #adadb3;
}

.list-group-item-second.list-group-item-action.active {
    color: #fff;
    background-color: #070919;
    border-color: #070919;
}

.close {
    float: right;
    font-size: 1.425rem;
    font-weight: 700;
    line-height: 1;
    color: #070919;
    text-shadow: 0 1px 0 #fff;
    opacity: 0.5;
}

.close:hover {
    color: #070919;
    text-decoration: none;
}

.close:not(:disabled):not(.disabled):focus,
.close:not(:disabled):not(.disabled):hover {
    opacity: 0.75;
}

button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

a.close.disabled {
    pointer-events: none;
}

.toast {
    max-width: 350px;
    overflow: hidden;
    font-size: 0.875rem;
    background-color: hsla(0, 0%, 100%, 0.85);
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 0.313rem 0.8rem rgba(122, 123, 151, 0.5),
        0 0.126rem 0.225rem rgba(122, 123, 151, 0.3);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    opacity: 0;
    border-radius: 0.29rem;
}

.toast:not(:last-child) {
    margin-bottom: 0.85rem;
}

.toast.showing {
    opacity: 1;
}

.toast.show {
    display: block;
    opacity: 1;
}

.toast.hide {
    display: none;
}

.toast-header {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    padding: 0.45rem 0.85rem;
    color: #7a7b97;
    background-color: hsla(0, 0%, 100%, 0.85);
    background-clip: padding-box;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.toast-body {
    padding: 0.85rem;
}

.modal-open {
    overflow: hidden;
}

.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    display: none;
    width: 100%;
    height: 100%;
    /* overflow: hidden; */
    outline: 0;
}

.modal-dialog {
    position: relative;
    width: auto;
    margin: 0.5rem;
    pointer-events: none;
}

.modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translateY(-50px), scale(0.8);
}

.modal.show .modal-dialog {
    transform: none;
}

.modal-dialog-scrollable {
    display: -ms-flexbox;
    display: flex;
    max-height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden;
}

.modal-dialog-scrollable .modal-footer,
.modal-dialog-scrollable .modal-header {
    -ms-flex-negative: 0;
    flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
    overflow-y: auto;
}

.modal-dialog-centered {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    min-height: calc(100% - 1rem);
}

.modal-dialog-centered:before {
    display: block;
    height: calc(100vh - 1rem);
    content: "";
}

.modal-dialog-centered.modal-dialog-scrollable {
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;
    height: 100%;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
    max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable:before {
    content: none;
}

.modal-content {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(7, 9, 25, 0.5);
    border-radius: 0.65rem;
    outline: 0;
}

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #070919;
}

.modal-backdrop.fade {
    opacity: 0;
}

.modal-backdrop.show {
    opacity: 0.8;
}

.modal-header {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: 1px solid #eeeff8;
    border-top-left-radius: 0.65rem;
    border-top-right-radius: 0.65rem;
}

.modal-header .close {
    padding: 1rem;
    margin: -1rem -1rem -1rem auto;
}

.modal-title {
    margin-bottom: 0;
    line-height: 1.5;
}

.modal-body {
    position: relative;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1rem;
}

.modal-footer {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding: 1rem;
    border-top: 1px solid #eeeff8;
    border-bottom-right-radius: 0.65rem;
    border-bottom-left-radius: 0.65rem;
}

.modal-footer> :not(:first-child) {
    margin-left: 0.25rem;
}

.modal-footer> :not(:last-child) {
    margin-right: 0.25rem;
}

.modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll;
}

.tooltip {
    position: absolute;
    z-index: 1070;
    display: block;
    margin: 0;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 0.83125rem;
    word-wrap: break-word;
    opacity: 0;
}

.tooltip.show {
    opacity: 0.85;
}

.tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem;
}

.tooltip .arrow:before {
    position: absolute;
    content: "";
    border-color: transparent;
    border-style: solid;
}

.bs-tooltip-auto[x-placement^="top"],
.bs-tooltip-top {
    padding: 0.4rem 0;
}

.bs-tooltip-auto[x-placement^="top"] .arrow,
.bs-tooltip-top .arrow {
    bottom: 0;
}

.bs-tooltip-auto[x-placement^="top"] .arrow:before,
.bs-tooltip-top .arrow:before {
    top: 0;
    border-width: 0.4rem 0.4rem 0;
    border-top-color: #070919;
}

.bs-tooltip-auto[x-placement^="right"],
.bs-tooltip-right {
    padding: 0 0.4rem;
}

.bs-tooltip-auto[x-placement^="right"] .arrow,
.bs-tooltip-right .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem;
}

.bs-tooltip-auto[x-placement^="right"] .arrow:before,
.bs-tooltip-right .arrow:before {
    right: 0;
    border-width: 0.4rem 0.4rem 0.4rem 0;
    border-right-color: #070919;
}

.bs-tooltip-auto[x-placement^="bottom"],
.bs-tooltip-bottom {
    padding: 0.4rem 0;
}

.bs-tooltip-auto[x-placement^="bottom"] .arrow,
.bs-tooltip-bottom .arrow {
    top: 0;
}

.bs-tooltip-auto[x-placement^="bottom"] .arrow:before,
.bs-tooltip-bottom .arrow:before {
    bottom: 0;
    border-width: 0 0.4rem 0.4rem;
    border-bottom-color: #070919;
}

.bs-tooltip-auto[x-placement^="left"],
.bs-tooltip-left {
    padding: 0 0.4rem;
}

.bs-tooltip-auto[x-placement^="left"] .arrow,
.bs-tooltip-left .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem;
}

.bs-tooltip-auto[x-placement^="left"] .arrow:before,
.bs-tooltip-left .arrow:before {
    left: 0;
    border-width: 0.4rem 0 0.4rem 0.4rem;
    border-left-color: #070919;
}

.tooltip-inner {
    max-width: 200px;
    padding: 0.55rem 1rem;
    color: #fff;
    text-align: center;
    background-color: #070919;
    border-radius: 0.29rem;
}

.popover {
    top: 0;
    left: 0;
    z-index: 1060;
    max-width: 276px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 0.95rem;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(7, 9, 25, 0.2);
    border-radius: 0.29rem;
}

.popover,
.popover .arrow {
    position: absolute;
    display: block;
}

.popover .arrow {
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.75rem;
}

.popover .arrow:after,
.popover .arrow:before {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid;
}

.bs-popover-auto[x-placement^="top"],
.bs-popover-top {
    margin-bottom: 0.5rem;
}

.bs-popover-auto[x-placement^="top"]>.arrow,
.bs-popover-top>.arrow {
    bottom: calc((0.5rem + 1px) * -1);
}

.bs-popover-auto[x-placement^="top"]>.arrow:before,
.bs-popover-top>.arrow:before {
    bottom: 0;
    border-width: 0.5rem 0.5rem 0;
    border-top-color: rgba(7, 9, 25, 0.25);
}

.bs-popover-auto[x-placement^="top"]>.arrow:after,
.bs-popover-top>.arrow:after {
    bottom: 1px;
    border-width: 0.5rem 0.5rem 0;
    border-top-color: #fff;
}

.bs-popover-auto[x-placement^="right"],
.bs-popover-right {
    margin-left: 0.5rem;
}

.bs-popover-auto[x-placement^="right"]>.arrow,
.bs-popover-right>.arrow {
    left: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.75rem 0;
}

.bs-popover-auto[x-placement^="right"]>.arrow:before,
.bs-popover-right>.arrow:before {
    left: 0;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-right-color: rgba(7, 9, 25, 0.25);
}

.bs-popover-auto[x-placement^="right"]>.arrow:after,
.bs-popover-right>.arrow:after {
    left: 1px;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-right-color: #fff;
}

.bs-popover-auto[x-placement^="bottom"],
.bs-popover-bottom {
    margin-top: 0.5rem;
}

.bs-popover-auto[x-placement^="bottom"]>.arrow,
.bs-popover-bottom>.arrow {
    top: calc((0.5rem + 1px) * -1);
}

.bs-popover-auto[x-placement^="bottom"]>.arrow:before,
.bs-popover-bottom>.arrow:before {
    top: 0;
    border-width: 0 0.5rem 0.5rem;
    border-bottom-color: rgba(7, 9, 25, 0.25);
}

.bs-popover-auto[x-placement^="bottom"]>.arrow:after,
.bs-popover-bottom>.arrow:after {
    top: 1px;
    border-width: 0 0.5rem 0.5rem;
    border-bottom-color: #fff;
}

.bs-popover-auto[x-placement^="bottom"] .popover-header:before,
.bs-popover-bottom .popover-header:before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7;
}

.bs-popover-auto[x-placement^="left"],
.bs-popover-left {
    margin-right: 0.5rem;
}

.bs-popover-auto[x-placement^="left"]>.arrow,
.bs-popover-left>.arrow {
    right: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.75rem 0;
}

.bs-popover-auto[x-placement^="left"]>.arrow:before,
.bs-popover-left>.arrow:before {
    right: 0;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    border-left-color: rgba(7, 9, 25, 0.25);
}

.bs-popover-auto[x-placement^="left"]>.arrow:after,
.bs-popover-left>.arrow:after {
    right: 1px;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    border-left-color: #fff;
}

.popover-header {
    padding: 0.85rem 1rem;
    margin-bottom: 0;
    font-size: 0.95rem;
    background-color: #f7f7f7;
    border-bottom: 1px solid #ebebeb;
    border-top-left-radius: calc(0.75rem - 1px);
    border-top-right-radius: calc(0.75rem - 1px);
}

.popover-header:empty {
    display: none;
}

.popover-body {
    padding: 0.85rem 1rem;
    color: #3b3e66;
}

.carousel {
    position: relative;
}

.carousel.pointer-event {
    -ms-touch-action: pan-y;
    touch-action: pan-y;
}

.carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden;
}

.carousel-inner:after {
    display: block;
    clear: both;
    content: "";
}

.carousel-item {
    position: relative;
    display: none;
    float: left;
    width: 100%;
    margin-right: -100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: transform 0.6s ease-in-out;
}



.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
    display: block;
}

.active.carousel-item-right,
.carousel-item-next:not(.carousel-item-left) {
    transform: translateX(100%);
}

.active.carousel-item-left,
.carousel-item-prev:not(.carousel-item-right) {
    transform: translateX(-100%);
}

.carousel-fade .carousel-item {
    opacity: 0;
    transition-property: opacity;
    transform: none;
}

.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right,
.carousel-fade .carousel-item.active {
    z-index: 1;
    opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
    z-index: 0;
    opacity: 0;
    transition: opacity 0s 0.6s;
}

.carousel-control-next,
.carousel-control-prev {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 15%;
    color: #fff;
    text-align: center;
    opacity: 0.5;
    transition: opacity 0.15s ease;
}

.carousel-control-next:focus,
.carousel-control-next:hover,
.carousel-control-prev:focus,
.carousel-control-prev:hover {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9;
}

.carousel-control-prev {
    left: 0;
}

.carousel-control-next {
    right: 0;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: no-repeat 50%/100% 100%;
}

.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3E%3C/svg%3E");
}

.carousel-control-next-icon {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3E%3C/svg%3E");
}

.carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 15;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    padding-left: 0;
    margin-right: 15%;
    margin-left: 15%;
    list-style: none;
}

.carousel-indicators li {
    box-sizing: content-box;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: 0.5;
    transition: opacity 0.6s ease;
}

.carousel-indicators .active {
    opacity: 1;
}

.carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 20px;
    left: 15%;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #fff;
    text-align: center;
}

@keyframes b {
    to {
        transform: rotate(1turn);
    }
}

.spinner-border {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    border: 0.25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    animation: b 0.75s linear infinite;
}

.spinner-border-sm {
    width: 1rem;
    height: 1rem;
    border-width: 0.2em;
}

@keyframes c {
    0% {
        transform: scale(0);
    }

    50% {
        opacity: 1;
    }
}

.spinner-grow {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    background-color: currentColor;
    border-radius: 50%;
    opacity: 0;
    animation: c 0.75s linear infinite;
}

.spinner-grow-sm {
    width: 1rem;
    height: 1rem;
}

.align-baseline {
    vertical-align: baseline !important;
}

.align-top {
    vertical-align: top !important;
}

.align-middle {
    vertical-align: middle !important;
}

.align-bottom {
    vertical-align: bottom !important;
}

.align-text-bottom {
    vertical-align: text-bottom !important;
}

.align-text-top {
    vertical-align: text-top !important;
}

.bg-primary {
    background-color: #3c44b1 !important;
}

a.bg-primary:focus,
a.bg-primary:hover,
button.bg-primary:focus,
button.bg-primary:hover {
    background-color: #2f358b !important;
}

.bg-secondary {
    background-color: #f8f9ff !important;
}

a.bg-secondary:focus,
a.bg-secondary:hover,
button.bg-secondary:focus,
button.bg-secondary:hover {
    background-color: #c5cdff !important;
}

.bg-success {
    background-color: #1bc943 !important;
}

a.bg-success:focus,
a.bg-success:hover,
button.bg-success:focus,
button.bg-success:hover {
    background-color: #159c34 !important;
}

.bg-info {
    background-color: #11c5db !important;
}

a.bg-info:focus,
a.bg-info:hover,
button.bg-info:focus,
button.bg-info:hover {
    background-color: #0d9aac !important;
}

.bg-warning {
    background-color: #f4772e !important;
}

a.bg-warning:focus,
a.bg-warning:hover,
button.bg-warning:focus,
button.bg-warning:hover {
    background-color: #e35b0c !important;
}

.bg-danger {
    background-color: #f83245 !important;
}

a.bg-danger:focus,
a.bg-danger:hover,
button.bg-danger:focus,
button.bg-danger:hover {
    background-color: #ef081e !important;
}

.bg-light {
    background-color: #f4f5fd !important;
}

a.bg-light:focus,
a.bg-light:hover,
button.bg-light:focus,
button.bg-light:hover {
    background-color: #c9cef5 !important;
}

.bg-dark {
    background-color: #7a7b97 !important;
}

a.bg-dark:focus,
a.bg-dark:hover,
button.bg-dark:focus,
button.bg-dark:hover {
    background-color: #61627d !important;
}

.bg-first {
    background-color: #4191ff !important;
}

a.bg-first:focus,
a.bg-first:hover,
button.bg-first:focus,
button.bg-first:hover {
    background-color: #0e73ff !important;
}

.bg-second {
    background-color: #070919 !important;
}

a.bg-second:focus,
a.bg-second:hover,
button.bg-second:focus,
button.bg-second:hover {
    background-color: #000 !important;
}

.bg-white {
    background-color: #fff !important;
}

.bg-transparent {
    background-color: transparent !important;
}

.border {
    border: 1px solid #eeeff8 !important;
}

.border-top {
    border-top: 1px solid #eeeff8 !important;
}

.border-right {
    border-right: 1px solid #eeeff8 !important;
}

.border-bottom {
    border-bottom: 1px solid #eeeff8 !important;
}

.border-left {
    border-left: 1px solid #eeeff8 !important;
}

.border-0 {
    border: 0 !important;
}

.border-top-0 {
    border-top: 0 !important;
}

.border-right-0 {
    border-right: 0 !important;
}

.border-bottom-0 {
    border-bottom: 0 !important;
}

.border-left-0 {
    border-left: 0 !important;
}

.border-primary {
    border-color: #3c44b1 !important;
}

.border-secondary {
    border-color: #f8f9ff !important;
}

.border-success {
    border-color: #1bc943 !important;
}

.border-info {
    border-color: #11c5db !important;
}

.border-warning {
    border-color: #f4772e !important;
}

.border-danger {
    border-color: #f83245 !important;
}

.border-light {
    border-color: #f4f5fd !important;
}

.border-dark {
    border-color: #7a7b97 !important;
}

.border-first {
    border-color: #4191ff !important;
}

.border-second {
    border-color: #070919 !important;
}

.border-white {
    border-color: #fff !important;
}

.rounded-sm {
    border-radius: 0.29rem !important;
}

.rounded {
    border-radius: 0.65rem !important;
}

.rounded-top {
    border-top-left-radius: 0.65rem !important;
}

.rounded-right,
.rounded-top {
    border-top-right-radius: 0.65rem !important;
}

.rounded-bottom,
.rounded-right {
    border-bottom-right-radius: 0.65rem !important;
}

.rounded-bottom,
.rounded-left {
    border-bottom-left-radius: 0.65rem !important;
}

.rounded-left {
    border-top-left-radius: 0.65rem !important;
}

.rounded-lg {
    border-radius: 0.75rem !important;
}

.rounded-circle {
    border-radius: 50% !important;
}

.rounded-pill {
    border-radius: 50rem !important;
}

.rounded-0 {
    border-radius: 0 !important;
}

.clearfix:after {
    display: block;
    clear: both;
    content: "";
}

.d-none {
    display: none !important;
}

.d-inline {
    display: inline !important;
}

.d-inline-block {
    display: inline-block !important;
}

.d-block {
    display: block !important;
}

.d-table {
    display: table !important;
}

.d-table-row {
    display: table-row !important;
}

.d-table-cell {
    display: table-cell !important;
}

.d-flex {
    display: -ms-flexbox !important;
    display: flex !important;
}

.d-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
}

.embed-responsive {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;
}

.embed-responsive:before {
    display: block;
    content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive embed,
.embed-responsive iframe,
.embed-responsive object,
.embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}

.embed-responsive-21by9:before {
    padding-top: 42.85714%;
}

.embed-responsive-16by9:before {
    padding-top: 56.25%;
}

.embed-responsive-4by3:before {
    padding-top: 75%;
}

.embed-responsive-1by1:before {
    padding-top: 100%;
}

.flex-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
}

.flex-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
}

.flex-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
}

.flex-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
}

.flex-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
}

.flex-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
}

.flex-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
}

.flex-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
}

.flex-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
}

.flex-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
}

.flex-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
}

.justify-content-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
}

.justify-content-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
}

.justify-content-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
}

.justify-content-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
}

.justify-content-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
}

.align-items-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
}

.align-items-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
}

.align-items-center {
    -ms-flex-align: center !important;
    align-items: center !important;
}

.align-items-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
}

.align-items-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
}

.align-content-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
}

.align-content-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
}

.align-content-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
}

.align-content-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
}

.align-content-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
}

.align-content-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
}

.align-self-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
}

.align-self-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
}

.align-self-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
}

.align-self-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
}

.align-self-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
}

.align-self-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
}

.float-left {
    float: left !important;
}

.float-right {
    float: right !important;
}

.float-none {
    float: none !important;
}

.overflow-auto {
    overflow: auto !important;
}

.overflow-hidden {
    overflow: hidden !important;
}

.position-static {
    position: static !important;
}

.position-relative {
    position: relative !important;
}

.position-absolute {
    position: absolute !important;
}

.position-fixed {
    position: fixed !important;
}

.position-sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
}

.fixed-top {
    top: 0;
}

.fixed-bottom,
.fixed-top {
    position: fixed;
    right: 0;
    left: 0;
    z-index: 1030;
}

.fixed-bottom {
    bottom: 0;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
    .sticky-top {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 1020;
    }
}

.sr-only {
    white-space: nowrap;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
    white-space: normal;
}

.shadow-sm {
    box-shadow: 0 0.313rem 0.8rem rgba(122, 123, 151, 0.5),
        0 0.126rem 0.225rem rgba(122, 123, 151, 0.3) !important;
}

.shadow {
    box-shadow: 0 0.5rem 1rem rgba(7, 9, 25, 0.55) !important;
}

.shadow-lg {
    box-shadow: 0 1rem 3rem rgba(7, 9, 25, 0.575) !important;
}

.shadow-none {
    box-shadow: none !important;
}

.w-25 {
    width: 25% !important;
}

.w-50 {
    width: 50% !important;
}

.w-75 {
    width: 75% !important;
}

.w-100 {
    width: 100% !important;
}

.w-auto {
    width: auto !important;
}

.h-25 {
    height: 25% !important;
}

.h-50 {
    height: 50% !important;
}

.h-75 {
    height: 75% !important;
}

.h-100 {
    height: 100% !important;
}

.h-auto {
    height: auto !important;
}

.mw-100 {
    max-width: 100% !important;
}

.mh-100 {
    max-height: 100% !important;
}

.min-vw-100 {
    min-width: 100vw !important;
}

.min-vh-100 {
    min-height: 100vh !important;
}

.vw-100 {
    width: 100vw !important;
}

.vh-100 {
    height: 100vh !important;
}

.stretched-link:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    pointer-events: auto;
    content: "";
    background-color: transparent;
}

.m-0 {
    margin: 0 !important;
}

.mt-0,
.my-0 {
    margin-top: 0 !important;
}

.mr-0,
.mx-0 {
    margin-right: 0 !important;
}

.mb-0,
.my-0 {
    margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
    margin-left: 0 !important;
}

.m-1 {
    margin: 0.25rem !important;
}

.mt-1,
.my-1 {
    margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
    margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
    margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
    margin-left: 0.25rem !important;
}

.m-2 {
    margin: 0.5rem !important;
}

.mt-2,
.my-2 {
    margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
    margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
    margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
    margin-left: 0.5rem !important;
}

.m-3 {
    margin: 1rem !important;
}

.mt-3,
.my-3 {
    margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
    margin-right: 1rem !important;
}

.mb-3,
.my-3 {
    margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
    margin-left: 1rem !important;
}

.m-4 {
    margin: 1.5rem !important;
}

.mt-4,
.my-4 {
    margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
    margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
    margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
    margin-left: 1.5rem !important;
}

.m-5 {
    margin: 3rem !important;
}

.mt-5,
.my-5 {
    margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
    margin-right: 3rem !important;
}

.mb-5,
.my-5 {
    margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
    margin-left: 3rem !important;
}

.p-0 {
    padding: 0 !important;
}

.pt-0,
.py-0 {
    padding-top: 0 !important;
}

.pr-0,
.px-0 {
    padding-right: 0 !important;
}

.pb-0,
.py-0 {
    padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
    padding-left: 0 !important;
}

.p-1 {
    padding: 0.25rem !important;
}

.pt-1,
.py-1 {
    padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
    padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
    padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
    padding-left: 0.25rem !important;
}

.p-2 {
    padding: 0.5rem !important;
}

.pt-2,
.py-2 {
    padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
    padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
    padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
    padding-left: 0.5rem !important;
}

.p-3 {
    padding: 1rem !important;
}

.pt-3,
.py-3 {
    padding-top: 1rem !important;
}

.pr-3,
.px-3 {
    padding-right: 1rem !important;
}

.pb-3,
.py-3 {
    padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
    padding-left: 1rem !important;
}

.p-4 {
    padding: 1.5rem !important;
}

.pt-4,
.py-4 {
    padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
    padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
    padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
    padding-left: 1.5rem !important;
}

.p-5 {
    padding: 3rem !important;
}

.pt-5,
.py-5 {
    padding-top: 3rem !important;
}

.pr-5,
.px-5 {
    padding-right: 3rem !important;
}

.pb-5,
.py-5 {
    padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
    padding-left: 3rem !important;
}

.m-n1 {
    margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
    margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
    margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
    margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
    margin-left: -0.25rem !important;
}

.m-n2 {
    margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
    margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
    margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
    margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
    margin-left: -0.5rem !important;
}

.m-n3 {
    margin: -1rem !important;
}

.mt-n3,
.my-n3 {
    margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
    margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
    margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
    margin-left: -1rem !important;
}

.m-n4 {
    margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
    margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
    margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
    margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
    margin-left: -1.5rem !important;
}

.m-n5 {
    margin: -3rem !important;
}

.mt-n5,
.my-n5 {
    margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
    margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
    margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
    margin-left: -3rem !important;
}

.m-auto {
    margin: auto !important;
}

.mt-auto,
.my-auto {
    margin-top: auto !important;
}

.mr-auto,
.mx-auto {
    margin-right: auto !important;
}

.mb-auto,
.my-auto {
    margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
    margin-left: auto !important;
}

.text-justify {
    text-align: justify !important;
}

.text-wrap {
    white-space: normal !important;
}

.text-nowrap {
    white-space: nowrap !important;
}

.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.text-left {
    text-align: left !important;
}

.text-right {
    text-align: right !important;
}

.text-center {
    text-align: center !important;
}

.text-lowercase {
    text-transform: lowercase !important;
}

.text-uppercase {
    text-transform: uppercase !important;
}

.text-capitalize {
    text-transform: capitalize !important;
}

.font-weight-light {
    font-weight: 300 !important;
}

.font-weight-lighter {
    font-weight: lighter !important;
}

.font-weight-normal {
    font-weight: 400 !important;
}

.font-weight-bold {
    font-weight: bold !important;
}

.font-weight-500 {
    font-weight: 500;
}

.font-weight-600 {
    font-weight: 600;
}

.font-weight-400 {
    font-weight: 400;
}

/* impact page */

#impactbold {
    font-size: x-large;
    font-weight: 700;
}

#impactnotbold {
    text-align: center;
    font-size: x-large;
}

/* impact page */
.font-weight-bolder {
    font-weight: bolder !important;
}

.font-italic {
    font-style: italic !important;
}

.text-white {
    color: white !important;
}

.text-black {
    color: black !important;
}

.text-primary {
    color: #3c44b1 !important;
}

a.text-primary:focus,
a.text-primary:hover {
    color: #292e78 !important;
}

.text-secondary {
    color: #f8f9ff !important;
}

a.text-secondary:focus,
a.text-secondary:hover {
    color: #acb7ff !important;
}

.text-success {
    color: #1bc943 !important;
}

a.text-success:focus,
a.text-success:hover {
    color: #12862d !important;
}

.text-info {
    color: #11c5db !important;
}

a.text-info:focus,
a.text-info:hover {
    color: #0b8594 !important;
}

.text-warning {
    color: #f4772e !important;
}

a.text-warning:focus,
a.text-warning:hover {
    color: #cb520b !important;
}

.text-danger {
    color: #f83245 !important;
}

a.text-danger:focus,
a.text-danger:hover {
    color: #d6071b !important;
}

.text-light {
    color: #f4f5fd !important;
}

a.text-light:focus,
a.text-light:hover {
    color: #b3baf1 !important;
}

.text-dark {
    color: #7a7b97 !important;
}

a.text-dark:focus,
a.text-dark:hover {
    color: #56576e !important;
}

.text-first {
    color: #4191ff !important;
}

a.text-first:focus,
a.text-first:hover {
    color: #0067f4 !important;
}

.text-second {
    color: #070919 !important;
}

a.text-second:focus,
a.text-second:hover {
    color: #000 !important;
}

.text-body {
    color: #3b3e66 !important;
}

.text-muted {
    color: #d1d2db !important;
}

.text-black-50 {
    color: rgba(7, 9, 25, 0.5) !important;
}

.text-white-50 {
    color: hsla(0, 0%, 100%, 0.5) !important;
}

.text-hide {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
}

.text-decoration-none {
    text-decoration: none !important;
}

.text-break {
    word-break: break-word !important;
    overflow-wrap: break-word !important;
}

.text-reset {
    color: inherit !important;
}

.visible {
    visibility: visible !important;
}

.invisible {
    visibility: hidden !important;
}

.close:active,
.close:focus,
a,
button {
    outline: none;
}

.br-tl {
    border-top-left-radius: 0.29rem !important;
}

.br-tr {
    border-top-right-radius: 0.29rem !important;
}

.br-bl {
    border-bottom-left-radius: 0.29rem !important;
}

.br-br {
    border-bottom-right-radius: 0.29rem !important;
}

.opacity-1 {
    opacity: 0.1 !important;
}

.opacity-2 {
    opacity: 0.2 !important;
}

.opacity-3 {
    opacity: 0.3 !important;
}

.opacity-4 {
    opacity: 0.4 !important;
}

.opacity-5 {
    opacity: 0.5 !important;
}

.opacity-6 {
    opacity: 0.6 !important;
}

.opacity-7 {
    opacity: 0.7 !important;
}

.opacity-8 {
    opacity: 0.8 !important;
}

.opacity-9 {
    opacity: 0.9 !important;
}

.d-40 {
    height: 40px !important;
    line-height: 40px !important;
    width: 40px !important;
}

.d-50 {
    height: 50px !important;
    line-height: 50px !important;
    width: 50px !important;
}

.d-60 {
    height: 60px !important;
    line-height: 60px !important;
    width: 60px !important;
}

.d-70 {
    height: 70px !important;
    line-height: 70px !important;
    width: 70px !important;
}

.d-80 {
    height: 80px !important;
    line-height: 80px !important;
    width: 80px !important;
}

.d-90 {
    height: 90px !important;
    line-height: 90px !important;
    width: 90px !important;
}

.d-100 {
    height: 100px !important;
    line-height: 100px !important;
    width: 100px !important;
}

.d-110 {
    height: 110px !important;
    line-height: 110px !important;
    width: 110px !important;
}

.d-120 {
    height: 120px !important;
    line-height: 120px !important;
    width: 120px !important;
}

.d-130 {
    height: 130px !important;
    line-height: 130px !important;
    width: 130px !important;
}

.d-140 {
    height: 140px !important;
    line-height: 140px !important;
    width: 140px !important;
}

.line-height-sm {
    line-height: 1.3;
}

.line-height-md {
    line-height: 2;
}

.h-auto {
    height: auto;
}

.h-100 {
    height: 100%;
}

.w-auto {
    width: auto;
}

.w-43 {
    width: 43%;
}

.divider {
    background: #eeeff8;
    height: 1px;
    overflow: hidden;
}

.border-2 {
    border-width: 2px !important;
    border-style: solid;
}

.border-3 {
    border-width: 3px !important;
    border-style: solid;
}

.font-size-xs {
    font-size: 0.79167rem;
}

.font-size-sm {
    font-size: 0.83125rem;
}

.font-size-md {
    font-size: 0.95rem;
}

.font-size-lg {
    font-size: 1.1875rem;
}

.font-size-xl {
    font-size: 1.425rem;
}

.font-size-xxl {
    font-size: 1.6625rem;
}

.font-size-32 {
    font-size: 32px;
}

.bg-composed-wrapper--bg,
.bg-composed-wrapper--image {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 3;
    opacity: 0.15;
}

.bg-composed-wrapper {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
}

.bg-composed-wrapper,
.bg-composed-wrapper--image {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
}

.bg-composed-wrapper--image {
    filter: grayscale(80%);
    background-size: cover;
}

.bg-composed-wrapper--content {
    position: relative;
    z-index: 5;
    width: 100%;
}

.bg-composed-wrapper--bg {
    z-index: 5;
}

.bg-composed-filter-rm {
    filter: grayscale(0);
}

.animated.standby {
    animation: none !important;
    visibility: hidden;
}

.shadow-xxl {
    box-shadow: 0 0.18rem 0.3rem rgba(166, 166, 185, 0.5),
        0 0.326rem 3rem rgba(122, 123, 151, 0.3) !important;
}

.shadow-sm-dark {
    box-shadow: 0 0.313rem 0.8rem rgba(7, 9, 25, 0.3),
        0 0.126rem 0.225rem rgba(7, 9, 25, 0.1) !important;
}

.bg-white-50 {
    background-color: hsla(0, 0%, 100%, 0.2);
}

.text-black {
    color: #070919;
}

.img-fit-container {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
}

html {
    scroll-behavior: smooth;
}

.wow {
    visibility: hidden;
}

.demo-wrapper .example-box>.badge,
.demo-wrapper .example-box>.btn,
.demo-wrapper .example-box>.btn-group {
    margin: 0 0.66667rem 0.66667rem 0;
}

.demo-img-wrapper {
    background: #f8f9ff;
    color: #d1d2db;
    font-size: 4rem;
    padding: 3rem;
    text-align: center;
    height: 100%;
    position: relative;
}

.demo-img-wrapper,
.demo-img-wrapper:after {
    border-radius: 0.29rem;
    transition: all 0.2s ease-in-out;
}

.demo-img-wrapper:after {
    content: "This demo placeholder should be replaced with either images or components.";
    position: absolute;
    left: 50%;
    width: 300px;
    margin-left: -150px;
    font-size: 0.95rem;
    background: #070919;
    visibility: hidden;
    opacity: 0;
    padding: 1rem;
    color: #fff;
    line-height: 1.6rem;
}

.demo-img-wrapper:hover {
    border-color: #3c44b1;
}

.demo-img-wrapper:hover:after {
    visibility: visible;
    opacity: 0.9;
}

.demo-img-wrapper i {
    margin: auto;
}

.demo-img-wrapper-discrete {
    background: hsla(0, 0%, 100%, 0.1);
    border: 0 !important;
    font-size: 3rem;
    color: hsla(0, 0%, 100%, 0.6);
}

.demo-img-wrapper-discrete:after {
    font-size: 0.86 !important;
}

.demo-img-wrapper-discrete:hover {
    background: hsla(0, 0%, 100%, 0.15);
}

.demo-img-wrapper-dark {
    background: #a6a6b9;
    border-color: #070919;
}

.demo-img-wrapper-dark:after {
    background: #3b3e66;
    color: #e6e7f1;
}

.demo-img-wrapper-dark:hover {
    border-color: #eeeff8;
}

.heading-1 {
    font-size: 4rem;
    color: #d1d2db;
}

.heading-1,
.heading-2 {
    font-weight: bold;
    margin: 0;
}

.heading-2 {
    font-size: 2rem;
    position: relative;
    padding-left: 2rem;
}

.heading-2:before {
    content: "::";
    position: absolute;
    left: 0;
    top: -2px;
    color: #3c44b1;
}

.heading-3 {
    text-transform: uppercase;
    font-size: 0.95rem;
    font-weight: bold;
    margin: 0 0 1rem;
    padding: 0;
    color: #7a7b97;
}

.heading-1 .sticky-btn {
    opacity: 0;
    transition: opacity 0.2s;
    transition-delay: 0.2s;
}

.heading-1.stick-parent {
    opacity: 0;
    transition: opacity 0.2s, transform 0.2s;
    position: absolute;
    visibility: hidden;
    background: #fff;
    z-index: 7;
    padding: 0 1rem !important;
    font-size: 1.6625rem;
    box-shadow: 0 1px 15px hsla(240, 2%, 52%, 0.3);
    height: 0;
    text-indent: -999rem;
    width: 100%;
    transform: translateY(-100%);
}

.heading-1.stick-parent.is_stuck {
    opacity: 1;
    position: fixed;
    visibility: visible;
    height: 68px;
    text-indent: 0;
    transform: translateY(0);
}

.heading-1.stick-parent.is_stuck .sticky-btn {
    opacity: 1;
}

.demo-stick-nav {
    visibility: hidden;
    opacity: 0;
    padding: 0 1rem;
    transition: all 0.2s ease-in-out;
    z-index: 22;
    height: 68px;
}

.demo-stick-nav.is_stuck {
    visibility: visible;
    opacity: 1;
}

.bg-neutral-primary {
    background-color: #eceef7 !important;
}

a.bg-neutral-primary:focus,
a.bg-neutral-primary:hover,
button.bg-neutral-primary:focus,
button.bg-neutral-primary:hover {
    background-color: #c8cde8 !important;
}

.bg-neutral-secondary {
    background-color: #fff !important;
}

a.bg-neutral-secondary:focus,
a.bg-neutral-secondary:hover,
button.bg-neutral-secondary:focus,
button.bg-neutral-secondary:hover {
    background-color: #e6e6e6 !important;
}

.bg-neutral-success {
    background-color: #e5f9ed !important;
}

a.bg-neutral-success:focus,
a.bg-neutral-success:hover,
button.bg-neutral-success:focus,
button.bg-neutral-success:hover {
    background-color: #bbf0d0 !important;
}

.bg-neutral-info {
    background-color: #e8fbfd !important;
}

a.bg-neutral-info:focus,
a.bg-neutral-info:hover,
button.bg-neutral-info:focus,
button.bg-neutral-info:hover {
    background-color: #b9f4f8 !important;
}

.bg-neutral-warning {
    background-color: #fffbf2 !important;
}

a.bg-neutral-warning:focus,
a.bg-neutral-warning:hover,
button.bg-neutral-warning:focus,
button.bg-neutral-warning:hover {
    background-color: #ffedbf !important;
}

.bg-neutral-danger {
    background-color: #fff5f6 !important;
}

a.bg-neutral-danger:focus,
a.bg-neutral-danger:hover,
button.bg-neutral-danger:focus,
button.bg-neutral-danger:hover {
    background-color: #ffc2c9 !important;
}

.bg-neutral-dark {
    background-color: #f5f5f7 !important;
}

a.bg-neutral-dark:focus,
a.bg-neutral-dark:hover,
button.bg-neutral-dark:focus,
button.bg-neutral-dark:hover {
    background-color: #d9d9e1 !important;
}

.bg-neutral-first {
    background-color: #eaf6ff !important;
}

a.bg-neutral-first:focus,
a.bg-neutral-first:hover,
button.bg-neutral-first:focus,
button.bg-neutral-first:hover {
    background-color: #b8e0fe !important;
}

.bg-neutral-second {
    background-color: #e1e2e7 !important;
}

a.bg-neutral-second:focus,
a.bg-neutral-second:hover,
button.bg-neutral-second:focus,
button.bg-neutral-second:hover {
    background-color: #c5c7d1 !important;
}

.bg-brand-facebook {
    background-color: #3b5999 !important;
}

a.bg-brand-facebook:focus,
a.bg-brand-facebook:hover,
button.bg-brand-facebook:focus,
button.bg-brand-facebook:hover {
    background-color: #2d4474 !important;
}

.bg-brand-twitter {
    background-color: #1da1f2 !important;
}

a.bg-brand-twitter:focus,
a.bg-brand-twitter:hover,
button.bg-brand-twitter:focus,
button.bg-brand-twitter:hover {
    background-color: #0c85d0 !important;
}

.bg-brand-google-plus {
    background-color: #dd4b39 !important;
}

a.bg-brand-google-plus:focus,
a.bg-brand-google-plus:hover,
button.bg-brand-google-plus:focus,
button.bg-brand-google-plus:hover {
    background-color: #c23321 !important;
}

.bg-brand-instagram {
    background-color: #e4405f !important;
}

a.bg-brand-instagram:focus,
a.bg-brand-instagram:hover,
button.bg-brand-instagram:focus,
button.bg-brand-instagram:hover {
    background-color: #d31e40 !important;
}

.bg-brand-pinterest {
    background-color: #bd081c !important;
}

a.bg-brand-pinterest:focus,
a.bg-brand-pinterest:hover,
button.bg-brand-pinterest:focus,
button.bg-brand-pinterest:hover {
    background-color: #8c0615 !important;
}

.bg-brand-youtube {
    background-color: #cd201f !important;
}

a.bg-brand-youtube:focus,
a.bg-brand-youtube:hover,
button.bg-brand-youtube:focus,
button.bg-brand-youtube:hover {
    background-color: #a11918 !important;
}

.bg-brand-slack {
    background-color: #3aaf85 !important;
}

a.bg-brand-slack:focus,
a.bg-brand-slack:hover,
button.bg-brand-slack:focus,
button.bg-brand-slack:hover {
    background-color: #2d8968 !important;
}

.bg-brand-dribbble {
    background-color: #ea4c89 !important;
}

a.bg-brand-dribbble:focus,
a.bg-brand-dribbble:hover,
button.bg-brand-dribbble:focus,
button.bg-brand-dribbble:hover {
    background-color: #e51e6b !important;
}

.bg-brand-github {
    background-color: #222 !important;
}

a.bg-brand-github:focus,
a.bg-brand-github:hover,
button.bg-brand-github:focus,
button.bg-brand-github:hover {
    background-color: #090909 !important;
}

.bg-warm-flame {
    background-image: linear-gradient(45deg,
            #ff9a9e,
            #fad0c4 99%,
            #fad0c4) !important;
}

.bg-night-fade {
    background-image: linear-gradient(0deg, #a18cd1 0, #fbc2eb) !important;
}

.bg-sunny-morning {
    background-image: linear-gradient(120deg, #f6d365, #fda085) !important;
}

.bg-tempting-azure {
    background-image: linear-gradient(120deg, #84fab0, #8fd3f4) !important;
}

.bg-amy-crisp {
    background-image: linear-gradient(120deg, #a6c0fe, #f68084) !important;
}

.bg-heavy-rain {
    background-image: linear-gradient(0deg, #cfd9df 0, #e2ebf0) !important;
}

.bg-mean-fruit {
    background-image: linear-gradient(120deg, #fccb90, #d57eeb) !important;
}

.bg-malibu-beach {
    background-image: linear-gradient(90deg, #4facfe 0, #00f2fe) !important;
}

.bg-deep-blue {
    background-image: linear-gradient(120deg, #e0c3fc, #8ec5fc) !important;
}

.bg-ripe-malin {
    background-image: linear-gradient(120deg, #f093fb, #f5576c) !important;
}

.bg-arielle-smile {
    background-image: radial-gradient(circle 248px at center,
            #16d9e3 0,
            #30c7ec 47%,
            #46aef7 100%) !important;
}

.bg-plum-plate {
    background-image: linear-gradient(135deg, #667eea, #764ba2) !important;
}

.bg-happy-fisher {
    background-image: linear-gradient(120deg, #89f7fe, #66a6ff) !important;
}

.bg-happy-itmeo {
    background-image: linear-gradient(180deg, #2af598, #009efd) !important;
}

.bg-mixed-hopes {
    background-image: linear-gradient(0deg, #c471f5 0, #fa71cd) !important;
}

.bg-strong-bliss {
    background-image: linear-gradient(90deg,
            #f78ca0 0,
            #f9748f 19%,
            #fd868c 60%,
            #fe9a8b) !important;
}

.bg-grow-early {
    background-image: linear-gradient(0deg, #0ba360 0, #3cba92) !important;
}

.bg-love-kiss {
    background-image: linear-gradient(0deg, #ff0844 0, #ffb199) !important;
}

.bg-premium-dark {
    background-image: linear-gradient(90deg, #434343 0, #000) !important;
}

.bg-happy-green {
    background-image: linear-gradient(180deg, #00b09b, #96c93d) !important;
}

.bg-vicious-stance {
    background-image: linear-gradient(60deg, #29323c, #485563) !important;
}

.bg-midnight-bloom {
    background-image: linear-gradient(-20deg, #2b5876, #4e4376) !important;
}

.bg-night-sky {
    background-image: linear-gradient(0deg,
            #1e3c72 0,
            #1e3c72 1%,
            #2a5298) !important;
}

.bg-slick-carbon {
    background-image: linear-gradient(180deg,
            #323232 0,
            #3f3f3f 40%,
            #1c1c1c 150%),
        linear-gradient(0deg, hsla(0, 0%, 100%, 0.4) 0, rgba(0, 0, 0, 0.25) 200%) !important;
    background-blend-mode: multiply;
}

.bg-royal {
    background-image: linear-gradient(90deg, #141e30, #243b55) !important;
}

.bg-asteroid {
    background-image: linear-gradient(90deg,
            #0f2027,
            #203a43,
            #2c5364) !important;
}

.color-swatch {
    position: relative;
    margin: 0 0 3rem;
}

.color-swatch--bg {
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
    height: 84px;
    width: 100%;
    margin: 0 auto;
}

.color-swatch--title {
    background: #fff;
    border: 1px solid #e6e7f1;
    border-bottom-right-radius: 1.5rem;
    border-bottom-left-radius: 0.75rem;
    padding: 0.66667rem 1rem;
    text-align: center;
    color: #a6a6b9;
}

.icon-demo-box {
    padding: 5rem 0 0;
    text-align: center;
}

.icon-demo-box .card {
    margin: 0 1rem 2rem;
    display: inline-block;
    padding: 1rem 0 !important;
    width: 120px;
    text-align: center;
    transition: all 0.2s ease-in-out;
}

.icon-demo-box .card i,
.icon-demo-box .card span {
    font-size: 2.5rem;
}

.icon-demo-box .card:hover {
    transform: scale(1.8);
    z-index: 9;
    border-radius: 30px;
    box-shadow: 0 5rem 14rem 0 hsla(0, 0%, 100%, 0.3),
        0 0.8rem 2.3rem rgba(0, 0, 0, 0.6), 0 0.2rem 0.3rem rgba(0, 0, 0, 0.45);
}

.heading-2-demo {
    padding-left: 0;
    font-size: 2.5rem;
    text-align: center;
}

.heading-2-demo:before {
    display: none;
}

.heading-2-demo+p {
    margin: 0;
    color: #a6a6b9;
    font-size: 1.4rem;
    text-align: center;
}

.badge {
    text-transform: uppercase;
    height: 20px;
    line-height: 21px;
}

.btn {
    transition: all 0.2s ease-out;
    border-radius: 0.2rem;
}

.btn:not(:disabled):hover {
    transform: translateY(-2px);
}

.btn .btn-wrapper--label:not(:first-child) {
    margin-left: 0.5rem;
}

.btn .btn-wrapper--label {
    display: inline-block;
}

.btn .btn-wrapper--label+.btn-wrapper--icon {
    margin-left: 0.5rem;
}

.btn-pill {
    border-radius: 1.5rem;
}

.btn-icon-transition {
    transition: all 0.2s ease-in-out;
}

.btn:hover .btn-icon-transition {
    transform: scale(1.1);
}

.btn-primary {
    box-shadow: 0 0.25rem 0.55rem rgba(60, 68, 177, 0.35);
}

.btn-primary:not(:disabled):active,
.btn-primary:not(:disabled):focus,
.btn-primary:not(:disabled):hover {
    box-shadow: 0 0.22rem 0.525rem rgba(60, 68, 177, 0.4),
        0 0.0625rem 0.385rem rgba(60, 68, 177, 0.54);
}

.btn-secondary {
    box-shadow: 0 0.25rem 0.55rem rgba(248, 249, 255, 0.35);
}

.btn-secondary:not(:disabled):active,
.btn-secondary:not(:disabled):focus,
.btn-secondary:not(:disabled):hover {
    box-shadow: 0 0.22rem 0.525rem rgba(248, 249, 255, 0.4),
        0 0.0625rem 0.385rem rgba(248, 249, 255, 0.54);
}

.btn-success {
    box-shadow: 0 0.25rem 0.55rem rgba(27, 201, 67, 0.35);
}

.btn-success:not(:disabled):active,
.btn-success:not(:disabled):focus,
.btn-success:not(:disabled):hover {
    box-shadow: 0 0.22rem 0.525rem rgba(27, 201, 67, 0.4),
        0 0.0625rem 0.385rem rgba(27, 201, 67, 0.54);
}

.btn-info {
    box-shadow: 0 0.25rem 0.55rem rgba(17, 197, 219, 0.35);
}

.btn-info:not(:disabled):active,
.btn-info:not(:disabled):focus,
.btn-info:not(:disabled):hover {
    box-shadow: 0 0.22rem 0.525rem rgba(17, 197, 219, 0.4),
        0 0.0625rem 0.385rem rgba(17, 197, 219, 0.54);
}

.btn-warning {
    box-shadow: 0 0.25rem 0.55rem rgba(244, 119, 46, 0.35);
}

.btn-warning:not(:disabled):active,
.btn-warning:not(:disabled):focus,
.btn-warning:not(:disabled):hover {
    box-shadow: 0 0.22rem 0.525rem rgba(244, 119, 46, 0.4),
        0 0.0625rem 0.385rem rgba(244, 119, 46, 0.54);
}

.btn-danger {
    box-shadow: 0 0.25rem 0.55rem rgba(248, 50, 69, 0.35);
}

.btn-danger:not(:disabled):active,
.btn-danger:not(:disabled):focus,
.btn-danger:not(:disabled):hover {
    box-shadow: 0 0.22rem 0.525rem rgba(248, 50, 69, 0.4),
        0 0.0625rem 0.385rem rgba(248, 50, 69, 0.54);
}

.btn-light {
    box-shadow: 0 0.25rem 0.55rem rgba(244, 245, 253, 0.35);
}

.btn-light:not(:disabled):active,
.btn-light:not(:disabled):focus,
.btn-light:not(:disabled):hover {
    box-shadow: 0 0.22rem 0.525rem rgba(244, 245, 253, 0.4),
        0 0.0625rem 0.385rem rgba(244, 245, 253, 0.54);
}

.btn-dark {
    box-shadow: 0 0.25rem 0.55rem rgba(122, 123, 151, 0.35);
}

.btn-dark:not(:disabled):active,
.btn-dark:not(:disabled):focus,
.btn-dark:not(:disabled):hover {
    box-shadow: 0 0.22rem 0.525rem rgba(122, 123, 151, 0.4),
        0 0.0625rem 0.385rem rgba(122, 123, 151, 0.54);
}

.btn-first {
    box-shadow: 0 0.25rem 0.55rem rgba(65, 145, 255, 0.35);
}

.btn-first:not(:disabled):active,
.btn-first:not(:disabled):focus,
.btn-first:not(:disabled):hover {
    box-shadow: 0 0.22rem 0.525rem rgba(65, 145, 255, 0.4),
        0 0.0625rem 0.385rem rgba(65, 145, 255, 0.54);
}

.btn-second {
    box-shadow: 0 0.25rem 0.55rem rgba(7, 9, 25, 0.35);
}

.btn-second:not(:disabled):active,
.btn-second:not(:disabled):focus,
.btn-second:not(:disabled):hover {
    box-shadow: 0 0.22rem 0.525rem rgba(7, 9, 25, 0.4),
        0 0.0625rem 0.385rem rgba(7, 9, 25, 0.54);
}

.btn-facebook {
    box-shadow: 0 0.25rem 0.55rem rgba(59, 89, 153, 0.35);
}

.btn-facebook,
.btn-facebook:hover {
    color: #fff;
    background-color: #3b5999;
    border-color: #3b5999;
}

.btn-facebook.focus,
.btn-facebook:focus {
    box-shadow: 0 0 0 0 transparent;
}

.btn-facebook.disabled,
.btn-facebook:disabled {
    color: #fff;
    background-color: #3b5999;
    border-color: #3b5999;
}

.btn-facebook:not(:disabled):not(.disabled).active,
.btn-facebook:not(:disabled):not(.disabled):active,
.show>.btn-facebook.dropdown-toggle {
    color: #fff;
    background-color: #2d4474;
    border-color: #293e6b;
}

.btn-facebook:not(:disabled):not(.disabled).active:focus,
.btn-facebook:not(:disabled):not(.disabled):active:focus,
.show>.btn-facebook.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 transparent;
}

.btn-facebook:not(:disabled):active,
.btn-facebook:not(:disabled):focus,
.btn-facebook:not(:disabled):hover {
    box-shadow: 0 0.22rem 0.525rem rgba(59, 89, 153, 0.4),
        0 0.0625rem 0.385rem rgba(59, 89, 153, 0.54);
}

.btn-twitter {
    box-shadow: 0 0.25rem 0.55rem rgba(29, 161, 242, 0.35);
}

.btn-twitter,
.btn-twitter:hover {
    color: #fff;
    background-color: #1da1f2;
    border-color: #1da1f2;
}

.btn-twitter.focus,
.btn-twitter:focus {
    box-shadow: 0 0 0 0 transparent;
}

.btn-twitter.disabled,
.btn-twitter:disabled {
    color: #fff;
    background-color: #1da1f2;
    border-color: #1da1f2;
}

.btn-twitter:not(:disabled):not(.disabled).active,
.btn-twitter:not(:disabled):not(.disabled):active,
.show>.btn-twitter.dropdown-toggle {
    color: #fff;
    background-color: #0c85d0;
    border-color: #0b7ec4;
}

.btn-twitter:not(:disabled):not(.disabled).active:focus,
.btn-twitter:not(:disabled):not(.disabled):active:focus,
.show>.btn-twitter.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 transparent;
}

.btn-twitter:not(:disabled):active,
.btn-twitter:not(:disabled):focus,
.btn-twitter:not(:disabled):hover {
    box-shadow: 0 0.22rem 0.525rem rgba(29, 161, 242, 0.4),
        0 0.0625rem 0.385rem rgba(29, 161, 242, 0.54);
}

.btn-google-plus {
    box-shadow: 0 0.25rem 0.55rem rgba(221, 75, 57, 0.35);
}

.btn-google-plus,
.btn-google-plus:hover {
    color: #fff;
    background-color: #dd4b39;
    border-color: #dd4b39;
}

.btn-google-plus.focus,
.btn-google-plus:focus {
    box-shadow: 0 0 0 0 transparent;
}

.btn-google-plus.disabled,
.btn-google-plus:disabled {
    color: #fff;
    background-color: #dd4b39;
    border-color: #dd4b39;
}

.btn-google-plus:not(:disabled):not(.disabled).active,
.btn-google-plus:not(:disabled):not(.disabled):active,
.show>.btn-google-plus.dropdown-toggle {
    color: #fff;
    background-color: #c23321;
    border-color: #b7301f;
}

.btn-google-plus:not(:disabled):not(.disabled).active:focus,
.btn-google-plus:not(:disabled):not(.disabled):active:focus,
.show>.btn-google-plus.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 transparent;
}

.btn-google-plus:not(:disabled):active,
.btn-google-plus:not(:disabled):focus,
.btn-google-plus:not(:disabled):hover {
    box-shadow: 0 0.22rem 0.525rem rgba(221, 75, 57, 0.4),
        0 0.0625rem 0.385rem rgba(221, 75, 57, 0.54);
}

.btn-instagram {
    box-shadow: 0 0.25rem 0.55rem rgba(228, 64, 95, 0.35);
}

.btn-instagram,
.btn-instagram:hover {
    color: #fff;
    background-color: #e4405f;
    border-color: #e4405f;
}

.btn-instagram.focus,
.btn-instagram:focus {
    box-shadow: 0 0 0 0 transparent;
}

.btn-instagram.disabled,
.btn-instagram:disabled {
    color: #fff;
    background-color: #e4405f;
    border-color: #e4405f;
}

.btn-instagram:not(:disabled):not(.disabled).active,
.btn-instagram:not(:disabled):not(.disabled):active,
.show>.btn-instagram.dropdown-toggle {
    color: #fff;
    background-color: #d31e40;
    border-color: #c81c3d;
}

.btn-instagram:not(:disabled):not(.disabled).active:focus,
.btn-instagram:not(:disabled):not(.disabled):active:focus,
.show>.btn-instagram.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 transparent;
}

.btn-instagram:not(:disabled):active,
.btn-instagram:not(:disabled):focus,
.btn-instagram:not(:disabled):hover {
    box-shadow: 0 0.22rem 0.525rem rgba(228, 64, 95, 0.4),
        0 0.0625rem 0.385rem rgba(228, 64, 95, 0.54);
}

.btn-pinterest {
    box-shadow: 0 0.25rem 0.55rem rgba(189, 8, 28, 0.35);
}

.btn-pinterest,
.btn-pinterest:hover {
    color: #fff;
    background-color: #bd081c;
    border-color: #bd081c;
}

.btn-pinterest.focus,
.btn-pinterest:focus {
    box-shadow: 0 0 0 0 transparent;
}

.btn-pinterest.disabled,
.btn-pinterest:disabled {
    color: #fff;
    background-color: #bd081c;
    border-color: #bd081c;
}

.btn-pinterest:not(:disabled):not(.disabled).active,
.btn-pinterest:not(:disabled):not(.disabled):active,
.show>.btn-pinterest.dropdown-toggle {
    color: #fff;
    background-color: #8c0615;
    border-color: #800513;
}

.btn-pinterest:not(:disabled):not(.disabled).active:focus,
.btn-pinterest:not(:disabled):not(.disabled):active:focus,
.show>.btn-pinterest.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 transparent;
}

.btn-pinterest:not(:disabled):active,
.btn-pinterest:not(:disabled):focus,
.btn-pinterest:not(:disabled):hover {
    box-shadow: 0 0.22rem 0.525rem rgba(189, 8, 28, 0.4),
        0 0.0625rem 0.385rem rgba(189, 8, 28, 0.54);
}

.btn-youtube {
    box-shadow: 0 0.25rem 0.55rem rgba(205, 32, 31, 0.35);
}

.btn-youtube,
.btn-youtube:hover {
    color: #fff;
    background-color: #cd201f;
    border-color: #cd201f;
}

.btn-youtube.focus,
.btn-youtube:focus {
    box-shadow: 0 0 0 0 transparent;
}

.btn-youtube.disabled,
.btn-youtube:disabled {
    color: #fff;
    background-color: #cd201f;
    border-color: #cd201f;
}

.btn-youtube:not(:disabled):not(.disabled).active,
.btn-youtube:not(:disabled):not(.disabled):active,
.show>.btn-youtube.dropdown-toggle {
    color: #fff;
    background-color: #a11918;
    border-color: #961717;
}

.btn-youtube:not(:disabled):not(.disabled).active:focus,
.btn-youtube:not(:disabled):not(.disabled):active:focus,
.show>.btn-youtube.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 transparent;
}

.btn-youtube:not(:disabled):active,
.btn-youtube:not(:disabled):focus,
.btn-youtube:not(:disabled):hover {
    box-shadow: 0 0.22rem 0.525rem rgba(205, 32, 31, 0.4),
        0 0.0625rem 0.385rem rgba(205, 32, 31, 0.54);
}

.btn-slack {
    box-shadow: 0 0.25rem 0.55rem rgba(58, 175, 133, 0.35);
}

.btn-slack,
.btn-slack:hover {
    color: #fff;
    background-color: #3aaf85;
    border-color: #3aaf85;
}

.btn-slack.focus,
.btn-slack:focus {
    box-shadow: 0 0 0 0 transparent;
}

.btn-slack.disabled,
.btn-slack:disabled {
    color: #fff;
    background-color: #3aaf85;
    border-color: #3aaf85;
}

.btn-slack:not(:disabled):not(.disabled).active,
.btn-slack:not(:disabled):not(.disabled):active,
.show>.btn-slack.dropdown-toggle {
    color: #fff;
    background-color: #2d8968;
    border-color: #2a7f61;
}

.btn-slack:not(:disabled):not(.disabled).active:focus,
.btn-slack:not(:disabled):not(.disabled):active:focus,
.show>.btn-slack.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 transparent;
}

.btn-slack:not(:disabled):active,
.btn-slack:not(:disabled):focus,
.btn-slack:not(:disabled):hover {
    box-shadow: 0 0.22rem 0.525rem rgba(58, 175, 133, 0.4),
        0 0.0625rem 0.385rem rgba(58, 175, 133, 0.54);
}

.btn-dribbble {
    box-shadow: 0 0.25rem 0.55rem rgba(234, 76, 137, 0.35);
}

.btn-dribbble,
.btn-dribbble:hover {
    color: #fff;
    background-color: #ea4c89;
    border-color: #ea4c89;
}

.btn-dribbble.focus,
.btn-dribbble:focus {
    box-shadow: 0 0 0 0 transparent;
}

.btn-dribbble.disabled,
.btn-dribbble:disabled {
    color: #fff;
    background-color: #ea4c89;
    border-color: #ea4c89;
}

.btn-dribbble:not(:disabled):not(.disabled).active,
.btn-dribbble:not(:disabled):not(.disabled):active,
.show>.btn-dribbble.dropdown-toggle {
    color: #fff;
    background-color: #e51e6b;
    border-color: #dc1a65;
}

.btn-dribbble:not(:disabled):not(.disabled).active:focus,
.btn-dribbble:not(:disabled):not(.disabled):active:focus,
.show>.btn-dribbble.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 transparent;
}

.btn-dribbble:not(:disabled):active,
.btn-dribbble:not(:disabled):focus,
.btn-dribbble:not(:disabled):hover {
    box-shadow: 0 0.22rem 0.525rem rgba(234, 76, 137, 0.4),
        0 0.0625rem 0.385rem rgba(234, 76, 137, 0.54);
}

.btn-github {
    box-shadow: 0 0.25rem 0.55rem rgba(34, 34, 34, 0.35);
}

.btn-github,
.btn-github:hover {
    color: #fff;
    background-color: #222;
    border-color: #222;
}

.btn-github.focus,
.btn-github:focus {
    box-shadow: 0 0 0 0 transparent;
}

.btn-github.disabled,
.btn-github:disabled {
    color: #fff;
    background-color: #222;
    border-color: #222;
}

.btn-github:not(:disabled):not(.disabled).active,
.btn-github:not(:disabled):not(.disabled):active,
.show>.btn-github.dropdown-toggle {
    color: #fff;
    background-color: #090909;
    border-color: #020202;
}

.btn-github:not(:disabled):not(.disabled).active:focus,
.btn-github:not(:disabled):not(.disabled):active:focus,
.show>.btn-github.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 transparent;
}

.btn-github:not(:disabled):active,
.btn-github:not(:disabled):focus,
.btn-github:not(:disabled):hover {
    box-shadow: 0 0.22rem 0.525rem rgba(34, 34, 34, 0.4),
        0 0.0625rem 0.385rem rgba(34, 34, 34, 0.54);
}

.btn-outline-primary:not(:disabled):hover {
    box-shadow: 0 0.22rem 0.525rem rgba(60, 68, 177, 0.4),
        0 0.0625rem 0.385rem rgba(60, 68, 177, 0.54);
}

.btn-outline-secondary:not(:disabled):hover {
    box-shadow: 0 0.22rem 0.525rem rgba(248, 249, 255, 0.4),
        0 0.0625rem 0.385rem rgba(248, 249, 255, 0.54);
}

.btn-outline-success:not(:disabled):hover {
    box-shadow: 0 0.22rem 0.525rem rgba(27, 201, 67, 0.4),
        0 0.0625rem 0.385rem rgba(27, 201, 67, 0.54);
}

.btn-outline-info:not(:disabled):hover {
    box-shadow: 0 0.22rem 0.525rem rgba(17, 197, 219, 0.4),
        0 0.0625rem 0.385rem rgba(17, 197, 219, 0.54);
}

.btn-outline-warning:not(:disabled):hover {
    box-shadow: 0 0.22rem 0.525rem rgba(244, 119, 46, 0.4),
        0 0.0625rem 0.385rem rgba(244, 119, 46, 0.54);
}

.btn-outline-danger:not(:disabled):hover {
    box-shadow: 0 0.22rem 0.525rem rgba(248, 50, 69, 0.4),
        0 0.0625rem 0.385rem rgba(248, 50, 69, 0.54);
}

.btn-outline-light:not(:disabled):hover {
    box-shadow: 0 0.22rem 0.525rem rgba(244, 245, 253, 0.4),
        0 0.0625rem 0.385rem rgba(244, 245, 253, 0.54);
}

.btn-outline-dark:not(:disabled):hover {
    box-shadow: 0 0.22rem 0.525rem rgba(122, 123, 151, 0.4),
        0 0.0625rem 0.385rem rgba(122, 123, 151, 0.54);
}

.btn-outline-first:not(:disabled):hover {
    box-shadow: 0 0.22rem 0.525rem rgba(65, 145, 255, 0.4),
        0 0.0625rem 0.385rem rgba(65, 145, 255, 0.54);
}

.btn-outline-second:not(:disabled):hover {
    box-shadow: 0 0.22rem 0.525rem rgba(7, 9, 25, 0.4),
        0 0.0625rem 0.385rem rgba(7, 9, 25, 0.54);
}

.btn-spinner {
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-align: center;
    align-items: center;
}

.btn-secondary {
    border-color: #f8f9ff;
    box-shadow: 0 0.25rem 0.55rem rgba(166, 166, 185, 0.55);
    color: #fff;
    background-color: #3c44b1;
}

.btn-secondary:not(:disabled):active,
.btn-secondary:not(:disabled):focus,
.btn-secondary:not(:disabled):hover {
    border-color: #f8f9ff;
    color: #080919;
    box-shadow: 0 0.22rem 0.525rem rgba(166, 166, 185, 0.4),
        0 0.0625rem 0.385rem rgba(166, 166, 185, 0.54);
}

.btn-secondary:not(:disabled):active {
    border-color: transparent !important;
    background: #eeeff8 !important;
}

.btn-link span {
    position: relative;
    transition: all 0.2s ease-in-out;
}

.btn-link span:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    visibility: hidden;
    transform: scaleX(0);
    transition: all 0.3s ease-in-out 0s;
}

.btn-link:hover span:before {
    visibility: visible;
    transform: scaleX(1);
}

.btn-link-primary span {
    color: #3c44b1;
}

.btn-link-primary span:before {
    background-color: #3c44b1;
}

.btn-link-primary:hover span {
    color: #2f358b;
}

.btn-link-secondary span {
    color: #f8f9ff;
}

.btn-link-secondary span:before {
    background-color: #f8f9ff;
}

.btn-link-secondary:hover span {
    color: #c5cdff;
}

.btn-link-success span {
    color: #1bc943;
}

.btn-link-success span:before {
    background-color: #1bc943;
}

.btn-link-success:hover span {
    color: #159c34;
}

.btn-link-info span {
    color: #11c5db;
}

.btn-link-info span:before {
    background-color: #11c5db;
}

.btn-link-info:hover span {
    color: #0d9aac;
}

.btn-link-warning span {
    color: #f4772e;
}

.btn-link-warning span:before {
    background-color: #f4772e;
}

.btn-link-warning:hover span {
    color: #e35b0c;
}

.btn-link-danger span {
    color: #f83245;
}

.btn-link-danger span:before {
    background-color: #f83245;
}

.btn-link-danger:hover span {
    color: #ef081e;
}

.btn-link-light span {
    color: #f4f5fd;
}

.btn-link-light span:before {
    background-color: #f4f5fd;
}

.btn-link-light:hover span {
    color: #c9cef5;
}

.btn-link-dark span {
    color: #7a7b97;
}

.btn-link-dark span:before {
    background-color: #7a7b97;
}

.btn-link-dark:hover span {
    color: #61627d;
}

.btn-link-first span {
    color: #4191ff;
}

.btn-link-first span:before {
    background-color: #4191ff;
}

.btn-link-first:hover span {
    color: #0e73ff;
}

.btn-link-second span {
    color: #070919;
}

.btn-link-second span:before {
    background-color: #070919;
}

.btn-link-second:hover span {
    color: #000;
}

.btn-link {
    font-weight: 400;
    color: #3c44b1;
    text-decoration: none;
}

.btn-link:hover {
    color: #292e78;
    text-decoration: none;
    transform: none;
}

.btn-link.focus,
.btn-link:focus {
    text-decoration: none;
    box-shadow: none;
}

.btn-link.disabled,
.btn-link:disabled {
    color: #d1d2db;
    pointer-events: none;
}

.card,
.card1,
#card-projects1,
#card-projects2,
.card-pricinglist,
.card-service-text {
    box-shadow: 0 0.46875rem 2.1875rem rgba(0, 0, 0, 0.03),
        0 0.9375rem 1.40625rem rgba(0, 0, 0, 0.1),
        0 0.25rem 0.53125rem rgba(0, 0, 0, 0.05),
        0 0.125rem 0.1875rem rgba(0, 0, 0, 0.03);
}

.card-services {
    box-shadow: 0 0.46875rem 2.1875rem rgba(0, 0, 0, 0.1),
        0 0.9375rem 1.40625rem rgba(0, 0, 0, 0.5),
        0 0.25rem 0.53125rem rgba(0, 0, 0, 0.05),
        0 0.125rem 0.1875rem rgba(0, 0, 0, 0.03);
}

.card .card-title {
    font-size: 1.1875rem;
}

.card-text {
    color: #7a7b97;
}

.card-transparent {
    background: none;
    box-shadow: 0 0 0 0 transparent !important;
}

.input-group-seamless .input-group-text {
    background: transparent;
    border-color: transparent;
    padding: 0 !important;
    width: 100%;
    text-align: center;
}

.input-group-seamless .input-group-text i {
    margin: 0 auto;
    opacity: 0.6;
}

.input-group-seamless .form-control {
    border-radius: 0.29rem !important;
}

.input-group-seamless .input-group-append {
    position: absolute;
    right: 0;
}

.input-group-seamless .input-group-append,
.input-group-seamless .input-group-prepend {
    position: absolute;
    top: 0;
    margin: 0;
    border: 0;
    height: 100%;
    width: 40px;
    padding: 0 !important;
    z-index: 4;
}

.input-group-seamless .input-group-prepend {
    left: 0;
}

.input-group-seamless .input-group-prepend+.form-control {
    padding-left: calc(18px + 1.3rem);
}

.input-group-seamless.input-group-sm .input-group-prepend {
    width: 30px;
}

.input-group-seamless.input-group-sm .input-group-prepend+.form-control {
    padding-left: calc(10px + 1.1rem);
    border-radius: 0.29rem;
}

.input-group-seamless.input-group-lg .input-group-prepend {
    width: 50px;
}

.input-group-seamless.input-group-lg .input-group-prepend+.form-control {
    padding-left: calc(20px + 1.8rem);
    border-radius: 0.29rem;
}

.form-control.is-invalid,
.form-control.is-valid,
.was-validated .form-control:invalid,
.was-validated .form-control:valid {
    background-position: 98% calc(0.375em + 0.265rem) !important;
}

.progress {
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}

.progress .progress-bar:last-child {
    border-top-right-radius: 0.65rem;
    border-bottom-right-radius: 0.65rem;
}

.progress.progress-bar-sm {
    height: 0.5rem;
}

.progress.progress-bar-xs {
    height: 0.3rem;
}

.progress.progress-bar-rounded {
    border-radius: 30px;
}

.progress-bar-animated-alt.progress-bar,
.progress-bar-animated-alt .progress-bar {
    position: relative;
}

.progress-bar-animated-alt.progress-bar:after,
.progress-bar-animated-alt .progress-bar:after {
    content: "";
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    animation: d 2s ease infinite;
}

@keyframes d {
    0% {
        opacity: 0.4;
        width: 0;
    }

    to {
        opacity: 0;
        width: 100%;
    }
}

.pagination .page-link {
    border-radius: 0.2rem !important;
    margin: 0 0.28571rem;
}

.pagination .page-item.active .page-link {
    box-shadow: 0 0.313rem 0.8rem rgba(122, 123, 151, 0.5),
        0 0.126rem 0.225rem rgba(122, 123, 151, 0.3);
}

.modal-content {
    box-shadow: 0 5rem 14rem 0 hsla(0, 0%, 100%, 0.3),
        0 0.8rem 2.3rem rgba(0, 0, 0, 0.6), 0 0.2rem 0.3rem rgba(0, 0, 0, 0.45);
}

.navbar-toggler {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
}

.navbar-toggler:active,
.navbar-toggler:focus {
    outline: none;
}

.navbar-brand {
    padding: 0;
}

.navbar-dark .hamburger .hamburger-inner,
.navbar-dark .hamburger .hamburger-inner:after,
.navbar-dark .hamburger .hamburger-inner:before,
.navbar-dark .hamburger.is-active .hamburger-inner,
.navbar-dark .hamburger.is-active .hamburger-inner:after,
.navbar-dark .hamburger.is-active .hamburger-inner:before {
    background-color: #fff;
}

.navbar-light .hamburger .hamburger-inner,
.navbar-light .hamburger .hamburger-inner:after,
.navbar-light .hamburger .hamburger-inner:before,
.navbar-light .hamburger.is-active .hamburger-inner,
.navbar-light .hamburger.is-active .hamburger-inner:after,
.navbar-light .hamburger.is-active .hamburger-inner:before {
    background-color: #070919;
}

.nav-collapse-alt .navbar-collapse-header {
    position: absolute;
    right: 1rem;
    top: 1rem;
}

.nav-collapse-alt .navbar-collapse {
    position: absolute;
    background: #fff;
    border-radius: 0.29rem;
    box-shadow: 0 0.5rem 1rem rgba(7, 9, 25, 0.55);
    padding: 0;
    left: 5%;
    width: 90%;
    top: 1rem;
    z-index: 5;
    visibility: hidden;
    opacity: 0;
}

.nav-collapse-alt .navbar-collapse.collapsing,
.nav-collapse-alt .navbar-collapse.show {
    visibility: visible;
    opacity: 1;
}

.navbar-collapse-inner {
    padding: 1rem;
}

.navbar-collapse-inner .list-group-flush .list-group-item:first-child {
    border-top-left-radius: 0.65rem;
    border-top-right-radius: 0.65rem;
}

.navbar-collapse-inner .list-group-flush .list-group-item:last-child {
    border-bottom-right-radius: 0.65rem;
    border-bottom-left-radius: 0.65rem;
}

.navbar-nav .dropdown-menu {
    display: none;
}

.nav-item.dropdown.show .dropdown-menu {
    display: block;
}

.navbar-nav.navbar-nav-dark .nav-item .nav-link {
    color: #7a7b97;
}

.navbar-nav.navbar-nav-dark .nav-item .nav-link:hover {
    color: #070919;
}

@keyframes navbar-collapse-active {
    0% {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes navbar-collapse-inactive {
    0% {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

.table {
    font-size: 0.95rem;
}

.table thead th {
    text-transform: uppercase;
    background: #f4f5fd;
    font-size: 0.83125rem;
}

.table tr {
    transition: all 0.2s ease-in-out;
}

.table td,
.table th {
    vertical-align: middle;
}

.tooltip .tooltip-inner {
    box-shadow: 0 0.313rem 0.8rem rgba(122, 123, 151, 0.5),
        0 0.126rem 0.225rem rgba(122, 123, 151, 0.3);
}

.hamburger {
    padding: 0;
    display: inline-block;
    cursor: pointer;
    transition-property: opacity, filter;
    transition-duration: .15s;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;
}

.hamburger.is-active:hover,
.hamburger:hover {
    opacity: .7;
}

.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner:after,
.hamburger.is-active .hamburger-inner:before {
    background-color: #070919;
}

.hamburger-box {
    width: 24px;
    height: 14px;
    display: inline-block;
    position: relative;
}

.hamburger-inner {
    display: block;
    top: 50%;
    margin-top: -1px;
}

.hamburger-inner,
.hamburger-inner:after,
.hamburger-inner:before {
    width: 24px;
    height: 2px;
    background-color: #070919;
    border-radius: 10px;
    position: absolute;
    transition-property: transform;
    transition-duration: .15s;
    transition-timing-function: ease;
}

.hamburger-inner:after,
.hamburger-inner:before {
    content: "";
    display: block;
}

.hamburger-inner:before {
    top: -6px;
}

.hamburger-inner:after {
    bottom: -6px;
}

.hamburger--elastic.collapsed.is-active .hamburger-inner,
.hamburger--elastic .hamburger-inner {
    top: 1px;
    transition-duration: .275s;
    transition-timing-function: cubic-bezier(.68, -.55, .265, 1.55);
}

.hamburger--elastic.collapsed.is-active .hamburger-inner:before,
.hamburger--elastic .hamburger-inner:before {
    top: 6px;
    transition: opacity .125s ease .275s;
}

.hamburger--elastic.collapsed.is-active .hamburger-inner:after,
.hamburger--elastic .hamburger-inner:after {
    top: 12px;
    transition: transform .275s cubic-bezier(.68, -.55, .265, 1.55);
}

.hamburger--elastic.active .hamburger-inner,
.hamburger--elastic.is-active:not(.collapsed) .hamburger-inner {
    transform: translate3d(0, 6px, 0) rotate(135deg);
    transition-delay: 75ms;
}

.hamburger--elastic.active .hamburger-inner:before,
.hamburger--elastic.is-active:not(.collapsed) .hamburger-inner:before {
    transition-delay: 0s;
    opacity: 0;
}

.hamburger--elastic.active .hamburger-inner:after,
.hamburger--elastic.is-active:not(.collapsed) .hamburger-inner:after {
    transform: translate3d(0, -12px, 0) rotate(-270deg);
    transition-delay: 75ms;
}

.popover {
    box-shadow: 0 0.313rem 0.8rem rgba(122, 123, 151, 0.5),
        0 0.126rem 0.225rem rgba(122, 123, 151, 0.3);
}

.btn-group {
    transition: all 0.2s ease-in-out;
}

.btn-group>.btn {
    transform: translateY(0);
}

.btn-group:hover>.btn {
    transform: translateY(-2px);
}

.dropdown-item {
    transition: all 0.2s ease-in-out;
}

.dropdown-menu {
    box-shadow: 0 0.313rem 0.8rem rgba(122, 123, 151, 0.5),
        0 0.126rem 0.225rem rgba(122, 123, 151, 0.3);
    display: block;
    transition: visibility 0.25s, opacity 0.25s;
    opacity: 0;
    visibility: hidden;
}

.dropdown-menu.show {
    display: block;
    visibility: visible;
    opacity: 1;
}

.dropdown-toggle:after {
    position: relative;
    top: 2px;
}

.scroll-area {
    overflow-x: hidden;
    height: 400px;
}

.scroll-area-xs {
    height: 150px;
    overflow-x: hidden;
}

.scroll-area-sm {
    height: 200px;
    overflow-x: hidden;
}

.scroll-area-md {
    height: 300px;
    overflow-x: hidden;
}

.scroll-area-lg {
    height: 400px;
    overflow-x: hidden;
}

.scroll-area-x {
    overflow-x: auto;
    width: 100%;
    max-width: 100%;
}

.shadow-overflow {
    position: relative;
}

.shadow-overflow:after,
.shadow-overflow:before {
    width: 100%;
    bottom: auto;
    top: 0;
    left: 0;
    height: 1rem;
    position: absolute;
    z-index: 10;
    content: "";
    background: linear-gradient(180deg, #fff 20%, hsla(0, 0%, 100%, 0));
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="#00ffffff", GradientType=0);
}

.shadow-overflow:after {
    bottom: 0;
    top: auto;
    background: linear-gradient(180deg, hsla(0, 0%, 100%, 0) 0, #fff 80%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#00ffffff", endColorstr="#ffffff", GradientType=0);
}

.list-group-item {
    font-size: 0.95rem;
    transition: all 0.2s ease-in-out;
}

.list-group-item .nav-icon-wrapper {
    width: 32px;
    text-align: center;
}

.list-group-item+.list-group-item>.card-header {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.nav-tabs {
    padding: 0 0.66667rem;
}

.nav-tabs .nav-item {
    padding: 0 0.16667rem;
}

.nav-tabs .nav-item .nav-link {
    font-size: 0.83125rem;
    text-transform: uppercase;
    margin-top: 2px;
    color: #a6a6b9;
    transition: all 0.2s ease-in-out;
}

.nav-tabs .nav-item .nav-link:hover {
    color: #070919;
}

.nav-tabs .nav-item .nav-link.active {
    margin-top: 0;
    color: #3c44b1;
    border-top: 3px solid #3c44b1;
}

.nav-tabs .nav-item .nav-link.active:hover {
    color: #3c44b1;
}

.swal2-modal {
    box-shadow: 0 5rem 14rem 0 hsla(0, 0%, 100%, 0.3),
        0 0.8rem 2.3rem rgba(0, 0, 0, 0.6), 0 0.2rem 0.3rem rgba(0, 0, 0, 0.45);
    border-radius: 0.65rem;
    background: #fff;
}

.app-header {
    height: 50px;
    padding: 0 2rem;
    background: #FBE9D8;
    box-shadow: 0 0.313rem 0.75rem rgba(7, 9, 25, 0.05), 0 0.126rem 0.15rem rgba(7, 9, 25, 0.03);
    -ms-flex-align: center;
    align-items: center;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    position: fixed;
    left: 0px;
    right: 0;
    z-index: 10;
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
}

.app-header .toggle-sidebar-mobile {
    display: none;
}

.mobile {
    display: none;
}

.app-header .d-44 {
    height: 36px;
    line-height: 32px;
    width: 38px;
}

.app-header .d-44 .badge-header-alt {
    border: 0;
    top: 1px !important;
    box-shadow: 0 0 0 0 transparent;
    height: 6px;
    width: 6px;
    left: 50%;
    margin-left: -3px;
}

@media (max-width:991.98px) {
    .app-header {
        left: 0
    }

    .app-header .toggle-sidebar-mobile {
        display: block
    }

    .mobile {
        display: block;
    }
}

.search-link {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    color: #d1d2db;
    transition: all 0.2s ease-in-out;
    margin-left: 1rem;
    padding-left: 1rem;
}

.search-link input {
    border: 0;
    background: transparent;
    box-shadow: none !important;
    outline: none !important;
}

.search-link .search-icon {
    font-size: 1.1875rem;
    color: #d1d2db;
    padding: 0 0.66667rem 0 0;
    transition: all 0.2s ease-in-out;
}

.search-link:hover,
.search-link:hover .search-icon {
    color: #a6a6b9;
}

.app-sidebar {
    width: 260px;
    -ms-flex-direction: column;
    flex-direction: column;
    display: -ms-flexbox;
    display: flex;
    position: fixed;
    height: 100%;
    transition: width 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    z-index: 11;
    border-radius: 50px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    top: 100px;
}

.app-sidebar--header {
    background: transparent;
    padding: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
    position: relative;
}

.app-sidebar--header:after {
    content: "";
    width: 90%;
    left: 5%;
    bottom: 0;
    height: 1px;
    position: absolute;
}

.app-sidebar--content {
    background: transparent;
    overflow-y: auto;
    overflow-x: hidden;
}

.app-sidebar--content .sidebar-header {
    text-transform: uppercase;
    font-size: .79167rem;
    padding: 1rem;
    height: auto;
    overflow: auto;
    transition: all .2s ease-in-out;
}

.app-sidebar--footer {
    height: 70px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-line-pack: center;
    align-content: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0;
    transition: all 0.2s ease-in-out;
}

.sidebar-navigation ul {
    padding-left: 0;
    list-style: none;
}

.sidebar-navigation ul,
.sidebar-navigation ul>li {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
}

.sidebar-navigation ul>li {
    position: relative;
    padding: 1px 0;
}

.sidebar-navigation ul a {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-line-pack: center;
    align-content: center;
    height: 44px;
    padding: 0 1.33333rem;
    transition: background 0.3s ease-out;
    -ms-flex-pack: justify;
    justify-content: space-between;
    font-size: 0.86364rem;
}

.sidebar-navigation ul a .fas {
    transition: all 0.2s ease-in-out;
    font-size: 0.73077rem;
}

.sidebar-navigation ul a span {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
}

.sidebar-navigation ul a span .fas {
    margin-right: 1rem;
    transition: all 0.2s ease-in-out;
}

.sidebar-navigation ul a span span {
    white-space: nowrap;
    transform: translateZ(0);
    transition: all 0.2s ease-in-out;
}

.app-sidebar--dark .sidebar-header {
    color: hsla(0, 0%, 100%, 0.2);
}

.app-sidebar--dark .app-sidebar--header:after {
    background: hsla(0, 0%, 100%, 0.1);
}

.app-sidebar--dark .app-sidebar--footer {
    background: rgba(0, 0, 0, 0.1);
}

.app-sidebar--dark .sidebar-navigation ul {
    padding-left: 0;
    list-style: none;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
}

.app-sidebar--dark .sidebar-navigation ul>li {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    position: relative;
}

.app-sidebar--dark .sidebar-navigation ul>li.mm-active>a {
    color: hsla(0, 0%, 100%, 0.9);
    background: rgba(7, 9, 25, 0.1);
}

.app-sidebar--dark .sidebar-navigation ul>li.mm-active>a .fas {
    opacity: 0.6;
}

.app-sidebar--dark .sidebar-navigation ul a {
    /* color: hsla(0, 0%, 100%, 0.5); */
    color: #455a64;
}

.app-sidebar--dark .sidebar-navigation ul a:after {
    background: rgba(7, 9, 25, 0.2);
}

.app-sidebar--dark .sidebar-navigation ul a .fas {
    color: #fff;
    fill: hsla(0, 0%, 100%, 0.25);
    opacity: 0.6;
}

.app-sidebar--dark .sidebar-navigation ul a:hover {
    /* color: hsla(0, 0%, 100%, 0.9); */
    /* background: rgba(7, 9, 25, 0.1); */
    color: #455a64;
}

.app-sidebar--dark .sidebar-navigation ul a:hover .fas {
    opacity: 1;
}

body,
html {
    -ms-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
}

body {
    -ms-flex-direction: column;
    flex-direction: column;
}

.app-wrapper,
body {
    display: -ms-flexbox;
    display: flex;
}

.app-wrapper {
    -ms-flex: 1;
    flex: 1;
    /* background: #f4f5fd; */
}

.app-main {
    -ms-flex: 1 auto;
    flex: 1 auto;
    min-width: 0;
    -ms-flex-direction: column;
    flex-direction: column;
    position: relative;
}

.app-content,
.app-main {
    display: -ms-flexbox;
    display: flex;
}

.app-content {
    -ms-flex: 1;
    flex: 1;
    padding: 48px 0 0 260px;
    -ms-flex-direction: column;
    flex-direction: column;
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
}

.app-content--inner {
    padding: 2rem;
    width: 100%;
    -ms-flex: 1;
    flex: 1;
}

.sidebar-mobile-overlay {
    width: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(7, 9, 25, 0.4);
    z-index: 11;
}

@media (max-width:991.98px) {
    .app-sidebar {
        left: -260px
    }

    .app-sidebar,
    .sidebar-open-mobile .app-sidebar {
        transition: all .5s cubic-bezier(.685, .0473, .346, 1)
    }

    .sidebar-open-mobile .app-sidebar {
        left: 0;
        box-shadow: 0 .5rem 1rem rgba(7, 9, 25, .55);
        z-index: 12
    }
}

@media (max-width:991.98px) {
    .app-content {
        padding-left: 0
    }

    .sidebar-open-mobile {
        overflow: hidden
    }

    .sidebar-open-mobile .sidebar-mobile-overlay {
        opacity: 1;
        visibility: visible;
        width: 100%;
        height: 100%
    }

    .sidebar-open-mobile .app-content {
        transform: translate3d(260px, 0, 0)
    }
}

.page-title {
    margin-bottom: 2rem;
}

#dashboard_image {
    height: 300px;
}

.nav-header {
    text-transform: uppercase;
    color: #d1d2db;
    font-size: 0.79167rem;
    padding: 1rem 0;
}

.nav-header:first-child {
    padding-top: 0;
}

.nav-pills-hover .nav-link {
    transition: all 0.2s ease-in-out;
    margin: 2px 0;
    font-size: 0.86364rem;
    color: #7a7b97;
}

.nav-pills-hover .nav-link i {
    transition: all 0.2s ease-in-out;
    margin: 0 0.33333rem 0 0;
    width: 26px;
    text-align: center;
    color: #d1d2db;
    font-size: 1.045rem;
}

.nav-pills-hover .nav-link.active i {
    color: #fff;
}

.nav-pills-hover .nav-link:hover:not(.active) {
    background: #f4f5fd;
    color: #070919;
}

.nav-pills-hover .nav-link:hover:not(.active) i {
    color: #7a7b97;
}

.search-widget {
    position: relative;
}

.search-widget .fas {
    position: absolute;
    left: 14px;
    top: 14px;
}

.search-widget .form-control {
    padding-left: 40px;
}

.breadcrumb {
    background: transparent;
}

.ps {
    overflow: hidden !important;
    overflow-anchor: none;
    -ms-touch-action: auto;
    touch-action: auto;
}

.ps__rail-x {
    display: none !important;
    height: 15px;
    bottom: 0;
}

.ps__rail-x,
.ps__rail-y {
    opacity: 0;
    transition: background-color 0.2s linear, opacity 0.2s linear;
    position: absolute;
    z-index: 7;
}

.ps__rail-y {
    display: none;
    width: 15px;
    right: 0;
    border-radius: 50px;
}

.ps--active-x>.ps__rail-x,
.ps--active-y>.ps__rail-y {
    display: block;
    background-color: transparent;
}

.ps--focus>.ps__rail-x,
.ps--focus>.ps__rail-y,
.ps--scrolling-x>.ps__rail-x,
.ps--scrolling-y>.ps__rail-y,
.ps:hover>.ps__rail-x,
.ps:hover>.ps__rail-y {
    opacity: 0.6;
}

.ps__rail-x:focus,
.ps__rail-x:hover,
.ps__rail-y:focus,
.ps__rail-y:hover {
    background-color: rgba(0, 0, 0, 0.1);
    opacity: 0.9;
}

.ps__thumb-x {
    transition: background-color 0.2s linear, height 0.2s ease-in-out;
    height: 6px;
    bottom: 2px;
}

.ps__thumb-x,
.ps__thumb-y {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    position: absolute;
}

.ps__thumb-y {
    transition: background-color 0.2s linear, width 0.2s ease-in-out;
    width: 6px;
    right: 2px;
}

.ps__rail-x:focus>.ps__thumb-x,
.ps__rail-x:hover>.ps__thumb-x {
    background-color: rgba(0, 0, 0, 0.12);
    height: 11px;
}

.ps__rail-y:focus>.ps__thumb-y,
.ps__rail-y:hover>.ps__thumb-y {
    background-color: rgba(0, 0, 0, 0.12);
    width: 11px;
}

@supports (-ms-overflow-style: none) {
    .ps {
        overflow: auto !important;
    }
}

.scrollbar-container,
.scrollbar-sidebar {
    position: relative;
    height: 100%;
}

.fa,
.fab,
.fal,
.far,
.fas {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
}

.fa-lg {
    font-size: 1.33333em;
    line-height: 0.75em;
    vertical-align: -0.0667em;
}

.fa-xs {
    font-size: 0.75em;
}

.fa-sm {
    font-size: 0.875em;
}

.fa-1x {
    font-size: 1em;
}

.fa-2x {
    font-size: 2em;
}

.fa-3x {
    font-size: 3em;
}

.fa-4x {
    font-size: 4em;
}

.fa-5x {
    font-size: 5em;
}

.fa-6x {
    font-size: 6em;
}

.fa-7x {
    font-size: 7em;
}

.fa-8x {
    font-size: 8em;
}

.fa-9x {
    font-size: 9em;
}

.fa-10x {
    font-size: 10em;
}

.fa-fw {
    text-align: center;
    width: 1.25em;
}

.fa-ul {
    list-style-type: none;
    margin-left: 2.5em;
    padding-left: 0;
}

.fa-ul>li {
    position: relative;
}

.fa-li {
    left: -2em;
    position: absolute;
    text-align: center;
    width: 2em;
    line-height: inherit;
}

.fa-border {
    border: 0.08em solid #eee;
    border-radius: 0.1em;
    padding: 0.2em 0.25em 0.15em;
}

.fa-pull-left {
    float: left;
}

.fa-pull-right {
    float: right;
}

.fa.fa-pull-left,
.fab.fa-pull-left,
.fal.fa-pull-left,
.far.fa-pull-left,
.fas.fa-pull-left {
    margin-right: 0.3em;
}

.fa.fa-pull-right,
.fab.fa-pull-right,
.fal.fa-pull-right,
.far.fa-pull-right,
.fas.fa-pull-right {
    margin-left: 0.3em;
}

.fa-spin {
    animation: r 2s infinite linear;
}

.fa-pulse {
    animation: r 1s infinite steps(8);
}

.fa-rotate-90 {
    transform: rotate(90deg);
}

.fa-rotate-180 {
    transform: rotate(180deg);
}

.fa-rotate-270 {
    transform: rotate(270deg);
}

.fa-flip-horizontal {
    transform: scaleX(-1);
}

.fa-flip-vertical {
    transform: scaleY(-1);
}

.fa-flip-both,
.fa-flip-horizontal.fa-flip-vertical {
    transform: scale(-1);
}

:root .fa-flip-both,
:root .fa-flip-horizontal,
:root .fa-flip-vertical,
:root .fa-rotate-90,
:root .fa-rotate-180,
:root .fa-rotate-270 {
    filter: none;
}

.fa-stack {
    display: inline-block;
    height: 2em;
    line-height: 2em;
    position: relative;
    vertical-align: middle;
    width: 2.5em;
}

.fa-stack-1x,
.fa-stack-2x {
    left: 0;
    position: absolute;
    text-align: center;
    width: 100%;
}

.fa-stack-1x {
    line-height: inherit;
}

.fa-stack-2x {
    font-size: 2em;
}

.fa-inverse {
    color: #fff;
}

.fa-500px:before {
    content: "\f26e";
}

.fa-accessible-icon:before {
    content: "\f368";
}

.fa-accusoft:before {
    content: "\f369";
}

.fa-acquisitions-incorporated:before {
    content: "\f6af";
}

.fa-ad:before {
    content: "\f641";
}

.fa-address-book:before {
    content: "\f2b9";
}

.fa-address-card:before {
    content: "\f2bb";
}

.fa-adjust:before {
    content: "\f042";
}

.fa-adn:before {
    content: "\f170";
}

.fa-adobe:before {
    content: "\f778";
}

.fa-adversal:before {
    content: "\f36a";
}

.fa-affiliatetheme:before {
    content: "\f36b";
}

.fa-air-freshener:before {
    content: "\f5d0";
}

.fa-airbnb:before {
    content: "\f834";
}

.fa-algolia:before {
    content: "\f36c";
}

.fa-align-center:before {
    content: "\f037";
}

.fa-align-justify:before {
    content: "\f039";
}

.fa-align-left:before {
    content: "\f036";
}

.fa-align-right:before {
    content: "\f038";
}

.fa-alipay:before {
    content: "\f642";
}

.fa-allergies:before {
    content: "\f461";
}

.fa-amazon:before {
    content: "\f270";
}

.fa-amazon-pay:before {
    content: "\f42c";
}

.fa-ambulance:before {
    content: "\f0f9";
}

.fa-american-sign-language-interpreting:before {
    content: "\f2a3";
}

.fa-amilia:before {
    content: "\f36d";
}

.fa-anchor:before {
    content: "\f13d";
}

.fa-android:before {
    content: "\f17b";
}

.fa-angellist:before {
    content: "\f209";
}

.fa-angle-double-down:before {
    content: "\f103";
}

.fa-angle-double-left:before {
    content: "\f100";
}

.fa-angle-double-right:before {
    content: "\f101";
}

.fa-angle-double-up:before {
    content: "\f102";
}

.fa-angle-down:before {
    content: "\f107";
}

.fa-angle-left:before {
    content: "\f104";
}

.fa-angle-right:before {
    content: "\f105";
}

.fa-angle-up:before {
    content: "\f106";
}

.fa-angry:before {
    content: "\f556";
}

.fa-angrycreative:before {
    content: "\f36e";
}

.fa-angular:before {
    content: "\f420";
}

.fa-ankh:before {
    content: "\f644";
}

.fa-app-store:before {
    content: "\f36f";
}

.fa-app-store-ios:before {
    content: "\f370";
}

.fa-apper:before {
    content: "\f371";
}

.fa-apple:before {
    content: "\f179";
}

.fa-apple-alt:before {
    content: "\f5d1";
}

.fa-apple-pay:before {
    content: "\f415";
}

.fa-archive:before {
    content: "\f187";
}

.fa-archway:before {
    content: "\f557";
}

.fa-arrow-alt-circle-down:before {
    content: "\f358";
}

.fa-arrow-alt-circle-left:before {
    content: "\f359";
}

.fa-arrow-alt-circle-right:before {
    content: "\f35a";
}

.fa-arrow-alt-circle-up:before {
    content: "\f35b";
}

.fa-arrow-circle-down:before {
    content: "\f0ab";
}

.fa-arrow-circle-left:before {
    content: "\f0a8";
}

.fa-arrow-circle-right:before {
    content: "\f0a9";
}

.fa-arrow-circle-up:before {
    content: "\f0aa";
}

.fa-arrow-down:before {
    content: "\f063";
}

.fa-arrow-left:before {
    content: "\f060";
}

.fa-arrow-right:before {
    content: "\f061";
}

.fa-arrow-up:before {
    content: "\f062";
}

.fa-arrows-alt:before {
    content: "\f0b2";
}

.fa-arrows-alt-h:before {
    content: "\f337";
}

.fa-arrows-alt-v:before {
    content: "\f338";
}

.fa-artstation:before {
    content: "\f77a";
}

.fa-assistive-listening-systems:before {
    content: "\f2a2";
}

.fa-asterisk:before {
    content: "\f069";
}

.fa-asymmetrik:before {
    content: "\f372";
}

.fa-at:before {
    content: "\f1fa";
}

.fa-atlas:before {
    content: "\f558";
}

.fa-atlassian:before {
    content: "\f77b";
}

.fa-atom:before {
    content: "\f5d2";
}

.fa-audible:before {
    content: "\f373";
}

.fa-audio-description:before {
    content: "\f29e";
}

.fa-autoprefixer:before {
    content: "\f41c";
}

.fa-avianex:before {
    content: "\f374";
}

.fa-aviato:before {
    content: "\f421";
}

.fa-award:before {
    content: "\f559";
}

.fa-aws:before {
    content: "\f375";
}

.fa-baby:before {
    content: "\f77c";
}

.fa-baby-carriage:before {
    content: "\f77d";
}

.fa-backspace:before {
    content: "\f55a";
}

.fa-backward:before {
    content: "\f04a";
}

.fa-bacon:before {
    content: "\f7e5";
}

.fa-balance-scale:before {
    content: "\f24e";
}

.fa-ban:before {
    content: "\f05e";
}

.fa-band-aid:before {
    content: "\f462";
}

.fa-bandcamp:before {
    content: "\f2d5";
}

.fa-barcode:before {
    content: "\f02a";
}

.fa-bars:before {
    content: "\f0c9";
}

.fa-baseball-ball:before {
    content: "\f433";
}

.fa-basketball-ball:before {
    content: "\f434";
}

.fa-bath:before {
    content: "\f2cd";
}

.fa-battery-empty:before {
    content: "\f244";
}

.fa-battery-full:before {
    content: "\f240";
}

.fa-battery-half:before {
    content: "\f242";
}

.fa-battery-quarter:before {
    content: "\f243";
}

.fa-battery-three-quarters:before {
    content: "\f241";
}

.fa-battle-net:before {
    content: "\f835";
}

.fa-bed:before {
    content: "\f236";
}

.fa-beer:before {
    content: "\f0fc";
}

.fa-behance:before {
    content: "\f1b4";
}

.fa-behance-square:before {
    content: "\f1b5";
}

.fa-bell:before {
    content: "\f0f3";
}

.fa-bell-slash:before {
    content: "\f1f6";
}

.fa-bezier-curve:before {
    content: "\f55b";
}

.fa-bible:before {
    content: "\f647";
}

.fa-bicycle:before {
    content: "\f206";
}

.fa-bimobject:before {
    content: "\f378";
}

.fa-binoculars:before {
    content: "\f1e5";
}

.fa-biohazard:before {
    content: "\f780";
}

.fa-birthday-cake:before {
    content: "\f1fd";
}

.fa-bitbucket:before {
    content: "\f171";
}

.fa-bitcoin:before {
    content: "\f379";
}

.fa-bity:before {
    content: "\f37a";
}

.fa-black-tie:before {
    content: "\f27e";
}

.fa-blackberry:before {
    content: "\f37b";
}

.fa-blender:before {
    content: "\f517";
}

.fa-blender-phone:before {
    content: "\f6b6";
}

.fa-blind:before {
    content: "\f29d";
}

.fa-blog:before {
    content: "\f781";
}

.fa-blogger:before {
    content: "\f37c";
}

.fa-blogger-b:before {
    content: "\f37d";
}

.fa-bluetooth:before {
    content: "\f293";
}

.fa-bluetooth-b:before {
    content: "\f294";
}

.fa-bold:before {
    content: "\f032";
}

.fa-bolt:before {
    content: "\f0e7";
}

.fa-bomb:before {
    content: "\f1e2";
}

.fa-bone:before {
    content: "\f5d7";
}

.fa-bong:before {
    content: "\f55c";
}

.fa-book:before {
    content: "\f02d";
}

.fa-book-dead:before {
    content: "\f6b7";
}

.fa-book-medical:before {
    content: "\f7e6";
}

.fa-book-open:before {
    content: "\f518";
}

.fa-book-reader:before {
    content: "\f5da";
}

.fa-bookmark:before {
    content: "\f02e";
}

.fa-bootstrap:before {
    content: "\f836";
}

.fa-bowling-ball:before {
    content: "\f436";
}

.fa-box:before {
    content: "\f466";
}

.fa-box-open:before {
    content: "\f49e";
}

.fa-boxes:before {
    content: "\f468";
}

.fa-braille:before {
    content: "\f2a1";
}

.fa-brain:before {
    content: "\f5dc";
}

.fa-bread-slice:before {
    content: "\f7ec";
}

.fa-briefcase:before {
    content: "\f0b1";
}

.fa-briefcase-medical:before {
    content: "\f469";
}

.fa-broadcast-tower:before {
    content: "\f519";
}

.fa-broom:before {
    content: "\f51a";
}

.fa-brush:before {
    content: "\f55d";
}

.fa-btc:before {
    content: "\f15a";
}

.fa-buffer:before {
    content: "\f837";
}

.fa-bug:before {
    content: "\f188";
}

.fa-building:before {
    content: "\f1ad";
}

.fa-bullhorn:before {
    content: "\f0a1";
}

.fa-bullseye:before {
    content: "\f140";
}

.fa-burn:before {
    content: "\f46a";
}

.fa-buromobelexperte:before {
    content: "\f37f";
}

.fa-bus:before {
    content: "\f207";
}

.fa-bus-alt:before {
    content: "\f55e";
}

.fa-business-time:before {
    content: "\f64a";
}

.fa-buysellads:before {
    content: "\f20d";
}

.fa-calculator:before {
    content: "\f1ec";
}

.fa-calendar:before {
    content: "\f133";
}

.fa-calendar-alt:before {
    content: "\f073";
}

.fa-calendar-check:before {
    content: "\f274";
}

.fa-calendar-day:before {
    content: "\f783";
}

.fa-calendar-minus:before {
    content: "\f272";
}

.fa-calendar-plus:before {
    content: "\f271";
}

.fa-calendar-times:before {
    content: "\f273";
}

.fa-calendar-week:before {
    content: "\f784";
}

.fa-camera:before {
    content: "\f030";
}

.fa-camera-retro:before {
    content: "\f083";
}

.fa-campground:before {
    content: "\f6bb";
}

.fa-canadian-maple-leaf:before {
    content: "\f785";
}

.fa-candy-cane:before {
    content: "\f786";
}

.fa-cannabis:before {
    content: "\f55f";
}

.fa-capsules:before {
    content: "\f46b";
}

.fa-car:before {
    content: "\f1b9";
}

.fa-car-alt:before {
    content: "\f5de";
}

.fa-car-battery:before {
    content: "\f5df";
}

.fa-car-crash:before {
    content: "\f5e1";
}

.fa-car-side:before {
    content: "\f5e4";
}

.fa-caret-down:before {
    content: "\f0d7";
}

.fa-caret-left:before {
    content: "\f0d9";
}

.fa-caret-right:before {
    content: "\f0da";
}

.fa-caret-square-down:before {
    content: "\f150";
}

.fa-caret-square-left:before {
    content: "\f191";
}

.fa-caret-square-right:before {
    content: "\f152";
}

.fa-caret-square-up:before {
    content: "\f151";
}

.fa-caret-up:before {
    content: "\f0d8";
}

.fa-carrot:before {
    content: "\f787";
}

.fa-cart-arrow-down:before {
    content: "\f218";
}

.fa-cart-plus:before {
    content: "\f217";
}

.fa-cash-register:before {
    content: "\f788";
}

.fa-cat:before {
    content: "\f6be";
}

.fa-cc-amazon-pay:before {
    content: "\f42d";
}

.fa-cc-amex:before {
    content: "\f1f3";
}

.fa-cc-apple-pay:before {
    content: "\f416";
}

.fa-cc-diners-club:before {
    content: "\f24c";
}

.fa-cc-discover:before {
    content: "\f1f2";
}

.fa-cc-jcb:before {
    content: "\f24b";
}

.fa-cc-mastercard:before {
    content: "\f1f1";
}

.fa-cc-paypal:before {
    content: "\f1f4";
}

.fa-cc-stripe:before {
    content: "\f1f5";
}

.fa-cc-visa:before {
    content: "\f1f0";
}

.fa-centercode:before {
    content: "\f380";
}

.fa-centos:before {
    content: "\f789";
}

.fa-certificate:before {
    content: "\f0a3";
}

.fa-chair:before {
    content: "\f6c0";
}

.fa-chalkboard:before {
    content: "\f51b";
}

.fa-chalkboard-teacher:before {
    content: "\f51c";
}

.fa-charging-station:before {
    content: "\f5e7";
}

.fa-chart-area:before {
    content: "\f1fe";
}

.fa-chart-bar:before {
    content: "\f080";
}

.fa-chart-line:before {
    content: "\f201";
}

.fa-chart-pie:before {
    content: "\f200";
}

.fa-check:before {
    content: "\f00c";
}

.fa-check-circle:before {
    content: "\f058";
}

.fa-check-double:before {
    content: "\f560";
}

.fa-check-square:before {
    content: "\f14a";
}

.fa-cheese:before {
    content: "\f7ef";
}

.fa-chess:before {
    content: "\f439";
}

.fa-chess-bishop:before {
    content: "\f43a";
}

.fa-chess-board:before {
    content: "\f43c";
}

.fa-chess-king:before {
    content: "\f43f";
}

.fa-chess-knight:before {
    content: "\f441";
}

.fa-chess-pawn:before {
    content: "\f443";
}

.fa-chess-queen:before {
    content: "\f445";
}

.fa-chess-rook:before {
    content: "\f447";
}

.fa-chevron-circle-down:before {
    content: "\f13a";
}

.fa-chevron-circle-left:before {
    content: "\f137";
}

.fa-chevron-circle-right:before {
    content: "\f138";
}

.fa-chevron-circle-up:before {
    content: "\f139";
}

.fa-chevron-down:before {
    content: "\f078";
}

.fa-chevron-left:before {
    content: "\f053";
}

.fa-chevron-right:before {
    content: "\f054";
}

.fa-chevron-up:before {
    content: "\f077";
}

.fa-child:before {
    content: "\f1ae";
}

.fa-chrome:before {
    content: "\f268";
}

.fa-chromecast:before {
    content: "\f838";
}

.fa-church:before {
    content: "\f51d";
}

.fa-circle:before {
    content: "\f111";
}

.fa-circle-notch:before {
    content: "\f1ce";
}

.fa-city:before {
    content: "\f64f";
}

.fa-clinic-medical:before {
    content: "\f7f2";
}

.fa-clipboard:before {
    content: "\f328";
}

.fa-clipboard-check:before {
    content: "\f46c";
}

.fa-clipboard-list:before {
    content: "\f46d";
}

.fa-clock:before {
    content: "\f017";
}

.fa-clone:before {
    content: "\f24d";
}

.fa-closed-captioning:before {
    content: "\f20a";
}

.fa-cloud:before {
    content: "\f0c2";
}

.fa-cloud-download-alt:before {
    content: "\f381";
}

.fa-cloud-meatball:before {
    content: "\f73b";
}

.fa-cloud-moon:before {
    content: "\f6c3";
}

.fa-cloud-moon-rain:before {
    content: "\f73c";
}

.fa-cloud-rain:before {
    content: "\f73d";
}

.fa-cloud-showers-heavy:before {
    content: "\f740";
}

.fa-cloud-sun:before {
    content: "\f6c4";
}

.fa-cloud-sun-rain:before {
    content: "\f743";
}

.fa-cloud-upload-alt:before {
    content: "\f382";
}

.fa-cloudscale:before {
    content: "\f383";
}

.fa-cloudsmith:before {
    content: "\f384";
}

.fa-cloudversify:before {
    content: "\f385";
}

.fa-cocktail:before {
    content: "\f561";
}

.fa-code:before {
    content: "\f121";
}

.fa-code-branch:before {
    content: "\f126";
}

.fa-codepen:before {
    content: "\f1cb";
}

.fa-codiepie:before {
    content: "\f284";
}

.fa-coffee:before {
    content: "\f0f4";
}

.fa-cog:before {
    content: "\f013";
}

.fa-cogs:before {
    content: "\f085";
}

.fa-coins:before {
    content: "\f51e";
}

.fa-columns:before {
    content: "\f0db";
}

.fa-comment:before {
    content: "\f075";
}

.fa-comment-alt:before {
    content: "\f27a";
}

.fa-comment-dollar:before {
    content: "\f651";
}

.fa-comment-dots:before {
    content: "\f4ad";
}

.fa-comment-medical:before {
    content: "\f7f5";
}

.fa-comment-slash:before {
    content: "\f4b3";
}

.fa-comments:before {
    content: "\f086";
}

.fa-comments-dollar:before {
    content: "\f653";
}

.fa-compact-disc:before {
    content: "\f51f";
}

.fa-compass:before {
    content: "\f14e";
}

.fa-compress:before {
    content: "\f066";
}

.fa-compress-arrows-alt:before {
    content: "\f78c";
}

.fa-concierge-bell:before {
    content: "\f562";
}

.fa-confluence:before {
    content: "\f78d";
}

.fa-connectdevelop:before {
    content: "\f20e";
}

.fa-contao:before {
    content: "\f26d";
}

.fa-cookie:before {
    content: "\f563";
}

.fa-cookie-bite:before {
    content: "\f564";
}

.fa-copy:before {
    content: "\f0c5";
}

.fa-copyright:before {
    content: "\f1f9";
}

.fa-couch:before {
    content: "\f4b8";
}

.fa-cpanel:before {
    content: "\f388";
}

.fa-creative-commons:before {
    content: "\f25e";
}

.fa-creative-commons-by:before {
    content: "\f4e7";
}

.fa-creative-commons-nc:before {
    content: "\f4e8";
}

.fa-creative-commons-nc-eu:before {
    content: "\f4e9";
}

.fa-creative-commons-nc-jp:before {
    content: "\f4ea";
}

.fa-creative-commons-nd:before {
    content: "\f4eb";
}

.fa-creative-commons-pd:before {
    content: "\f4ec";
}

.fa-creative-commons-pd-alt:before {
    content: "\f4ed";
}

.fa-creative-commons-remix:before {
    content: "\f4ee";
}

.fa-creative-commons-sa:before {
    content: "\f4ef";
}

.fa-creative-commons-sampling:before {
    content: "\f4f0";
}

.fa-creative-commons-sampling-plus:before {
    content: "\f4f1";
}

.fa-creative-commons-share:before {
    content: "\f4f2";
}

.fa-creative-commons-zero:before {
    content: "\f4f3";
}

.fa-credit-card:before {
    content: "\f09d";
}

.fa-critical-role:before {
    content: "\f6c9";
}

.fa-crop:before {
    content: "\f125";
}

.fa-crop-alt:before {
    content: "\f565";
}

.fa-cross:before {
    content: "\f654";
}

.fa-crosshairs:before {
    content: "\f05b";
}

.fa-crow:before {
    content: "\f520";
}

.fa-crown:before {
    content: "\f521";
}

.fa-crutch:before {
    content: "\f7f7";
}

.fa-css3:before {
    content: "\f13c";
}

.fa-css3-alt:before {
    content: "\f38b";
}

.fa-cube:before {
    content: "\f1b2";
}

.fa-cubes:before {
    content: "\f1b3";
}

.fa-cut:before {
    content: "\f0c4";
}

.fa-cuttlefish:before {
    content: "\f38c";
}

.fa-d-and-d:before {
    content: "\f38d";
}

.fa-d-and-d-beyond:before {
    content: "\f6ca";
}

.fa-dashcube:before {
    content: "\f210";
}

.fa-database:before {
    content: "\f1c0";
}

.fa-deaf:before {
    content: "\f2a4";
}

.fa-delicious:before {
    content: "\f1a5";
}

.fa-democrat:before {
    content: "\f747";
}

.fa-deploydog:before {
    content: "\f38e";
}

.fa-deskpro:before {
    content: "\f38f";
}

.fa-desktop:before {
    content: "\f108";
}

.fa-dev:before {
    content: "\f6cc";
}

.fa-deviantart:before {
    content: "\f1bd";
}

.fa-dharmachakra:before {
    content: "\f655";
}

.fa-dhl:before {
    content: "\f790";
}

.fa-diagnoses:before {
    content: "\f470";
}

.fa-diaspora:before {
    content: "\f791";
}

.fa-dice:before {
    content: "\f522";
}

.fa-dice-d20:before {
    content: "\f6cf";
}

.fa-dice-d6:before {
    content: "\f6d1";
}

.fa-dice-five:before {
    content: "\f523";
}

.fa-dice-four:before {
    content: "\f524";
}

.fa-dice-one:before {
    content: "\f525";
}

.fa-dice-six:before {
    content: "\f526";
}

.fa-dice-three:before {
    content: "\f527";
}

.fa-dice-two:before {
    content: "\f528";
}

.fa-digg:before {
    content: "\f1a6";
}

.fa-digital-ocean:before {
    content: "\f391";
}

.fa-digital-tachograph:before {
    content: "\f566";
}

.fa-directions:before {
    content: "\f5eb";
}

.fa-discord:before {
    content: "\f392";
}

.fa-discourse:before {
    content: "\f393";
}

.fa-divide:before {
    content: "\f529";
}

.fa-dizzy:before {
    content: "\f567";
}

.fa-dna:before {
    content: "\f471";
}

.fa-dochub:before {
    content: "\f394";
}

.fa-docker:before {
    content: "\f395";
}

.fa-dog:before {
    content: "\f6d3";
}

.fa-dollar-sign:before {
    content: "\f155";
}

.fa-dolly:before {
    content: "\f472";
}

.fa-dolly-flatbed:before {
    content: "\f474";
}

.fa-donate:before {
    content: "\f4b9";
}

.fa-door-closed:before {
    content: "\f52a";
}

.fa-door-open:before {
    content: "\f52b";
}

.fa-dot-circle:before {
    content: "\f192";
}

.fa-dove:before {
    content: "\f4ba";
}

.fa-download:before {
    content: "\f019";
}

.fa-draft2digital:before {
    content: "\f396";
}

.fa-drafting-compass:before {
    content: "\f568";
}

.fa-dragon:before {
    content: "\f6d5";
}

.fa-draw-polygon:before {
    content: "\f5ee";
}

.fa-dribbble:before {
    content: "\f17d";
}

.fa-dribbble-square:before {
    content: "\f397";
}

.fa-dropbox:before {
    content: "\f16b";
}

.fa-drum:before {
    content: "\f569";
}

.fa-drum-steelpan:before {
    content: "\f56a";
}

.fa-drumstick-bite:before {
    content: "\f6d7";
}

.fa-drupal:before {
    content: "\f1a9";
}

.fa-dumbbell:before {
    content: "\f44b";
}

.fa-dumpster:before {
    content: "\f793";
}

.fa-dumpster-fire:before {
    content: "\f794";
}

.fa-dungeon:before {
    content: "\f6d9";
}

.fa-dyalog:before {
    content: "\f399";
}

.fa-earlybirds:before {
    content: "\f39a";
}

.fa-ebay:before {
    content: "\f4f4";
}

.fa-edge:before {
    content: "\f282";
}

.fa-edit:before {
    content: "\f044";
}

.fa-egg:before {
    content: "\f7fb";
}

.fa-eject:before {
    content: "\f052";
}

.fa-elementor:before {
    content: "\f430";
}

.fa-ellipsis-h:before {
    content: "\f141";
}

.fa-ellipsis-v:before {
    content: "\f142";
}

.fa-ello:before {
    content: "\f5f1";
}

.fa-ember:before {
    content: "\f423";
}

.fa-empire:before {
    content: "\f1d1";
}

.fa-envelope:before {
    content: "\f0e0";
}

.fa-envelope-open:before {
    content: "\f2b6";
}

.fa-envelope-open-text:before {
    content: "\f658";
}

.fa-envelope-square:before {
    content: "\f199";
}

.fa-envira:before {
    content: "\f299";
}

.fa-equals:before {
    content: "\f52c";
}

.fa-eraser:before {
    content: "\f12d";
}

.fa-erlang:before {
    content: "\f39d";
}

.fa-ethereum:before {
    content: "\f42e";
}

.fa-ethernet:before {
    content: "\f796";
}

.fa-etsy:before {
    content: "\f2d7";
}

.fa-euro-sign:before {
    content: "\f153";
}

.fa-evernote:before {
    content: "\f839";
}

.fa-exchange-alt:before {
    content: "\f362";
}

.fa-exclamation:before {
    content: "\f12a";
}

.fa-exclamation-circle:before {
    content: "\f06a";
}

.fa-exclamation-triangle:before {
    content: "\f071";
}

.fa-expand:before {
    content: "\f065";
}

.fa-expand-arrows-alt:before {
    content: "\f31e";
}

.fa-expeditedssl:before {
    content: "\f23e";
}

.fa-external-link-alt:before {
    content: "\f35d";
}

.fa-external-link-square-alt:before {
    content: "\f360";
}

.fa-eye:before {
    content: "\f06e";
}

.fa-eye-dropper:before {
    content: "\f1fb";
}

.fa-eye-slash:before {
    content: "\f070";
}

.fa-facebook:before {
    content: "\f09a";
}

.fa-facebook-f:before {
    content: "\f39e";
}

.fa-facebook-messenger:before {
    content: "\f39f";
}

.fa-facebook-square:before {
    content: "\f082";
}

.fa-fantasy-flight-games:before {
    content: "\f6dc";
}

.fa-fast-backward:before {
    content: "\f049";
}

.fa-fast-forward:before {
    content: "\f050";
}

.fa-fax:before {
    content: "\f1ac";
}

.fa-feather:before {
    content: "\f52d";
}

.fa-feather-alt:before {
    content: "\f56b";
}

.fa-fedex:before {
    content: "\f797";
}

.fa-fedora:before {
    content: "\f798";
}

.fa-female:before {
    content: "\f182";
}

.fa-fighter-jet:before {
    content: "\f0fb";
}

.fa-figma:before {
    content: "\f799";
}

.fa-file:before {
    content: "\f15b";
}

.fa-file-alt:before {
    content: "\f15c";
}

.fa-file-archive:before {
    content: "\f1c6";
}

.fa-file-audio:before {
    content: "\f1c7";
}

.fa-file-code:before {
    content: "\f1c9";
}

.fa-file-contract:before {
    content: "\f56c";
}

.fa-file-csv:before {
    content: "\f6dd";
}

.fa-file-download:before {
    content: "\f56d";
}

.fa-file-excel:before {
    content: "\f1c3";
}

.fa-file-export:before {
    content: "\f56e";
}

.fa-file-image:before {
    content: "\f1c5";
}

.fa-file-import:before {
    content: "\f56f";
}

.fa-file-invoice:before {
    content: "\f570";
}

.fa-file-invoice-dollar:before {
    content: "\f571";
}

.fa-file-medical:before {
    content: "\f477";
}

.fa-file-medical-alt:before {
    content: "\f478";
}

.fa-file-pdf:before {
    content: "\f1c1";
}

.fa-file-powerpoint:before {
    content: "\f1c4";
}

.fa-file-prescription:before {
    content: "\f572";
}

.fa-file-signature:before {
    content: "\f573";
}

.fa-file-upload:before {
    content: "\f574";
}

.fa-file-video:before {
    content: "\f1c8";
}

.fa-file-word:before {
    content: "\f1c2";
}

.fa-fill:before {
    content: "\f575";
}

.fa-fill-drip:before {
    content: "\f576";
}

.fa-film:before {
    content: "\f008";
}

.fa-filter:before {
    content: "\f0b0";
}

.fa-fingerprint:before {
    content: "\f577";
}

.fa-fire:before {
    content: "\f06d";
}

.fa-fire-alt:before {
    content: "\f7e4";
}

.fa-fire-extinguisher:before {
    content: "\f134";
}

.fa-firefox:before {
    content: "\f269";
}

.fa-first-aid:before {
    content: "\f479";
}

.fa-first-order:before {
    content: "\f2b0";
}

.fa-first-order-alt:before {
    content: "\f50a";
}

.fa-firstdraft:before {
    content: "\f3a1";
}

.fa-fish:before {
    content: "\f578";
}

.fa-fist-raised:before {
    content: "\f6de";
}

.fa-flag:before {
    content: "\f024";
}

.fa-flag-checkered:before {
    content: "\f11e";
}

.fa-flag-usa:before {
    content: "\f74d";
}

.fa-flask:before {
    content: "\f0c3";
}

.fa-flickr:before {
    content: "\f16e";
}

.fa-flipboard:before {
    content: "\f44d";
}

.fa-flushed:before {
    content: "\f579";
}

.fa-fly:before {
    content: "\f417";
}

.fa-folder:before {
    content: "\f07b";
}

.fa-folder-minus:before {
    content: "\f65d";
}

.fa-folder-open:before {
    content: "\f07c";
}

.fa-folder-plus:before {
    content: "\f65e";
}

.fa-font:before {
    content: "\f031";
}

.fa-font-awesome:before {
    content: "\f2b4";
}

.fa-font-awesome-alt:before {
    content: "\f35c";
}

.fa-font-awesome-flag:before {
    content: "\f425";
}

.fa-font-awesome-logo-full:before {
    content: "\f4e6";
}

.fa-fonticons:before {
    content: "\f280";
}

.fa-fonticons-fi:before {
    content: "\f3a2";
}

.fa-football-ball:before {
    content: "\f44e";
}

.fa-fort-awesome:before {
    content: "\f286";
}

.fa-fort-awesome-alt:before {
    content: "\f3a3";
}

.fa-forumbee:before {
    content: "\f211";
}

.fa-forward:before {
    content: "\f04e";
}

.fa-foursquare:before {
    content: "\f180";
}

.fa-free-code-camp:before {
    content: "\f2c5";
}

.fa-freebsd:before {
    content: "\f3a4";
}

.fa-frog:before {
    content: "\f52e";
}

.fa-frown:before {
    content: "\f119";
}

.fa-frown-open:before {
    content: "\f57a";
}

.fa-fulcrum:before {
    content: "\f50b";
}

.fa-funnel-dollar:before {
    content: "\f662";
}

.fa-futbol:before {
    content: "\f1e3";
}

.fa-galactic-republic:before {
    content: "\f50c";
}

.fa-galactic-senate:before {
    content: "\f50d";
}

.fa-gamepad:before {
    content: "\f11b";
}

.fa-gas-pump:before {
    content: "\f52f";
}

.fa-gavel:before {
    content: "\f0e3";
}

.fa-gem:before {
    content: "\f3a5";
}

.fa-genderless:before {
    content: "\f22d";
}

.fa-get-pocket:before {
    content: "\f265";
}

.fa-gg:before {
    content: "\f260";
}

.fa-gg-circle:before {
    content: "\f261";
}

.fa-ghost:before {
    content: "\f6e2";
}

.fa-gift:before {
    content: "\f06b";
}

.fa-gifts:before {
    content: "\f79c";
}

.fa-git:before {
    content: "\f1d3";
}

.fa-git-alt:before {
    content: "\f841";
}

.fa-git-square:before {
    content: "\f1d2";
}

.fa-github:before {
    content: "\f09b";
}

.fa-github-alt:before {
    content: "\f113";
}

.fa-github-square:before {
    content: "\f092";
}

.fa-gitkraken:before {
    content: "\f3a6";
}

.fa-gitlab:before {
    content: "\f296";
}

.fa-gitter:before {
    content: "\f426";
}

.fa-glass-cheers:before {
    content: "\f79f";
}

.fa-glass-martini:before {
    content: "\f000";
}

.fa-glass-martini-alt:before {
    content: "\f57b";
}

.fa-glass-whiskey:before {
    content: "\f7a0";
}

.fa-glasses:before {
    content: "\f530";
}

.fa-glide:before {
    content: "\f2a5";
}

.fa-glide-g:before {
    content: "\f2a6";
}

.fa-globe:before {
    content: "\f0ac";
}

.fa-globe-africa:before {
    content: "\f57c";
}

.fa-globe-americas:before {
    content: "\f57d";
}

.fa-globe-asia:before {
    content: "\f57e";
}

.fa-globe-europe:before {
    content: "\f7a2";
}

.fa-gofore:before {
    content: "\f3a7";
}

.fa-golf-ball:before {
    content: "\f450";
}

.fa-goodreads:before {
    content: "\f3a8";
}

.fa-goodreads-g:before {
    content: "\f3a9";
}

.fa-google:before {
    content: "\f1a0";
}

.fa-google-drive:before {
    content: "\f3aa";
}

.fa-google-play:before {
    content: "\f3ab";
}

.fa-google-plus:before {
    content: "\f2b3";
}

.fa-google-plus-g:before {
    content: "\f0d5";
}

.fa-google-plus-square:before {
    content: "\f0d4";
}

.fa-google-wallet:before {
    content: "\f1ee";
}

.fa-gopuram:before {
    content: "\f664";
}

.fa-graduation-cap:before {
    content: "\f19d";
}

.fa-gratipay:before {
    content: "\f184";
}

.fa-grav:before {
    content: "\f2d6";
}

.fa-greater-than:before {
    content: "\f531";
}

.fa-greater-than-equal:before {
    content: "\f532";
}

.fa-grimace:before {
    content: "\f57f";
}

.fa-grin:before {
    content: "\f580";
}

.fa-grin-alt:before {
    content: "\f581";
}

.fa-grin-beam:before {
    content: "\f582";
}

.fa-grin-beam-sweat:before {
    content: "\f583";
}

.fa-grin-hearts:before {
    content: "\f584";
}

.fa-grin-squint:before {
    content: "\f585";
}

.fa-grin-squint-tears:before {
    content: "\f586";
}

.fa-grin-stars:before {
    content: "\f587";
}

.fa-grin-tears:before {
    content: "\f588";
}

.fa-grin-tongue:before {
    content: "\f589";
}

.fa-grin-tongue-squint:before {
    content: "\f58a";
}

.fa-grin-tongue-wink:before {
    content: "\f58b";
}

.fa-grin-wink:before {
    content: "\f58c";
}

.fa-grip-horizontal:before {
    content: "\f58d";
}

.fa-grip-lines:before {
    content: "\f7a4";
}

.fa-grip-lines-vertical:before {
    content: "\f7a5";
}

.fa-grip-vertical:before {
    content: "\f58e";
}

.fa-gripfire:before {
    content: "\f3ac";
}

.fa-grunt:before {
    content: "\f3ad";
}

.fa-guitar:before {
    content: "\f7a6";
}

.fa-gulp:before {
    content: "\f3ae";
}

.fa-h-square:before {
    content: "\f0fd";
}

.fa-hacker-news:before {
    content: "\f1d4";
}

.fa-hacker-news-square:before {
    content: "\f3af";
}

.fa-hackerrank:before {
    content: "\f5f7";
}

.fa-hamburger:before {
    content: "\f805";
}

.fa-hammer:before {
    content: "\f6e3";
}

.fa-hamsa:before {
    content: "\f665";
}

.fa-hand-holding:before {
    content: "\f4bd";
}

.fa-hand-holding-heart:before {
    content: "\f4be";
}

.fa-hand-holding-usd:before {
    content: "\f4c0";
}

.fa-hand-lizard:before {
    content: "\f258";
}

.fa-hand-middle-finger:before {
    content: "\f806";
}

.fa-hand-paper:before {
    content: "\f256";
}

.fa-hand-peace:before {
    content: "\f25b";
}

.fa-hand-point-down:before {
    content: "\f0a7";
}

.fa-hand-point-left:before {
    content: "\f0a5";
}

.fa-hand-point-right:before {
    content: "\f0a4";
}

.fa-hand-point-up:before {
    content: "\f0a6";
}

.fa-hand-pointer:before {
    content: "\f25a";
}

.fa-hand-rock:before {
    content: "\f255";
}

.fa-hand-scissors:before {
    content: "\f257";
}

.fa-hand-spock:before {
    content: "\f259";
}

.fa-hands:before {
    content: "\f4c2";
}

.fa-hands-helping:before {
    content: "\f4c4";
}

.fa-handshake:before {
    content: "\f2b5";
}

.fa-hanukiah:before {
    content: "\f6e6";
}

.fa-hard-hat:before {
    content: "\f807";
}

.fa-hashtag:before {
    content: "\f292";
}

.fa-hat-wizard:before {
    content: "\f6e8";
}

.fa-haykal:before {
    content: "\f666";
}

.fa-hdd:before {
    content: "\f0a0";
}

.fa-heading:before {
    content: "\f1dc";
}

.fa-headphones:before {
    content: "\f025";
}

.fa-headphones-alt:before {
    content: "\f58f";
}

.fa-headset:before {
    content: "\f590";
}

.fa-heart:before {
    content: "\f004";
}

.fa-heart-broken:before {
    content: "\f7a9";
}

.fa-heartbeat:before {
    content: "\f21e";
}

.fa-helicopter:before {
    content: "\f533";
}

.fa-highlighter:before {
    content: "\f591";
}

.fa-hiking:before {
    content: "\f6ec";
}

.fa-hippo:before {
    content: "\f6ed";
}

.fa-hips:before {
    content: "\f452";
}

.fa-hire-a-helper:before {
    content: "\f3b0";
}

.fa-history:before {
    content: "\f1da";
}

.fa-hockey-puck:before {
    content: "\f453";
}

.fa-holly-berry:before {
    content: "\f7aa";
}

.fa-home:before {
    content: "\f015";
}

.fa-hooli:before {
    content: "\f427";
}

.fa-hornbill:before {
    content: "\f592";
}

.fa-horse:before {
    content: "\f6f0";
}

.fa-horse-head:before {
    content: "\f7ab";
}

.fa-hospital:before {
    content: "\f0f8";
}

.fa-hospital-alt:before {
    content: "\f47d";
}

.fa-hospital-symbol:before {
    content: "\f47e";
}

.fa-hot-tub:before {
    content: "\f593";
}

.fa-hotdog:before {
    content: "\f80f";
}

.fa-hotel:before {
    content: "\f594";
}

.fa-hotjar:before {
    content: "\f3b1";
}

.fa-hourglass:before {
    content: "\f254";
}

.fa-hourglass-end:before {
    content: "\f253";
}

.fa-hourglass-half:before {
    content: "\f252";
}

.fa-hourglass-start:before {
    content: "\f251";
}

.fa-house-damage:before {
    content: "\f6f1";
}

.fa-houzz:before {
    content: "\f27c";
}

.fa-hryvnia:before {
    content: "\f6f2";
}

.fa-html5:before {
    content: "\f13b";
}

.fa-hubspot:before {
    content: "\f3b2";
}

.fa-i-cursor:before {
    content: "\f246";
}

.fa-ice-cream:before {
    content: "\f810";
}

.fa-icicles:before {
    content: "\f7ad";
}

.fa-id-badge:before {
    content: "\f2c1";
}

.fa-id-card:before {
    content: "\f2c2";
}

.fa-id-card-alt:before {
    content: "\f47f";
}

.fa-igloo:before {
    content: "\f7ae";
}

.fa-image:before {
    content: "\f03e";
}

.fa-images:before {
    content: "\f302";
}

.fa-imdb:before {
    content: "\f2d8";
}

.fa-inbox:before {
    content: "\f01c";
}

.fa-indent:before {
    content: "\f03c";
}

.fa-industry:before {
    content: "\f275";
}

.fa-infinity:before {
    content: "\f534";
}

.fa-info:before {
    content: "\f129";
}

.fa-info-circle:before {
    content: "\f05a";
}

.fa-instagram:before {
    content: "\f16d";
}

.fa-intercom:before {
    content: "\f7af";
}

.fa-internet-explorer:before {
    content: "\f26b";
}

.fa-invision:before {
    content: "\f7b0";
}

.fa-ioxhost:before {
    content: "\f208";
}

.fa-italic:before {
    content: "\f033";
}

.fa-itch-io:before {
    content: "\f83a";
}

.fa-itunes:before {
    content: "\f3b4";
}

.fa-itunes-note:before {
    content: "\f3b5";
}

.fa-java:before {
    content: "\f4e4";
}

.fa-jedi:before {
    content: "\f669";
}

.fa-jedi-order:before {
    content: "\f50e";
}

.fa-jenkins:before {
    content: "\f3b6";
}

.fa-jira:before {
    content: "\f7b1";
}

.fa-joget:before {
    content: "\f3b7";
}

.fa-joint:before {
    content: "\f595";
}

.fa-joomla:before {
    content: "\f1aa";
}

.fa-journal-whills:before {
    content: "\f66a";
}

.fa-js:before {
    content: "\f3b8";
}

.fa-js-square:before {
    content: "\f3b9";
}

.fa-jsfiddle:before {
    content: "\f1cc";
}

.fa-kaaba:before {
    content: "\f66b";
}

.fa-kaggle:before {
    content: "\f5fa";
}

.fa-key:before {
    content: "\f084";
}

.fa-keybase:before {
    content: "\f4f5";
}

.fa-keyboard:before {
    content: "\f11c";
}

.fa-keycdn:before {
    content: "\f3ba";
}

.fa-khanda:before {
    content: "\f66d";
}

.fa-kickstarter:before {
    content: "\f3bb";
}

.fa-kickstarter-k:before {
    content: "\f3bc";
}

.fa-kiss:before {
    content: "\f596";
}

.fa-kiss-beam:before {
    content: "\f597";
}

.fa-kiss-wink-heart:before {
    content: "\f598";
}

.fa-kiwi-bird:before {
    content: "\f535";
}

.fa-korvue:before {
    content: "\f42f";
}

.fa-landmark:before {
    content: "\f66f";
}

.fa-language:before {
    content: "\f1ab";
}

.fa-laptop:before {
    content: "\f109";
}

.fa-laptop-code:before {
    content: "\f5fc";
}

.fa-laptop-medical:before {
    content: "\f812";
}

.fa-laravel:before {
    content: "\f3bd";
}

.fa-lastfm:before {
    content: "\f202";
}

.fa-lastfm-square:before {
    content: "\f203";
}

.fa-laugh:before {
    content: "\f599";
}

.fa-laugh-beam:before {
    content: "\f59a";
}

.fa-laugh-squint:before {
    content: "\f59b";
}

.fa-laugh-wink:before {
    content: "\f59c";
}

.fa-layer-group:before {
    content: "\f5fd";
}

.fa-leaf:before {
    content: "\f06c";
}

.fa-leanpub:before {
    content: "\f212";
}

.fa-lemon:before {
    content: "\f094";
}

.fa-less:before {
    content: "\f41d";
}

.fa-less-than:before {
    content: "\f536";
}

.fa-less-than-equal:before {
    content: "\f537";
}

.fa-level-down-alt:before {
    content: "\f3be";
}

.fa-level-up-alt:before {
    content: "\f3bf";
}

.fa-life-ring:before {
    content: "\f1cd";
}

.fa-lightbulb:before {
    content: "\f0eb";
}

.fa-line:before {
    content: "\f3c0";
}

.fa-link:before {
    content: "\f0c1";
}

.fa-linkedin:before {
    content: "\f08c";
}

.fa-linkedin-in:before {
    content: "\f0e1";
}

.fa-linode:before {
    content: "\f2b8";
}

.fa-linux:before {
    content: "\f17c";
}

.fa-lira-sign:before {
    content: "\f195";
}

.fa-list:before {
    content: "\f03a";
}

.fa-list-alt:before {
    content: "\f022";
}

.fa-list-ol:before {
    content: "\f0cb";
}

.fa-list-ul:before {
    content: "\f0ca";
}

.fa-location-arrow:before {
    content: "\f124";
}

.fa-lock:before {
    content: "\f023";
}

.fa-lock-open:before {
    content: "\f3c1";
}

.fa-long-arrow-alt-down:before {
    content: "\f309";
}

.fa-long-arrow-alt-left:before {
    content: "\f30a";
}

.fa-long-arrow-alt-right:before {
    content: "\f30b";
}

.fa-long-arrow-alt-up:before {
    content: "\f30c";
}

.fa-low-vision:before {
    content: "\f2a8";
}

.fa-luggage-cart:before {
    content: "\f59d";
}

.fa-lyft:before {
    content: "\f3c3";
}

.fa-magento:before {
    content: "\f3c4";
}

.fa-magic:before {
    content: "\f0d0";
}

.fa-magnet:before {
    content: "\f076";
}

.fa-mail-bulk:before {
    content: "\f674";
}

.fa-mailchimp:before {
    content: "\f59e";
}

.fa-male:before {
    content: "\f183";
}

.fa-mandalorian:before {
    content: "\f50f";
}

.fa-map:before {
    content: "\f279";
}

.fa-map-marked:before {
    content: "\f59f";
}

.fa-map-marked-alt:before {
    content: "\f5a0";
}

.fa-map-marker:before {
    content: "\f041";
}

.fa-map-marker-alt:before {
    content: "\f3c5";
}

.fa-map-pin:before {
    content: "\f276";
}

.fa-map-signs:before {
    content: "\f277";
}

.fa-markdown:before {
    content: "\f60f";
}

.fa-marker:before {
    content: "\f5a1";
}

.fa-mars:before {
    content: "\f222";
}

.fa-mars-double:before {
    content: "\f227";
}

.fa-mars-stroke:before {
    content: "\f229";
}

.fa-mars-stroke-h:before {
    content: "\f22b";
}

.fa-mars-stroke-v:before {
    content: "\f22a";
}

.fa-mask:before {
    content: "\f6fa";
}

.fa-mastodon:before {
    content: "\f4f6";
}

.fa-maxcdn:before {
    content: "\f136";
}

.fa-medal:before {
    content: "\f5a2";
}

.fa-medapps:before {
    content: "\f3c6";
}

.fa-medium:before {
    content: "\f23a";
}

.fa-medium-m:before {
    content: "\f3c7";
}

.fa-medkit:before {
    content: "\f0fa";
}

.fa-medrt:before {
    content: "\f3c8";
}

.fa-meetup:before {
    content: "\f2e0";
}

.fa-megaport:before {
    content: "\f5a3";
}

.fa-meh:before {
    content: "\f11a";
}

.fa-meh-blank:before {
    content: "\f5a4";
}

.fa-meh-rolling-eyes:before {
    content: "\f5a5";
}

.fa-memory:before {
    content: "\f538";
}

.fa-mendeley:before {
    content: "\f7b3";
}

.fa-menorah:before {
    content: "\f676";
}

.fa-mercury:before {
    content: "\f223";
}

.fa-meteor:before {
    content: "\f753";
}

.fa-microchip:before {
    content: "\f2db";
}

.fa-microphone:before {
    content: "\f130";
}

.fa-microphone-alt:before {
    content: "\f3c9";
}

.fa-microphone-alt-slash:before {
    content: "\f539";
}

.fa-microphone-slash:before {
    content: "\f131";
}

.fa-microscope:before {
    content: "\f610";
}

.fa-microsoft:before {
    content: "\f3ca";
}

.fa-minus:before {
    content: "\f068";
}

.fa-minus-circle:before {
    content: "\f056";
}

.fa-minus-square:before {
    content: "\f146";
}

.fa-mitten:before {
    content: "\f7b5";
}

.fa-mix:before {
    content: "\f3cb";
}

.fa-mixcloud:before {
    content: "\f289";
}

.fa-mizuni:before {
    content: "\f3cc";
}

.fa-mobile:before {
    content: "\f10b";
}

.fa-mobile-alt:before {
    content: "\f3cd";
}

.fa-modx:before {
    content: "\f285";
}

.fa-monero:before {
    content: "\f3d0";
}

.fa-money-bill:before {
    content: "\f0d6";
}

.fa-money-bill-alt:before {
    content: "\f3d1";
}

.fa-money-bill-wave:before {
    content: "\f53a";
}

.fa-money-bill-wave-alt:before {
    content: "\f53b";
}

.fa-money-check:before {
    content: "\f53c";
}

.fa-money-check-alt:before {
    content: "\f53d";
}

.fa-monument:before {
    content: "\f5a6";
}

.fa-moon:before {
    content: "\f186";
}

.fa-mortar-pestle:before {
    content: "\f5a7";
}

.fa-mosque:before {
    content: "\f678";
}

.fa-motorcycle:before {
    content: "\f21c";
}

.fa-mountain:before {
    content: "\f6fc";
}

.fa-mouse-pointer:before {
    content: "\f245";
}

.fa-mug-hot:before {
    content: "\f7b6";
}

.fa-music:before {
    content: "\f001";
}

.fa-napster:before {
    content: "\f3d2";
}

.fa-neos:before {
    content: "\f612";
}

.fa-network-wired:before {
    content: "\f6ff";
}

.fa-neuter:before {
    content: "\f22c";
}

.fa-newspaper:before {
    content: "\f1ea";
}

.fa-nimblr:before {
    content: "\f5a8";
}

.fa-nintendo-switch:before {
    content: "\f418";
}

.fa-node:before {
    content: "\f419";
}

.fa-node-js:before {
    content: "\f3d3";
}

.fa-not-equal:before {
    content: "\f53e";
}

.fa-notes-medical:before {
    content: "\f481";
}

.fa-npm:before {
    content: "\f3d4";
}

.fa-ns8:before {
    content: "\f3d5";
}

.fa-nutritionix:before {
    content: "\f3d6";
}

.fa-object-group:before {
    content: "\f247";
}

.fa-object-ungroup:before {
    content: "\f248";
}

.fa-odnoklassniki:before {
    content: "\f263";
}

.fa-odnoklassniki-square:before {
    content: "\f264";
}

.fa-oil-can:before {
    content: "\f613";
}

.fa-old-republic:before {
    content: "\f510";
}

.fa-om:before {
    content: "\f679";
}

.fa-opencart:before {
    content: "\f23d";
}

.fa-openid:before {
    content: "\f19b";
}

.fa-opera:before {
    content: "\f26a";
}

.fa-optin-monster:before {
    content: "\f23c";
}

.fa-osi:before {
    content: "\f41a";
}

.fa-otter:before {
    content: "\f700";
}

.fa-outdent:before {
    content: "\f03b";
}

.fa-page4:before {
    content: "\f3d7";
}

.fa-pagelines:before {
    content: "\f18c";
}

.fa-pager:before {
    content: "\f815";
}

.fa-paint-brush:before {
    content: "\f1fc";
}

.fa-paint-roller:before {
    content: "\f5aa";
}

.fa-palette:before {
    content: "\f53f";
}

.fa-palfed:before {
    content: "\f3d8";
}

.fa-pallet:before {
    content: "\f482";
}

.fa-paper-plane:before {
    content: "\f1d8";
}

.fa-paperclip:before {
    content: "\f0c6";
}

.fa-parachute-box:before {
    content: "\f4cd";
}

.fa-paragraph:before {
    content: "\f1dd";
}

.fa-parking:before {
    content: "\f540";
}

.fa-passport:before {
    content: "\f5ab";
}

.fa-pastafarianism:before {
    content: "\f67b";
}

.fa-paste:before {
    content: "\f0ea";
}

.fa-patreon:before {
    content: "\f3d9";
}

.fa-pause:before {
    content: "\f04c";
}

.fa-pause-circle:before {
    content: "\f28b";
}

.fa-paw:before {
    content: "\f1b0";
}

.fa-paypal:before {
    content: "\f1ed";
}

.fa-peace:before {
    content: "\f67c";
}

.fa-pen:before {
    content: "\f304";
}

.fa-pen-alt:before {
    content: "\f305";
}

.fa-pen-fancy:before {
    content: "\f5ac";
}

.fa-pen-nib:before {
    content: "\f5ad";
}

.fa-pen-square:before {
    content: "\f14b";
}

.fa-pencil-alt:before {
    content: "\f303";
}

.fa-pencil-ruler:before {
    content: "\f5ae";
}

.fa-penny-arcade:before {
    content: "\f704";
}

.fa-people-carry:before {
    content: "\f4ce";
}

.fa-pepper-hot:before {
    content: "\f816";
}

.fa-percent:before {
    content: "\f295";
}

.fa-percentage:before {
    content: "\f541";
}

.fa-periscope:before {
    content: "\f3da";
}

.fa-person-booth:before {
    content: "\f756";
}

.fa-phabricator:before {
    content: "\f3db";
}

.fa-phoenix-framework:before {
    content: "\f3dc";
}

.fa-phoenix-squadron:before {
    content: "\f511";
}

.fa-phone:before {
    content: "\f095";
}

.fa-phone-slash:before {
    content: "\f3dd";
}

.fa-phone-square:before {
    content: "\f098";
}

.fa-phone-volume:before {
    content: "\f2a0";
}

.fa-php:before {
    content: "\f457";
}

.fa-pied-piper:before {
    content: "\f2ae";
}

.fa-pied-piper-alt:before {
    content: "\f1a8";
}

.fa-pied-piper-hat:before {
    content: "\f4e5";
}

.fa-pied-piper-pp:before {
    content: "\f1a7";
}

.fa-piggy-bank:before {
    content: "\f4d3";
}

.fa-pills:before {
    content: "\f484";
}

.fa-pinterest:before {
    content: "\f0d2";
}

.fa-pinterest-p:before {
    content: "\f231";
}

.fa-pinterest-square:before {
    content: "\f0d3";
}

.fa-pizza-slice:before {
    content: "\f818";
}

.fa-place-of-worship:before {
    content: "\f67f";
}

.fa-plane:before {
    content: "\f072";
}

.fa-plane-arrival:before {
    content: "\f5af";
}

.fa-plane-departure:before {
    content: "\f5b0";
}

.fa-play:before {
    content: "\f04b";
}

.fa-play-circle:before {
    content: "\f144";
}

.fa-playstation:before {
    content: "\f3df";
}

.fa-plug:before {
    content: "\f1e6";
}

.fa-plus:before {
    content: "\f067";
}

.fa-plus-circle:before {
    content: "\f055";
}

.fa-plus-square:before {
    content: "\f0fe";
}

.fa-podcast:before {
    content: "\f2ce";
}

.fa-poll:before {
    content: "\f681";
}

.fa-poll-h:before {
    content: "\f682";
}

.fa-poo:before {
    content: "\f2fe";
}

.fa-poo-storm:before {
    content: "\f75a";
}

.fa-poop:before {
    content: "\f619";
}

.fa-portrait:before {
    content: "\f3e0";
}

.fa-pound-sign:before {
    content: "\f154";
}

.fa-power-off:before {
    content: "\f011";
}

.fa-pray:before {
    content: "\f683";
}

.fa-praying-hands:before {
    content: "\f684";
}

.fa-prescription:before {
    content: "\f5b1";
}

.fa-prescription-bottle:before {
    content: "\f485";
}

.fa-prescription-bottle-alt:before {
    content: "\f486";
}

.fa-print:before {
    content: "\f02f";
}

.fa-procedures:before {
    content: "\f487";
}

.fa-product-hunt:before {
    content: "\f288";
}

.fa-project-diagram:before {
    content: "\f542";
}

.fa-pushed:before {
    content: "\f3e1";
}

.fa-puzzle-piece:before {
    content: "\f12e";
}

.fa-python:before {
    content: "\f3e2";
}

.fa-qq:before {
    content: "\f1d6";
}

.fa-qrcode:before {
    content: "\f029";
}

.fa-question:before {
    content: "\f128";
}

.fa-question-circle:before {
    content: "\f059";
}

.fa-quidditch:before {
    content: "\f458";
}

.fa-quinscape:before {
    content: "\f459";
}

.fa-quora:before {
    content: "\f2c4";
}

.fa-quote-left:before {
    content: "\f10d";
}

.fa-quote-right:before {
    content: "\f10e";
}

.fa-quran:before {
    content: "\f687";
}

.fa-r-project:before {
    content: "\f4f7";
}

.fa-radiation:before {
    content: "\f7b9";
}

.fa-radiation-alt:before {
    content: "\f7ba";
}

.fa-rainbow:before {
    content: "\f75b";
}

.fa-random:before {
    content: "\f074";
}

.fa-raspberry-pi:before {
    content: "\f7bb";
}

.fa-ravelry:before {
    content: "\f2d9";
}

.fa-react:before {
    content: "\f41b";
}

.fa-reacteurope:before {
    content: "\f75d";
}

.fa-readme:before {
    content: "\f4d5";
}

.fa-rebel:before {
    content: "\f1d0";
}

.fa-receipt:before {
    content: "\f543";
}

.fa-recycle:before {
    content: "\f1b8";
}

.fa-red-river:before {
    content: "\f3e3";
}

.fa-reddit:before {
    content: "\f1a1";
}

.fa-reddit-alien:before {
    content: "\f281";
}

.fa-reddit-square:before {
    content: "\f1a2";
}

.fa-redhat:before {
    content: "\f7bc";
}

.fa-redo:before {
    content: "\f01e";
}

.fa-redo-alt:before {
    content: "\f2f9";
}

.fa-registered:before {
    content: "\f25d";
}

.fa-renren:before {
    content: "\f18b";
}

.fa-reply:before {
    content: "\f3e5";
}

.fa-reply-all:before {
    content: "\f122";
}

.fa-replyd:before {
    content: "\f3e6";
}

.fa-republican:before {
    content: "\f75e";
}

.fa-researchgate:before {
    content: "\f4f8";
}

.fa-resolving:before {
    content: "\f3e7";
}

.fa-restroom:before {
    content: "\f7bd";
}

.fa-retweet:before {
    content: "\f079";
}

.fa-rev:before {
    content: "\f5b2";
}

.fa-ribbon:before {
    content: "\f4d6";
}

.fa-ring:before {
    content: "\f70b";
}

.fa-road:before {
    content: "\f018";
}

.fa-robot:before {
    content: "\f544";
}

.fa-rocket:before {
    content: "\f135";
}

.fa-rocketchat:before {
    content: "\f3e8";
}

.fa-rockrms:before {
    content: "\f3e9";
}

.fa-route:before {
    content: "\f4d7";
}

.fa-rss:before {
    content: "\f09e";
}

.fa-rss-square:before {
    content: "\f143";
}

.fa-ruble-sign:before {
    content: "\f158";
}

.fa-ruler:before {
    content: "\f545";
}

.fa-ruler-combined:before {
    content: "\f546";
}

.fa-ruler-horizontal:before {
    content: "\f547";
}

.fa-ruler-vertical:before {
    content: "\f548";
}

.fa-running:before {
    content: "\f70c";
}

.fa-rupee-sign:before {
    content: "\f156";
}

.fa-sad-cry:before {
    content: "\f5b3";
}

.fa-sad-tear:before {
    content: "\f5b4";
}

.fa-safari:before {
    content: "\f267";
}

.fa-salesforce:before {
    content: "\f83b";
}

.fa-sass:before {
    content: "\f41e";
}

.fa-satellite:before {
    content: "\f7bf";
}

.fa-satellite-dish:before {
    content: "\f7c0";
}

.fa-save:before {
    content: "\f0c7";
}

.fa-schlix:before {
    content: "\f3ea";
}

.fa-school:before {
    content: "\f549";
}

.fa-screwdriver:before {
    content: "\f54a";
}

.fa-scribd:before {
    content: "\f28a";
}

.fa-scroll:before {
    content: "\f70e";
}

.fa-sd-card:before {
    content: "\f7c2";
}

.fa-search:before {
    content: "\f002";
}

.fa-search-dollar:before {
    content: "\f688";
}

.fa-search-location:before {
    content: "\f689";
}

.fa-search-minus:before {
    content: "\f010";
}

.fa-search-plus:before {
    content: "\f00e";
}

.fa-searchengin:before {
    content: "\f3eb";
}

.fa-seedling:before {
    content: "\f4d8";
}

.fa-sellcast:before {
    content: "\f2da";
}

.fa-sellsy:before {
    content: "\f213";
}

.fa-server:before {
    content: "\f233";
}

.fa-servicestack:before {
    content: "\f3ec";
}

.fa-shapes:before {
    content: "\f61f";
}

.fa-share:before {
    content: "\f064";
}

.fa-share-alt:before {
    content: "\f1e0";
}

.fa-share-alt-square:before {
    content: "\f1e1";
}

.fa-share-square:before {
    content: "\f14d";
}

.fa-shekel-sign:before {
    content: "\f20b";
}

.fa-shield-alt:before {
    content: "\f3ed";
}

.fa-ship:before {
    content: "\f21a";
}

.fa-shipping-fast:before {
    content: "\f48b";
}

.fa-shirtsinbulk:before {
    content: "\f214";
}

.fa-shoe-prints:before {
    content: "\f54b";
}

.fa-shopping-bag:before {
    content: "\f290";
}

.fa-shopping-basket:before {
    content: "\f291";
}

.fa-shopping-cart:before {
    content: "\f07a";
}

.fa-shopware:before {
    content: "\f5b5";
}

.fa-shower:before {
    content: "\f2cc";
}

.fa-shuttle-van:before {
    content: "\f5b6";
}

.fa-sign:before {
    content: "\f4d9";
}

.fa-sign-in-alt:before {
    content: "\f2f6";
}

.fa-sign-language:before {
    content: "\f2a7";
}

.fa-sign-out-alt:before {
    content: "\f2f5";
}

.fa-signal:before {
    content: "\f012";
}

.fa-signature:before {
    content: "\f5b7";
}

.fa-sim-card:before {
    content: "\f7c4";
}

.fa-simplybuilt:before {
    content: "\f215";
}

.fa-sistrix:before {
    content: "\f3ee";
}

.fa-sitemap:before {
    content: "\f0e8";
}

.fa-sith:before {
    content: "\f512";
}

.fa-skating:before {
    content: "\f7c5";
}

.fa-sketch:before {
    content: "\f7c6";
}

.fa-skiing:before {
    content: "\f7c9";
}

.fa-skiing-nordic:before {
    content: "\f7ca";
}

.fa-skull:before {
    content: "\f54c";
}

.fa-skull-crossbones:before {
    content: "\f714";
}

.fa-skyatlas:before {
    content: "\f216";
}

.fa-skype:before {
    content: "\f17e";
}

.fa-slack:before {
    content: "\f198";
}

.fa-slack-hash:before {
    content: "\f3ef";
}

.fa-slash:before {
    content: "\f715";
}

.fa-sleigh:before {
    content: "\f7cc";
}

.fa-sliders-h:before {
    content: "\f1de";
}

.fa-slideshare:before {
    content: "\f1e7";
}

.fa-smile:before {
    content: "\f118";
}

.fa-smile-beam:before {
    content: "\f5b8";
}

.fa-smile-wink:before {
    content: "\f4da";
}

.fa-smog:before {
    content: "\f75f";
}

.fa-smoking:before {
    content: "\f48d";
}

.fa-smoking-ban:before {
    content: "\f54d";
}

.fa-sms:before {
    content: "\f7cd";
}

.fa-snapchat:before {
    content: "\f2ab";
}

.fa-snapchat-ghost:before {
    content: "\f2ac";
}

.fa-snapchat-square:before {
    content: "\f2ad";
}

.fa-snowboarding:before {
    content: "\f7ce";
}

.fa-snowflake:before {
    content: "\f2dc";
}

.fa-snowman:before {
    content: "\f7d0";
}

.fa-snowplow:before {
    content: "\f7d2";
}

.fa-socks:before {
    content: "\f696";
}

.fa-solar-panel:before {
    content: "\f5ba";
}

.fa-sort:before {
    content: "\f0dc";
}

.fa-sort-alpha-down:before {
    content: "\f15d";
}

.fa-sort-alpha-up:before {
    content: "\f15e";
}

.fa-sort-amount-down:before {
    content: "\f160";
}

.fa-sort-amount-up:before {
    content: "\f161";
}

.fa-sort-down:before {
    content: "\f0dd";
}

.fa-sort-numeric-down:before {
    content: "\f162";
}

.fa-sort-numeric-up:before {
    content: "\f163";
}

.fa-sort-up:before {
    content: "\f0de";
}

.fa-soundcloud:before {
    content: "\f1be";
}

.fa-sourcetree:before {
    content: "\f7d3";
}

.fa-spa:before {
    content: "\f5bb";
}

.fa-space-shuttle:before {
    content: "\f197";
}

.fa-speakap:before {
    content: "\f3f3";
}

.fa-speaker-deck:before {
    content: "\f83c";
}

.fa-spider:before {
    content: "\f717";
}

.fa-spinner:before {
    content: "\f110";
}

.fa-splotch:before {
    content: "\f5bc";
}

.fa-spotify:before {
    content: "\f1bc";
}

.fa-spray-can:before {
    content: "\f5bd";
}

.fa-square:before {
    content: "\f0c8";
}

.fa-square-full:before {
    content: "\f45c";
}

.fa-square-root-alt:before {
    content: "\f698";
}

.fa-squarespace:before {
    content: "\f5be";
}

.fa-stack-exchange:before {
    content: "\f18d";
}

.fa-stack-overflow:before {
    content: "\f16c";
}

.fa-stackpath:before {
    content: "\f842";
}

.fa-stamp:before {
    content: "\f5bf";
}

.fa-star:before {
    content: "\f005";
}

.fa-star-and-crescent:before {
    content: "\f699";
}

.fa-star-half:before {
    content: "\f089";
}

.fa-star-half-alt:before {
    content: "\f5c0";
}

.fa-star-of-david:before {
    content: "\f69a";
}

.fa-star-of-life:before {
    content: "\f621";
}

.fa-staylinked:before {
    content: "\f3f5";
}

.fa-steam:before {
    content: "\f1b6";
}

.fa-steam-square:before {
    content: "\f1b7";
}

.fa-steam-symbol:before {
    content: "\f3f6";
}

.fa-step-backward:before {
    content: "\f048";
}

.fa-step-forward:before {
    content: "\f051";
}

.fa-stethoscope:before {
    content: "\f0f1";
}

.fa-sticker-mule:before {
    content: "\f3f7";
}

.fa-sticky-note:before {
    content: "\f249";
}

.fa-stop:before {
    content: "\f04d";
}

.fa-stop-circle:before {
    content: "\f28d";
}

.fa-stopwatch:before {
    content: "\f2f2";
}

.fa-store:before {
    content: "\f54e";
}

.fa-store-alt:before {
    content: "\f54f";
}

.fa-strava:before {
    content: "\f428";
}

.fa-stream:before {
    content: "\f550";
}

.fa-street-view:before {
    content: "\f21d";
}

.fa-strikethrough:before {
    content: "\f0cc";
}

.fa-stripe:before {
    content: "\f429";
}

.fa-stripe-s:before {
    content: "\f42a";
}

.fa-stroopwafel:before {
    content: "\f551";
}

.fa-studiovinari:before {
    content: "\f3f8";
}

.fa-stumbleupon:before {
    content: "\f1a4";
}

.fa-stumbleupon-circle:before {
    content: "\f1a3";
}

.fa-subscript:before {
    content: "\f12c";
}

.fa-subway:before {
    content: "\f239";
}

.fa-suitcase:before {
    content: "\f0f2";
}

.fa-suitcase-rolling:before {
    content: "\f5c1";
}

.fa-sun:before {
    content: "\f185";
}

.fa-superpowers:before {
    content: "\f2dd";
}

.fa-superscript:before {
    content: "\f12b";
}

.fa-supple:before {
    content: "\f3f9";
}

.fa-surprise:before {
    content: "\f5c2";
}

.fa-suse:before {
    content: "\f7d6";
}

.fa-swatchbook:before {
    content: "\f5c3";
}

.fa-swimmer:before {
    content: "\f5c4";
}

.fa-swimming-pool:before {
    content: "\f5c5";
}

.fa-symfony:before {
    content: "\f83d";
}

.fa-synagogue:before {
    content: "\f69b";
}

.fa-sync:before {
    content: "\f021";
}

.fa-sync-alt:before {
    content: "\f2f1";
}

.fa-syringe:before {
    content: "\f48e";
}

.fa-table:before {
    content: "\f0ce";
}

.fa-table-tennis:before {
    content: "\f45d";
}

.fa-tablet:before {
    content: "\f10a";
}

.fa-tablet-alt:before {
    content: "\f3fa";
}

.fa-tablets:before {
    content: "\f490";
}

.fa-tachometer-alt:before {
    content: "\f3fd";
}

.fa-tag:before {
    content: "\f02b";
}

.fa-tags:before {
    content: "\f02c";
}

.fa-tape:before {
    content: "\f4db";
}

.fa-tasks:before {
    content: "\f0ae";
}

.fa-taxi:before {
    content: "\f1ba";
}

.fa-teamspeak:before {
    content: "\f4f9";
}

.fa-teeth:before {
    content: "\f62e";
}

.fa-teeth-open:before {
    content: "\f62f";
}

.fa-telegram:before {
    content: "\f2c6";
}

.fa-telegram-plane:before {
    content: "\f3fe";
}

.fa-temperature-high:before {
    content: "\f769";
}

.fa-temperature-low:before {
    content: "\f76b";
}

.fa-tencent-weibo:before {
    content: "\f1d5";
}

.fa-tenge:before {
    content: "\f7d7";
}

.fa-terminal:before {
    content: "\f120";
}

.fa-text-height:before {
    content: "\f034";
}

.fa-text-width:before {
    content: "\f035";
}

.fa-th:before {
    content: "\f00a";
}

.fa-th-large:before {
    content: "\f009";
}

.fa-th-list:before {
    content: "\f00b";
}

.fa-the-red-yeti:before {
    content: "\f69d";
}

.fa-theater-masks:before {
    content: "\f630";
}

.fa-themeco:before {
    content: "\f5c6";
}

.fa-themeisle:before {
    content: "\f2b2";
}

.fa-thermometer:before {
    content: "\f491";
}

.fa-thermometer-empty:before {
    content: "\f2cb";
}

.fa-thermometer-full:before {
    content: "\f2c7";
}

.fa-thermometer-half:before {
    content: "\f2c9";
}

.fa-thermometer-quarter:before {
    content: "\f2ca";
}

.fa-thermometer-three-quarters:before {
    content: "\f2c8";
}

.fa-think-peaks:before {
    content: "\f731";
}

.fa-thumbs-down:before {
    content: "\f165";
}

.fa-thumbs-up:before {
    content: "\f164";
}

.fa-thumbtack:before {
    content: "\f08d";
}

.fa-ticket-alt:before {
    content: "\f3ff";
}

.fa-times:before {
    content: "\f00d";
}

.fa-times-circle:before {
    content: "\f057";
}

.fa-tint:before {
    content: "\f043";
}

.fa-tint-slash:before {
    content: "\f5c7";
}

.fa-tired:before {
    content: "\f5c8";
}

.fa-toggle-off:before {
    content: "\f204";
}

.fa-toggle-on:before {
    content: "\f205";
}

.fa-toilet:before {
    content: "\f7d8";
}

.fa-toilet-paper:before {
    content: "\f71e";
}

.fa-toolbox:before {
    content: "\f552";
}

.fa-tools:before {
    content: "\f7d9";
}

.fa-tooth:before {
    content: "\f5c9";
}

.fa-torah:before {
    content: "\f6a0";
}

.fa-torii-gate:before {
    content: "\f6a1";
}

.fa-tractor:before {
    content: "\f722";
}

.fa-trade-federation:before {
    content: "\f513";
}

.fa-trademark:before {
    content: "\f25c";
}

.fa-traffic-light:before {
    content: "\f637";
}

.fa-train:before {
    content: "\f238";
}

.fa-tram:before {
    content: "\f7da";
}

.fa-transgender:before {
    content: "\f224";
}

.fa-transgender-alt:before {
    content: "\f225";
}

.fa-trash:before {
    content: "\f1f8";
}

.fa-trash-alt:before {
    content: "\f2ed";
}

.fa-trash-restore:before {
    content: "\f829";
}

.fa-trash-restore-alt:before {
    content: "\f82a";
}

.fa-tree:before {
    content: "\f1bb";
}

.fa-trello:before {
    content: "\f181";
}

.fa-tripadvisor:before {
    content: "\f262";
}

.fa-trophy:before {
    content: "\f091";
}

.fa-truck:before {
    content: "\f0d1";
}

.fa-truck-loading:before {
    content: "\f4de";
}

.fa-truck-monster:before {
    content: "\f63b";
}

.fa-truck-moving:before {
    content: "\f4df";
}

.fa-truck-pickup:before {
    content: "\f63c";
}

.fa-tshirt:before {
    content: "\f553";
}

.fa-tty:before {
    content: "\f1e4";
}

.fa-tumblr:before {
    content: "\f173";
}

.fa-tumblr-square:before {
    content: "\f174";
}

.fa-tv:before {
    content: "\f26c";
}

.fa-twitch:before {
    content: "\f1e8";
}

.fa-twitter:before {
    content: "\f099";
}

.fa-twitter-square:before {
    content: "\f081";
}

.fa-typo3:before {
    content: "\f42b";
}

.fa-uber:before {
    content: "\f402";
}

.fa-ubuntu:before {
    content: "\f7df";
}

.fa-uikit:before {
    content: "\f403";
}

.fa-umbrella:before {
    content: "\f0e9";
}

.fa-umbrella-beach:before {
    content: "\f5ca";
}

.fa-underline:before {
    content: "\f0cd";
}

.fa-undo:before {
    content: "\f0e2";
}

.fa-undo-alt:before {
    content: "\f2ea";
}

.fa-uniregistry:before {
    content: "\f404";
}

.fa-universal-access:before {
    content: "\f29a";
}

.fa-university:before {
    content: "\f19c";
}

.fa-unlink:before {
    content: "\f127";
}

.fa-unlock:before {
    content: "\f09c";
}

.fa-unlock-alt:before {
    content: "\f13e";
}

.fa-untappd:before {
    content: "\f405";
}

.fa-upload:before {
    content: "\f093";
}

.fa-ups:before {
    content: "\f7e0";
}

.fa-usb:before {
    content: "\f287";
}

.fa-user:before {
    content: "\f007";
}

.fa-user-alt:before {
    content: "\f406";
}

.fa-user-alt-slash:before {
    content: "\f4fa";
}

.fa-user-astronaut:before {
    content: "\f4fb";
}

.fa-user-check:before {
    content: "\f4fc";
}

.fa-user-circle:before {
    content: "\f2bd";
}

.fa-user-clock:before {
    content: "\f4fd";
}

.fa-user-cog:before {
    content: "\f4fe";
}

.fa-user-edit:before {
    content: "\f4ff";
}

.fa-user-friends:before {
    content: "\f500";
}

.fa-user-graduate:before {
    content: "\f501";
}

.fa-user-injured:before {
    content: "\f728";
}

.fa-user-lock:before {
    content: "\f502";
}

.fa-user-md:before {
    content: "\f0f0";
}

.fa-user-minus:before {
    content: "\f503";
}

.fa-user-ninja:before {
    content: "\f504";
}

.fa-user-nurse:before {
    content: "\f82f";
}

.fa-user-plus:before {
    content: "\f234";
}

.fa-user-secret:before {
    content: "\f21b";
}

.fa-user-shield:before {
    content: "\f505";
}

.fa-user-slash:before {
    content: "\f506";
}

.fa-user-tag:before {
    content: "\f507";
}

.fa-user-tie:before {
    content: "\f508";
}

.fa-user-times:before {
    content: "\f235";
}

.fa-users:before {
    content: "\f0c0";
}

.fa-users-cog:before {
    content: "\f509";
}

.fa-usps:before {
    content: "\f7e1";
}

.fa-ussunnah:before {
    content: "\f407";
}

.fa-utensil-spoon:before {
    content: "\f2e5";
}

.fa-utensils:before {
    content: "\f2e7";
}

.fa-vaadin:before {
    content: "\f408";
}

.fa-vector-square:before {
    content: "\f5cb";
}

.fa-venus:before {
    content: "\f221";
}

.fa-venus-double:before {
    content: "\f226";
}

.fa-venus-mars:before {
    content: "\f228";
}

.fa-viacoin:before {
    content: "\f237";
}

.fa-viadeo:before {
    content: "\f2a9";
}

.fa-viadeo-square:before {
    content: "\f2aa";
}

.fa-vial:before {
    content: "\f492";
}

.fa-vials:before {
    content: "\f493";
}

.fa-viber:before {
    content: "\f409";
}

.fa-video:before {
    content: "\f03d";
}

.fa-video-slash:before {
    content: "\f4e2";
}

.fa-vihara:before {
    content: "\f6a7";
}

.fa-vimeo:before {
    content: "\f40a";
}

.fa-vimeo-square:before {
    content: "\f194";
}

.fa-vimeo-v:before {
    content: "\f27d";
}

.fa-vine:before {
    content: "\f1ca";
}

.fa-vk:before {
    content: "\f189";
}

.fa-vnv:before {
    content: "\f40b";
}

.fa-volleyball-ball:before {
    content: "\f45f";
}

.fa-volume-down:before {
    content: "\f027";
}

.fa-volume-mute:before {
    content: "\f6a9";
}

.fa-volume-off:before {
    content: "\f026";
}

.fa-volume-up:before {
    content: "\f028";
}

.fa-vote-yea:before {
    content: "\f772";
}

.fa-vr-cardboard:before {
    content: "\f729";
}

.fa-vuejs:before {
    content: "\f41f";
}

.fa-walking:before {
    content: "\f554";
}

.fa-wallet:before {
    content: "\f555";
}

.fa-warehouse:before {
    content: "\f494";
}

.fa-water:before {
    content: "\f773";
}

.fa-wave-square:before {
    content: "\f83e";
}

.fa-waze:before {
    content: "\f83f";
}

.fa-weebly:before {
    content: "\f5cc";
}

.fa-weibo:before {
    content: "\f18a";
}

.fa-weight:before {
    content: "\f496";
}

.fa-weight-hanging:before {
    content: "\f5cd";
}

.fa-weixin:before {
    content: "\f1d7";
}

.fa-whatsapp:before {
    content: "\f232";
}

.fa-whatsapp-square:before {
    content: "\f40c";
}

.fa-wheelchair:before {
    content: "\f193";
}

.fa-whmcs:before {
    content: "\f40d";
}

.fa-wifi:before {
    content: "\f1eb";
}

.fa-wikipedia-w:before {
    content: "\f266";
}

.fa-wind:before {
    content: "\f72e";
}

.fa-window-close:before {
    content: "\f410";
}

.fa-window-maximize:before {
    content: "\f2d0";
}

.fa-window-minimize:before {
    content: "\f2d1";
}

.fa-window-restore:before {
    content: "\f2d2";
}

.fa-windows:before {
    content: "\f17a";
}

.fa-wine-bottle:before {
    content: "\f72f";
}

.fa-wine-glass:before {
    content: "\f4e3";
}

.fa-wine-glass-alt:before {
    content: "\f5ce";
}

.fa-wix:before {
    content: "\f5cf";
}

.fa-wizards-of-the-coast:before {
    content: "\f730";
}

.fa-wolf-pack-battalion:before {
    content: "\f514";
}

.fa-won-sign:before {
    content: "\f159";
}

.fa-wordpress:before {
    content: "\f19a";
}

.fa-wordpress-simple:before {
    content: "\f411";
}

.fa-wpbeginner:before {
    content: "\f297";
}

.fa-wpexplorer:before {
    content: "\f2de";
}

.fa-wpforms:before {
    content: "\f298";
}

.fa-wpressr:before {
    content: "\f3e4";
}

.fa-wrench:before {
    content: "\f0ad";
}

.fa-x-ray:before {
    content: "\f497";
}

.fa-xbox:before {
    content: "\f412";
}

.fa-xing:before {
    content: "\f168";
}

.fa-xing-square:before {
    content: "\f169";
}

.fa-y-combinator:before {
    content: "\f23b";
}

.fa-yahoo:before {
    content: "\f19e";
}

.fa-yammer:before {
    content: "\f840";
}

.fa-yandex:before {
    content: "\f413";
}

.fa-yandex-international:before {
    content: "\f414";
}

.fa-yarn:before {
    content: "\f7e3";
}

.fa-yelp:before {
    content: "\f1e9";
}

.fa-yen-sign:before {
    content: "\f157";
}

.fa-yin-yang:before {
    content: "\f6ad";
}

.fa-yoast:before {
    content: "\f2b1";
}

.fa-youtube:before {
    content: "\f167";
}

.fa-youtube-square:before {
    content: "\f431";
}

.fa-zhihu:before {
    content: "\f63f";
}

.sr-only {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
}

.fa,
.fas {
    font-weight: 900;
}

.far {
    font-weight: 400;
}

.material-icons {
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-feature-settings: "liga";
    font-feature-settings: "liga";
}

@keyframes g {
    0% {
        transform: scale(0.7);
    }

    45% {
        transform: scale(1.05);
    }

    80% {
        transform: scale(0.95);
    }

    to {
        transform: scale(1);
    }
}

@keyframes h {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    to {
        transform: scale(0.5);
        opacity: 0;
    }
}

@keyframes i {
    0% {
        top: 1.1875em;
        left: 0.0625em;
        width: 0;
    }

    54% {
        top: 1.0625em;
        left: 0.125em;
        width: 0;
    }

    70% {
        top: 2.1875em;
        left: -0.375em;
        width: 3.125em;
    }

    84% {
        top: 3em;
        left: 1.3125em;
        width: 1.0625em;
    }

    to {
        top: 2.8125em;
        left: 0.875em;
        width: 1.5625em;
    }
}

@keyframes j {
    0% {
        top: 3.375em;
        right: 2.875em;
        width: 0;
    }

    65% {
        top: 3.375em;
        right: 2.875em;
        width: 0;
    }

    84% {
        top: 2.1875em;
        right: 0;
        width: 3.4375em;
    }

    to {
        top: 2.375em;
        right: 0.5em;
        width: 2.9375em;
    }
}

@keyframes k {
    0% {
        transform: rotate(-45deg);
    }

    5% {
        transform: rotate(-45deg);
    }

    12% {
        transform: rotate(-405deg);
    }

    to {
        transform: rotate(-405deg);
    }
}

@keyframes l {
    0% {
        margin-top: 1.625em;
        transform: scale(0.4);
        opacity: 0;
    }

    50% {
        margin-top: 1.625em;
        transform: scale(0.4);
        opacity: 0;
    }

    80% {
        margin-top: -0.375em;
        transform: scale(1.15);
    }

    to {
        margin-top: 0;
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes m {
    0% {
        transform: rotateX(100deg);
        opacity: 0;
    }

    to {
        transform: rotateX(0deg);
        opacity: 1;
    }
}

body.swal2-toast-shown .swal2-container,
body.swal2-toast-shown .swal2-container.swal2-shown {
    background-color: transparent;
}

body.swal2-toast-shown .swal2-container.swal2-top {
    top: 0;
    right: auto;
    bottom: auto;
    left: 50%;
    transform: translateX(-50%);
}

body.swal2-toast-shown .swal2-container.swal2-top-end,
body.swal2-toast-shown .swal2-container.swal2-top-right {
    top: 0;
    right: 0;
    bottom: auto;
    left: auto;
}

body.swal2-toast-shown .swal2-container.swal2-top-left,
body.swal2-toast-shown .swal2-container.swal2-top-start {
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
}

body.swal2-toast-shown .swal2-container.swal2-center-left,
body.swal2-toast-shown .swal2-container.swal2-center-start {
    top: 50%;
    right: auto;
    bottom: auto;
    left: 0;
    transform: translateY(-50%);
}

body.swal2-toast-shown .swal2-container.swal2-center {
    top: 50%;
    right: auto;
    bottom: auto;
    left: 50%;
    transform: translate(-50%, -50%);
}

body.swal2-toast-shown .swal2-container.swal2-center-end,
body.swal2-toast-shown .swal2-container.swal2-center-right {
    top: 50%;
    right: 0;
    bottom: auto;
    left: auto;
    transform: translateY(-50%);
}

body.swal2-toast-shown .swal2-container.swal2-bottom-left,
body.swal2-toast-shown .swal2-container.swal2-bottom-start {
    top: auto;
    right: auto;
    bottom: 0;
    left: 0;
}

body.swal2-toast-shown .swal2-container.swal2-bottom {
    top: auto;
    right: auto;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}

body.swal2-toast-shown .swal2-container.swal2-bottom-end,
body.swal2-toast-shown .swal2-container.swal2-bottom-right {
    top: auto;
    right: 0;
    bottom: 0;
    left: auto;
}

body.swal2-toast-column .swal2-toast {
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-align: stretch;
    align-items: stretch;
}

body.swal2-toast-column .swal2-toast .swal2-actions {
    -ms-flex: 1;
    flex: 1;
    -ms-flex-item-align: stretch;
    align-self: stretch;
    height: 2.2em;
    margin-top: 0.3125em;
}

body.swal2-toast-column .swal2-toast .swal2-loading {
    -ms-flex-pack: center;
    justify-content: center;
}

body.swal2-toast-column .swal2-toast .swal2-input {
    height: 2em;
    margin: 0.3125em auto;
    font-size: 1em;
}

body.swal2-toast-column .swal2-toast .swal2-validation-message {
    font-size: 1em;
}

.swal2-popup.swal2-toast {
    -ms-flex-align: center;
    align-items: center;
    width: auto;
    padding: 0.625em;
    overflow-y: hidden;
    box-shadow: 0 0 0.625em #d9d9d9;
}

.swal2-popup.swal2-toast,
.swal2-popup.swal2-toast .swal2-header {
    -ms-flex-direction: row;
    flex-direction: row;
}

.swal2-popup.swal2-toast .swal2-title {
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-pack: start;
    justify-content: flex-start;
    margin: 0 0.6em;
    font-size: 1em;
}

.swal2-popup.swal2-toast .swal2-footer {
    margin: 0.5em 0 0;
    padding: 0.5em 0 0;
    font-size: 0.8em;
}

.swal2-popup.swal2-toast .swal2-close {
    position: static;
    width: 0.8em;
    height: 0.8em;
    line-height: 0.8;
}

.swal2-popup.swal2-toast .swal2-content {
    -ms-flex-pack: start;
    justify-content: flex-start;
    font-size: 1em;
}

.swal2-popup.swal2-toast .swal2-icon {
    width: 2em;
    min-width: 2em;
    height: 2em;
    margin: 0;
}

.swal2-popup.swal2-toast .swal2-icon:before {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    font-size: 2em;
    font-weight: bold;
}

.swal2-popup.swal2-toast .swal2-icon.swal2-success .swal2-success-ring {
    width: 2em;
    height: 2em;
}

.swal2-popup.swal2-toast .swal2-icon.swal2-error [class^="swal2-x-mark-line"] {
    top: 0.875em;
    width: 1.375em;
}

.swal2-popup.swal2-toast .swal2-icon.swal2-error [class^="swal2-x-mark-line"][class$="left"] {
    left: 0.3125em;
}

.swal2-popup.swal2-toast .swal2-icon.swal2-error [class^="swal2-x-mark-line"][class$="right"] {
    right: 0.3125em;
}

.swal2-popup.swal2-toast .swal2-actions {
    -ms-flex-preferred-size: auto !important;
    flex-basis: auto !important;
    height: auto;
    margin: 0 0.3125em;
}

.swal2-popup.swal2-toast .swal2-styled {
    margin: 0 0.3125em;
    padding: 0.3125em 0.625em;
    font-size: 1em;
}

.swal2-popup.swal2-toast .swal2-styled:focus {
    box-shadow: 0 0 0 0.0625em #fff, 0 0 0 0.125em rgba(50, 100, 150, 0.4);
}

.swal2-popup.swal2-toast .swal2-success {
    border-color: #a5dc86;
}

.swal2-popup.swal2-toast .swal2-success [class^="swal2-success-circular-line"] {
    position: absolute;
    width: 1.6em;
    height: 3em;
    transform: rotate(45deg);
    border-radius: 50%;
}

.swal2-popup.swal2-toast .swal2-success [class^="swal2-success-circular-line"][class$="left"] {
    top: -0.8em;
    left: -0.5em;
    transform: rotate(-45deg);
    transform-origin: 2em 2em;
    border-radius: 4em 0 0 4em;
}

.swal2-popup.swal2-toast .swal2-success [class^="swal2-success-circular-line"][class$="right"] {
    top: -0.25em;
    left: 0.9375em;
    transform-origin: 0 1.5em;
    border-radius: 0 4em 4em 0;
}

.swal2-popup.swal2-toast .swal2-success .swal2-success-ring {
    width: 2em;
    height: 2em;
}

.swal2-popup.swal2-toast .swal2-success .swal2-success-fix {
    top: 0;
    left: 0.4375em;
    width: 0.4375em;
    height: 2.6875em;
}

.swal2-popup.swal2-toast .swal2-success [class^="swal2-success-line"] {
    height: 0.3125em;
}

.swal2-popup.swal2-toast .swal2-success [class^="swal2-success-line"][class$="tip"] {
    top: 1.125em;
    left: 0.1875em;
    width: 0.75em;
}

.swal2-popup.swal2-toast .swal2-success [class^="swal2-success-line"][class$="long"] {
    top: 0.9375em;
    right: 0.1875em;
    width: 1.375em;
}

.swal2-popup.swal2-toast.swal2-show {
    animation: n 0.5s;
}

.swal2-popup.swal2-toast.swal2-hide {
    animation: o 0.1s forwards;
}

.swal2-popup.swal2-toast .swal2-animate-success-icon .swal2-success-line-tip {
    animation: p 0.75s;
}

.swal2-popup.swal2-toast .swal2-animate-success-icon .swal2-success-line-long {
    animation: q 0.75s;
}

@keyframes n {
    0% {
        transform: translateY(-0.625em) rotate(2deg);
    }

    33% {
        transform: translateY(0) rotate(-2deg);
    }

    66% {
        transform: translateY(0.3125em) rotate(2deg);
    }

    to {
        transform: translateY(0) rotate(0);
    }
}

@keyframes o {
    to {
        transform: rotate(1deg);
        opacity: 0;
    }
}

@keyframes p {
    0% {
        top: 0.5625em;
        left: 0.0625em;
        width: 0;
    }

    54% {
        top: 0.125em;
        left: 0.125em;
        width: 0;
    }

    70% {
        top: 0.625em;
        left: -0.25em;
        width: 1.625em;
    }

    84% {
        top: 1.0625em;
        left: 0.75em;
        width: 0.5em;
    }

    to {
        top: 1.125em;
        left: 0.1875em;
        width: 0.75em;
    }
}

@keyframes q {
    0% {
        top: 1.625em;
        right: 1.375em;
        width: 0;
    }

    65% {
        top: 1.25em;
        right: 0.9375em;
        width: 0;
    }

    84% {
        top: 0.9375em;
        right: 0;
        width: 1.125em;
    }

    to {
        top: 0.9375em;
        right: 0.1875em;
        width: 1.375em;
    }
}

body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) {
    overflow: hidden;
}

body.swal2-height-auto {
    height: auto !important;
}

body.swal2-no-backdrop .swal2-shown {
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
    max-width: calc(100% - 0.625em * 2);
    background-color: transparent;
}

body.swal2-no-backdrop .swal2-shown>.swal2-modal {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
}

body.swal2-no-backdrop .swal2-shown.swal2-top {
    top: 0;
    left: 50%;
    transform: translateX(-50%);
}

body.swal2-no-backdrop .swal2-shown.swal2-top-left,
body.swal2-no-backdrop .swal2-shown.swal2-top-start {
    top: 0;
    left: 0;
}

body.swal2-no-backdrop .swal2-shown.swal2-top-end,
body.swal2-no-backdrop .swal2-shown.swal2-top-right {
    top: 0;
    right: 0;
}

body.swal2-no-backdrop .swal2-shown.swal2-center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

body.swal2-no-backdrop .swal2-shown.swal2-center-left,
body.swal2-no-backdrop .swal2-shown.swal2-center-start {
    top: 50%;
    left: 0;
    transform: translateY(-50%);
}

body.swal2-no-backdrop .swal2-shown.swal2-center-end,
body.swal2-no-backdrop .swal2-shown.swal2-center-right {
    top: 50%;
    right: 0;
    transform: translateY(-50%);
}

body.swal2-no-backdrop .swal2-shown.swal2-bottom {
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}

body.swal2-no-backdrop .swal2-shown.swal2-bottom-left,
body.swal2-no-backdrop .swal2-shown.swal2-bottom-start {
    bottom: 0;
    left: 0;
}

body.swal2-no-backdrop .swal2-shown.swal2-bottom-end,
body.swal2-no-backdrop .swal2-shown.swal2-bottom-right {
    right: 0;
    bottom: 0;
}

.swal2-container {
    display: -ms-flexbox;
    display: flex;
    position: fixed;
    z-index: 1060;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 0.625em;
    overflow-x: hidden;
    background-color: transparent;
    -webkit-overflow-scrolling: touch;
}

.swal2-container.swal2-top {
    -ms-flex-align: start;
    align-items: flex-start;
}

.swal2-container.swal2-top-left,
.swal2-container.swal2-top-start {
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

.swal2-container.swal2-top-end,
.swal2-container.swal2-top-right {
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.swal2-container.swal2-center {
    -ms-flex-align: center;
    align-items: center;
}

.swal2-container.swal2-center-left,
.swal2-container.swal2-center-start {
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

.swal2-container.swal2-center-end,
.swal2-container.swal2-center-right {
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.swal2-container.swal2-bottom {
    -ms-flex-align: end;
    align-items: flex-end;
}

.swal2-container.swal2-bottom-left,
.swal2-container.swal2-bottom-start {
    -ms-flex-align: end;
    align-items: flex-end;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

.swal2-container.swal2-bottom-end,
.swal2-container.swal2-bottom-right {
    -ms-flex-align: end;
    align-items: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.swal2-container.swal2-bottom-end> :first-child,
.swal2-container.swal2-bottom-left> :first-child,
.swal2-container.swal2-bottom-right> :first-child,
.swal2-container.swal2-bottom-start> :first-child,
.swal2-container.swal2-bottom> :first-child {
    margin-top: auto;
}

.swal2-container.swal2-grow-fullscreen>.swal2-modal {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex: 1;
    flex: 1;
    -ms-flex-item-align: stretch;
    align-self: stretch;
    -ms-flex-pack: center;
    justify-content: center;
}

.swal2-container.swal2-grow-row>.swal2-modal {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex: 1;
    flex: 1;
    -ms-flex-line-pack: center;
    align-content: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.swal2-container.swal2-grow-column {
    -ms-flex: 1;
    flex: 1;
    -ms-flex-direction: column;
    flex-direction: column;
}

.swal2-container.swal2-grow-column.swal2-bottom,
.swal2-container.swal2-grow-column.swal2-center,
.swal2-container.swal2-grow-column.swal2-top {
    -ms-flex-align: center;
    align-items: center;
}

.swal2-container.swal2-grow-column.swal2-bottom-left,
.swal2-container.swal2-grow-column.swal2-bottom-start,
.swal2-container.swal2-grow-column.swal2-center-left,
.swal2-container.swal2-grow-column.swal2-center-start,
.swal2-container.swal2-grow-column.swal2-top-left,
.swal2-container.swal2-grow-column.swal2-top-start {
    -ms-flex-align: start;
    align-items: flex-start;
}

.swal2-container.swal2-grow-column.swal2-bottom-end,
.swal2-container.swal2-grow-column.swal2-bottom-right,
.swal2-container.swal2-grow-column.swal2-center-end,
.swal2-container.swal2-grow-column.swal2-center-right,
.swal2-container.swal2-grow-column.swal2-top-end,
.swal2-container.swal2-grow-column.swal2-top-right {
    -ms-flex-align: end;
    align-items: flex-end;
}

.swal2-container.swal2-grow-column>.swal2-modal {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex: 1;
    flex: 1;
    -ms-flex-line-pack: center;
    align-content: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.swal2-container:not(.swal2-top):not(.swal2-top-start):not(.swal2-top-end):not(.swal2-top-left):not(.swal2-top-right):not(.swal2-center-start):not(.swal2-center-end):not(.swal2-center-left):not(.swal2-center-right):not(.swal2-bottom):not(.swal2-bottom-start):not(.swal2-bottom-end):not(.swal2-bottom-left):not(.swal2-bottom-right):not(.swal2-grow-fullscreen)>.swal2-modal {
    margin: auto;
}

.swal2-container.swal2-fade {
    transition: background-color 0.1s;
}

.swal2-container.swal2-shown {
    background-color: rgba(0, 0, 0, 0.4);
}

.swal2-popup {
    display: none;
    position: relative;
    box-sizing: border-box;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;
    width: 32em;
    max-width: 100%;
    padding: 1.25em;
    border: none;
    border-radius: 0.3125em;
    background: #fff;
    font-size: 1rem;
}

.swal2-popup:focus {
    outline: none;
}

.swal2-popup.swal2-loading {
    overflow-y: hidden;
}

.swal2-header {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-align: center;
    align-items: center;
}

.swal2-title {
    position: relative;
    max-width: 100%;
    margin: 0 0 0.4em;
    padding: 0;
    color: #595959;
    font-size: 1.875em;
    font-weight: 600;
    text-align: center;
    text-transform: none;
    word-wrap: break-word;
}

.swal2-actions {
    z-index: 1;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
    margin: 1.25em auto 0;
}

.swal2-actions:not(.swal2-loading) .swal2-styled[disabled] {
    opacity: 0.4;
}

.swal2-actions:not(.swal2-loading) .swal2-styled:hover {
    background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
}

.swal2-actions:not(.swal2-loading) .swal2-styled:active {
    background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
}

.swal2-actions.swal2-loading .swal2-styled.swal2-confirm {
    box-sizing: border-box;
    width: 2.5em;
    height: 2.5em;
    margin: 0.46875em;
    padding: 0;
    animation: r 1.5s linear 0s infinite normal;
    border: 0.25em solid transparent;
    border-radius: 100%;
    border-color: transparent;
    background-color: transparent !important;
    color: transparent;
    cursor: default;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.swal2-actions.swal2-loading .swal2-styled.swal2-cancel {
    margin-right: 30px;
    margin-left: 30px;
}

.swal2-actions.swal2-loading :not(.swal2-styled).swal2-confirm:after {
    content: "";
    display: inline-block;
    width: 15px;
    height: 15px;
    margin-left: 5px;
    animation: r 1.5s linear 0s infinite normal;
    border: 3px solid #999;
    border-radius: 50%;
    border-right-color: transparent;
    box-shadow: 1px 1px 1px #fff;
}

.swal2-styled {
    margin: 0.3125em;
    padding: 0.625em 2em;
    box-shadow: none;
    font-weight: 500;
}

.swal2-styled:not([disabled]) {
    cursor: pointer;
}

.swal2-styled.swal2-confirm {
    background: initial;
    background-color: #3085d6;
}

.swal2-styled.swal2-cancel,
.swal2-styled.swal2-confirm {
    border: 0;
    border-radius: 0.25em;
    color: #fff;
    font-size: 1.0625em;
}

.swal2-styled.swal2-cancel {
    background: initial;
    background-color: #aaa;
}

.swal2-styled:focus {
    outline: none;
    box-shadow: 0 0 0 2px #fff, 0 0 0 4px rgba(50, 100, 150, 0.4);
}

.swal2-styled::-moz-focus-inner {
    border: 0;
}

.swal2-footer {
    -ms-flex-pack: center;
    justify-content: center;
    margin: 1.25em 0 0;
    padding: 1em 0 0;
    border-top: 1px solid #eee;
    color: #545454;
    font-size: 1em;
}

.swal2-image {
    max-width: 100%;
    margin: 1.25em auto;
}

.swal2-close {
    position: absolute;
    top: 0;
    right: 0;
    -ms-flex-pack: center;
    justify-content: center;
    width: 1.2em;
    height: 1.2em;
    padding: 0;
    overflow: hidden;
    transition: color 0.1s ease-out;
    border: none;
    border-radius: 0;
    outline: initial;
    background: transparent;
    color: #ccc;
    font-size: 2.5em;
    line-height: 1.2;
    cursor: pointer;
}

.swal2-close:hover {
    transform: none;
    background: transparent;
    color: #f27474;
}

.swal2-checkbox,
.swal2-file,
.swal2-input,
.swal2-radio,
.swal2-select,
.swal2-textarea {
    display: none;
}

.swal2-content {
    z-index: 1;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    color: #545454;
    font-size: 1.125em;
    font-weight: 300;
    line-height: normal;
    word-wrap: break-word;
}

#swal2-content {
    text-align: center;
}

.swal2-checkbox,
.swal2-file,
.swal2-input,
.swal2-radio,
.swal2-select,
.swal2-textarea {
    margin: 1em auto;
}

.swal2-file,
.swal2-input,
.swal2-textarea {
    box-sizing: border-box;
    width: 100%;
    transition: border-color 0.3s, box-shadow 0.3s;
    border: 1px solid #d9d9d9;
    border-radius: 0.1875em;
    background: inherit;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.06);
    color: inherit;
    font-size: 1.125em;
}

.swal2-file.swal2-inputerror,
.swal2-input.swal2-inputerror,
.swal2-textarea.swal2-inputerror {
    border-color: #f27474 !important;
    box-shadow: 0 0 2px #f27474 !important;
}

.swal2-file:focus,
.swal2-input:focus,
.swal2-textarea:focus {
    border: 1px solid #b4dbed;
    outline: none;
    box-shadow: 0 0 3px #c4e6f5;
}

.swal2-file::-webkit-input-placeholder,
.swal2-input::-webkit-input-placeholder,
.swal2-textarea::-webkit-input-placeholder {
    color: #ccc;
}

.swal2-file::-moz-placeholder,
.swal2-input::-moz-placeholder,
.swal2-textarea::-moz-placeholder {
    color: #ccc;
}

.swal2-file:-ms-input-placeholder,
.swal2-file::-ms-input-placeholder,
.swal2-input:-ms-input-placeholder,
.swal2-input::-ms-input-placeholder,
.swal2-textarea:-ms-input-placeholder,
.swal2-textarea::-ms-input-placeholder {
    color: #ccc;
}

.swal2-file::placeholder,
.swal2-input::placeholder,
.swal2-textarea::placeholder {
    color: #ccc;
}

.swal2-range {
    margin: 1em auto;
    background: inherit;
}

.swal2-range input {
    width: 80%;
}

.swal2-range output {
    width: 20%;
    color: inherit;
    font-weight: 600;
    text-align: center;
}

.swal2-range input,
.swal2-range output {
    height: 2.625em;
    padding: 0;
    font-size: 1.125em;
    line-height: 2.625em;
}

.swal2-input {
    height: 2.625em;
    padding: 0 0.75em;
}

.swal2-input[type="number"] {
    max-width: 10em;
}

.swal2-file {
    background: inherit;
    font-size: 1.125em;
}

.swal2-textarea {
    height: 6.75em;
    padding: 0.75em;
}

.swal2-select {
    min-width: 50%;
    max-width: 100%;
    padding: 0.375em 0.625em;
    background: inherit;
    color: inherit;
    font-size: 1.125em;
}

.swal2-checkbox,
.swal2-radio {
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    background: inherit;
    color: inherit;
}

.swal2-checkbox label,
.swal2-radio label {
    margin: 0 0.6em;
    font-size: 1.125em;
}

.swal2-checkbox input,
.swal2-radio input {
    margin: 0 0.4em;
}

.swal2-validation-message {
    display: none;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 0.625em;
    overflow: hidden;
    background: #f0f0f0;
    color: #666;
    font-size: 1em;
    font-weight: 300;
}

.swal2-validation-message:before {
    content: "!";
    display: inline-block;
    width: 1.5em;
    min-width: 1.5em;
    height: 1.5em;
    margin: 0 0.625em;
    zoom: normal;
    border-radius: 50%;
    background-color: #f27474;
    color: #fff;
    font-weight: 600;
    line-height: 1.5em;
    text-align: center;
}

@supports (-ms-accelerator: true) {
    .swal2-range input {
        width: 100% !important;
    }

    .swal2-range output {
        display: none;
    }
}

@-moz-document url-prefix() {
    .swal2-close:focus {
        outline: 2px solid rgba(50, 100, 150, 0.4);
    }
}

.swal2-icon {
    position: relative;
    box-sizing: content-box;
    -ms-flex-pack: center;
    justify-content: center;
    width: 5em;
    height: 5em;
    margin: 1.25em auto 1.875em;
    zoom: normal;
    border: 0.25em solid transparent;
    border-radius: 50%;
    line-height: 5em;
    cursor: default;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.swal2-icon:before {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    height: 92%;
    font-size: 3.75em;
}

.swal2-icon.swal2-error {
    border-color: #f27474;
}

.swal2-icon.swal2-error .swal2-x-mark {
    position: relative;
    -ms-flex-positive: 1;
    flex-grow: 1;
}

.swal2-icon.swal2-error [class^="swal2-x-mark-line"] {
    display: block;
    position: absolute;
    top: 2.3125em;
    width: 2.9375em;
    height: 0.3125em;
    border-radius: 0.125em;
    background-color: #f27474;
}

.swal2-icon.swal2-error [class^="swal2-x-mark-line"][class$="left"] {
    left: 1.0625em;
    transform: rotate(45deg);
}

.swal2-icon.swal2-error [class^="swal2-x-mark-line"][class$="right"] {
    right: 1em;
    transform: rotate(-45deg);
}

.swal2-icon.swal2-warning {
    border-color: #facea8;
    color: #f8bb86;
}

.swal2-icon.swal2-warning:before {
    content: "!";
}

.swal2-icon.swal2-info {
    border-color: #9de0f6;
    color: #3fc3ee;
}

.swal2-icon.swal2-info:before {
    content: "i";
}

.swal2-icon.swal2-question {
    border-color: #c9dae1;
    color: #87adbd;
}

.swal2-icon.swal2-question:before {
    content: "?";
}

.swal2-icon.swal2-question.swal2-arabic-question-mark:before {
    content: "؟";
}

.swal2-icon.swal2-success {
    border-color: #a5dc86;
}

.swal2-icon.swal2-success [class^="swal2-success-circular-line"] {
    position: absolute;
    width: 3.75em;
    height: 7.5em;
    transform: rotate(45deg);
    border-radius: 50%;
}

.swal2-icon.swal2-success [class^="swal2-success-circular-line"][class$="left"] {
    top: -0.4375em;
    left: -2.0635em;
    transform: rotate(-45deg);
    transform-origin: 3.75em 3.75em;
    border-radius: 7.5em 0 0 7.5em;
}

.swal2-icon.swal2-success [class^="swal2-success-circular-line"][class$="right"] {
    top: -0.6875em;
    left: 1.875em;
    transform: rotate(-45deg);
    transform-origin: 0 3.75em;
    border-radius: 0 7.5em 7.5em 0;
}

.swal2-icon.swal2-success .swal2-success-ring {
    position: absolute;
    z-index: 2;
    top: -0.25em;
    left: -0.25em;
    box-sizing: content-box;
    width: 100%;
    height: 100%;
    border: 0.25em solid hsla(98, 55%, 69%, 0.3);
    border-radius: 50%;
}

.swal2-icon.swal2-success .swal2-success-fix {
    position: absolute;
    z-index: 1;
    top: 0.5em;
    left: 1.625em;
    width: 0.4375em;
    height: 5.625em;
    transform: rotate(-45deg);
}

.swal2-icon.swal2-success [class^="swal2-success-line"] {
    display: block;
    position: absolute;
    z-index: 2;
    height: 0.3125em;
    border-radius: 0.125em;
    background-color: #a5dc86;
}

.swal2-icon.swal2-success [class^="swal2-success-line"][class$="tip"] {
    top: 2.875em;
    left: 0.875em;
    width: 1.5625em;
    transform: rotate(45deg);
}

.swal2-icon.swal2-success [class^="swal2-success-line"][class$="long"] {
    top: 2.375em;
    right: 0.5em;
    width: 2.9375em;
    transform: rotate(-45deg);
}

.swal2-progress-steps {
    -ms-flex-align: center;
    align-items: center;
    margin: 0 0 1.25em;
    padding: 0;
    background: inherit;
    font-weight: 600;
}

.swal2-progress-steps li {
    display: inline-block;
    position: relative;
}

.swal2-progress-steps .swal2-progress-step {
    z-index: 20;
    width: 2em;
    height: 2em;
    border-radius: 2em;
    background: #3085d6;
    color: #fff;
    line-height: 2em;
    text-align: center;
}

.swal2-progress-steps .swal2-progress-step.swal2-active-progress-step {
    background: #3085d6;
}

.swal2-progress-steps .swal2-progress-step.swal2-active-progress-step~.swal2-progress-step {
    background: #add8e6;
    color: #fff;
}

.swal2-progress-steps .swal2-progress-step.swal2-active-progress-step~.swal2-progress-step-line {
    background: #add8e6;
}

.swal2-progress-steps .swal2-progress-step-line {
    z-index: 10;
    width: 2.5em;
    height: 0.4em;
    margin: 0 -1px;
    background: #3085d6;
}

[class^="swal2"] {
    -webkit-tap-highlight-color: transparent;
}

.swal2-show {
    animation: g 0.3s;
}

.swal2-show.swal2-noanimation {
    animation: none;
}

.swal2-hide {
    animation: h 0.15s forwards;
}

.swal2-hide.swal2-noanimation {
    animation: none;
}

.swal2-rtl .swal2-close {
    right: auto;
    left: 0;
}

.swal2-animate-success-icon .swal2-success-line-tip {
    animation: i 0.75s;
}

.swal2-animate-success-icon .swal2-success-line-long {
    animation: j 0.75s;
}

.swal2-animate-success-icon .swal2-success-circular-line-right {
    animation: k 4.25s ease-in;
}

.swal2-animate-error-icon {
    animation: m 0.5s;
}

.swal2-animate-error-icon .swal2-x-mark {
    animation: l 0.5s;
}

@keyframes r {
    0% {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(1turn);
    }
}

.app-header-mobile {
    height: 78px;
    padding: 0 2rem;
    background: #fbe9d8;
    border-radius: 20px;
    box-shadow: 0 0.313rem 0.75rem rgba(7, 9, 25, 0.05),
        0 0.126rem 0.15rem rgba(7, 9, 25, 0.03);
    -ms-flex-align: center;
    align-items: center;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    position: fixed;
    left: 0px;
    right: 0;
    z-index: 10;
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
}

#verification {
    color: white;
    border-radius: 5px;
    background-color: #EB9481;
    border: 3px solid #EB9481;
}

#phoneNumber {
    color: #EB9481;
    border-radius: 5px;
    border-color: rgba(248, 219, 199, 0.6);
    background-color: rgba(248, 219, 199, 0.6);
}

#otp {
    color: #EB9481;
    border-radius: 5px;
    border-color: rgba(248, 219, 199, 0.6);
    background-color: rgba(248, 219, 199, 0.6);
}

#otppadding {
    padding-top: 8px;
}

.slideshow {
    margin: 0 auto;
    overflow: hidden;
    background-repeat: no-repeat;
}

.slideshow-review {
    margin: 0 auto;
    overflow: hidden;
    max-width: 100%;
    width: 500px;
    background-repeat: no-repeat;
}

.slideshow1 {
    margin: 0 auto;
    overflow: hidden;
    width: 35%;
    background-repeat: no-repeat;
}

.slideshowSlider {
    white-space: nowrap;
    transition: ease 3000ms;
}

.slide {
    display: inline-block;
    background-repeat: no-repeat;
    background-position: center;
    height: 400px;
    width: 80%;
}

.slide-review {
    display: inline-block;
    background-repeat: no-repeat;
    background-position: center;
    height: 400px;
    width: 100%;
    border-radius: 5px;
}

.slide1 {
    display: inline-block;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    height: 200px;
    width: 100%;
}

#paginationnumbers {
    justify-content: center;
    align-items: center;
}

.leftnavbar-components {
    color: #356859;
}

#leftnavbar-components-id {
    color: #356859;
}

#dashboard-wallet-impact-quantity-box {
    background: #eafaf0;
    height: 150px;
    text-align: center;
    justify-content: center;
}

#dashboard-wallet-impact-quantity-text {
    color: #356859;
    font-weight: bold;
    text-align: left;
}

.aboutus-icon {
    font-size: 18px;
    color: white;
    border: 1px solid black;
    background-color: black;
    padding: 3px;
    border-radius: 5px;
}

#aboutustext1 {
    font-family: "Open Sans", sans-serif !important;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 30px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    color: #000000;
}

#aboutustext2 {
    font-family: "Open Sans", sans-serif !important;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    color: #000000;
    padding-right: 20px;
}

#aboutusheadings {
    font-family: "Open Sans", sans-serif !important;
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    text-align: center;
    color: rgba(8, 59, 124, 1);
    padding-left: 20px;
    padding-right: 20px;
}

#aboutusheadings2 {
    font-family: "Open Sans", sans-serif !important;
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    text-align: center;
    color: rgba(8, 59, 124, 1);
    background: #ffffff;
    border-bottom: 0.5px solid #77a1d7;
    box-shadow: inset 4px 2px 4px 2px #77a1d7;
    border-radius: 5px;
    width: 300px;
    height: 80px;
    margin: auto;
    padding-top: 11px;
    margin-top: 30px;
    margin-bottom: 20px;
}

#incentivelist-top-box {
    height: 180px;
    text-align: center;
    background-position: center;
}

#ratelist-top-box {
    height: 160px;
    text-align: center;
    background-position: center;
}

.activated-incentive {
    background: rgba(255, 255, 255, 0.49);
    border: 2px solid rgba(29, 115, 225, 0.61);
    box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.25);
}

.activated-rate {
    background: rgba(255, 255, 255, 0.49);
    border: 2px solid rgba(0, 0, 0);
    box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.25);
}

#pricinglist-top-box-text {
    font-weight: bold;
}

.contactus-top-form {
    align-items: center;
}

#pickup-page-image {
    border-radius: 5px;
}

.clickable {
    color: blue;
}

#pickup-top-left {
    font-size: large;
    color: #bfae56;
    font-weight: bold;
}

.pickups-up {
    font-size: 1.2rem;
    font-weight: bold;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 1rem;
    font-family: "Open Sans", sans-serif !important;
    font-style: normal;
    color: #356859;
    backdrop-filter: blur(2px);
    border-bottom: 2px solid rgba(7, 9, 25, 0.1);
    border-radius: 5px;
    background: rgba(217, 217, 217, 0.2);
}

.pickup-table {
    color: #E0944F;
    font-size: 1.2rem;
    font-weight: 500;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 1rem;
    border-bottom: 2px solid rgba(7, 9, 25, 0.1);
    border-radius: 5px;
    background: rgba(217, 217, 217, 0.2);
}

.pickups-table {
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 1rem;
    border-bottom: 2px solid rgba(7, 9, 25, 0.1);
    border-radius: 5px;
    background: rgba(217, 217, 217, 0.0);
}

#aboutus-icon-images-1 {
    padding-top: 40px;
    margin-left: 40px;
    max-width: 60px;
    max-height: 100px;
}

#aboutus-icon-images-2 {
    padding-top: 30px;
    margin-left: 45px;
    max-width: 60px;
    max-height: 100px;
}

.padding-peoplebehindecowrap {
    padding-top: 50%;
}

.contactus-page-heading {
    text-align: center;
    margin-top: 50px;
    margin-bottom: 50px;
}

.cu-page-head {
    text-decoration: underline dashed 2px;
    text-underline-offset: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 54px;
    text-align: center;
    color: #083b7c;
}

.cu-head-1 {
    text-decoration: underline dashed 1px;
    text-underline-offset: 10px;
    font-family: "Open Sans", sans-serif !important;
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 54px;
    text-align: center;
    color: #083b7c;
}

.cu-head-2 {
    text-decoration: underline dashed 1px #083b7c;
    text-underline-offset: 10px;
    font-family: "Open Sans", sans-serif !important;
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 54px;
    text-align: center;
    color: rgba(13, 145, 26, 1);
}

.btn-contactus {
    background: #6396d7;
    box-shadow: 0px 2.92062px 2.92062px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font-family: "Open Sans", sans-serif !important;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    text-align: center;
    color: #ffffff;
    padding-top: 0px;
    padding-bottom: 0px;
}

#services-1,
#services-3 {
    margin-top: 30%;
}

.product-image-pricinglist {
    height: 120px;
    width: 120px;
    margin-left: 25%;
    margin-top: 10px;
    margin-bottom: 10px;
}

.color-356859 {
    color: #356859;
}

.color-000000 {
    color: #000000;
}

.color-green {
    color: green;
}

.margin-auto {
    margin: auto;
}

.background-color-aboutus-peoplebehindecowrap {
    background-color: rgba(119, 161, 215, 0.15);
}

.mg-left--ve {
    margin-left: -40px;
}

.mg-top-pve {
    margin-top: 20px;
}

.mg-right--ve {
    margin-right: -40px;
}

.mg-bottom-pve {
    margin-bottom: 20px;
}

#aboutus-pbe-bkgimage {
    background-image: url("../public/assets/images/aboutUs/aboutus.png");
    background-repeat: no-repeat;
    background-position: center;
}

#contact .contact-head {
    font-size: 3rem;
    color: #eee4dd;
}

.align-right {
    text-align: right;
}

.align-left {
    text-align: left;
}

.align-center {
    text-align: center;
}

.btn-pickupschedule {
    padding: 10px 25px;
    border-radius: 5px;
    font-weight: 500;
    color: white;
    background: #80B0FF;
    text-decoration: none;
    transition: 0.5s;
}

.btn-pickupschedule-list {
    padding: 10px 25px;
    border-radius: 50px;
    font-weight: 500;
    color: #0053b1;
    background: #dee2e6;
    text-decoration: none;
    transition: 0.5s;
}

.btn-pickupschedule-list:disabled {
    padding: 10px 25px;
    border-radius: 50px;
    font-weight: 500;
    color: #0053b1;
    background: transparent;
    text-decoration: none;
    transition: 0.5s;
}

#schedulepickup-savedaddresses {
    font-size: large;
    font-weight: bold;
    color: #356859;
}

.schedulepickup-tabletop {
    font-weight: bold;
    font-size: 24px;
    font-family: "Open Sans", sans-serif !important;
    font-style: normal;
    line-height: 44px;
    color: #E0944F;
    backdrop-filter: blur(2px);
}

.schedulepickup-propertyname {
    color: #356859;
    font-size: 18px;
    font-weight: bold;
    font-family: "Open Sans", sans-serif !important;
    font-style: normal;
    line-height: 44px;
    color: #356859;
    backdrop-filter: blur(2px);
}

#schedule-box-head {
    background-color: #80B0FF;
    margin-bottom: 20px;
    border-radius: 2rem;
}

.propertyName {
    font-family: "Open Sans", sans-serif !important;
    font-style: normal;
    font-weight: bold;
    padding-top: 5px;
    padding-bottom: 5px;
}

#confirmpickup-head {
    font-family: "Open Sans", sans-serif !important;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    color: #80B0FF;
    backdrop-filter: blur(2px);
}

#confirmpickup-background {
    background: #FFFDF2;
    border-radius: 30px;
}

#confirmpickup-label {
    font-family: "Open Sans", sans-serif !important;
    font-style: normal;
    font-weight: bold;
    font-size: large;
    line-height: 34px;
    color: #356859;
    backdrop-filter: blur(2px);
}

.checkbox-round {
    width: 1.3em;
    height: 1.3em;
    background-color: white;
    border-radius: 50%;
    vertical-align: middle;
    border: 1px solid #356859;
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
}

.checkbox-round:checked {
    background-color: #356859;
}

.confirmpickup-img {
    max-height: 100%;
    max-width: 100%;
}

.addAddress-img {
    margin-top: 100px;
    width: 500px;
    max-height: 100%;
    max-width: 100%;
    position: fixed;
}

.br-2rem {
    background: rgba(217, 217, 217, 0.23);
    border-radius: 5px;
}

.span-property {
    font-family: "Open Sans", sans-serif !important;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    color: #356859;
    backdrop-filter: blur(2px);
}

.span-register {
    font-family: "Open Sans", sans-serif !important;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    color: #81B0FF;
    backdrop-filter: blur(2px);
}

.span-address {
    font-family: "Open Sans", sans-serif !important;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    color: #4F575B;
    backdrop-filter: blur(2px);
}

.span-map {
    font-family: "Open Sans", sans-serif !important;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    color: #7B8184;
    backdrop-filter: blur(2px);
}

.span-type-property {
    font-family: "Open Sans", sans-serif !important;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    color: #4F575B;
    backdrop-filter: blur(2px);
}

#addaddress-propertytype {
    font-family: "Open Sans", sans-serif !important;
    font-style: normal;
    font-weight: 500;
    background: #E3F4E9;
    border-radius: 2rem;
    width: 100%;
    height: 38px;
    line-height: 38px;
    text-align: center;
    color: #7B8184;
    backdrop-filter: blur(2px);
}

#audit-report {
    font-family: "Open Sans", sans-serif !important;
    font-style: normal;
    font-weight: 500;
    background: transparent;
    border-radius: 2rem;
    border: 1px solid gray;
    width: 100%;
    height: 38px;
    line-height: 38px;
    text-align: center;
    color: #7B8184;
    backdrop-filter: blur(2px);
}

.hotel-activated {
    border: 2px solid #3c44b1;
    padding-bottom: 10px;
}

.restaurant-bar-activated {
    border: 2px solid #4191ff;
    padding-bottom: 10px;
}

.cafe-activated {
    border: 2px solid #36a051;
    padding-bottom: 10px;
}

.educational-activated {
    border: 2px solid #7a7b97;
    padding-bottom: 10px;
}

.buisness-activated {
    border: 2px solid #11c5db;
    padding-bottom: 10px;
}

.retail-activated {
    border: 2px solid #793de6;
    padding-bottom: 10px;
}

.household-activated {
    border: 2px solid #000000;
    padding-bottom: 10px;
}

.impact-image {
    max-height: 150px;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 20px;
}

#impact-box {
    background-color: #e3f4e9;
    margin-bottom: 20px;
}

#impact-box-head {
    background-color: #fffbe6;
    margin-bottom: 20px;
    border-radius: 5px;
    width: 100%;
}

#profile-box-head {
    background-color: #fffbe6;
    margin-bottom: 20px;
    border-radius: 2rem;
}

.card-howToEarn {
    background: #fffbe6;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
    border-radius: 5px;
    padding: 40px 20px 40px 20px;
    max-width: 400px;
    align-items: center;
    justify-content: center;
    justify-items: center;
}

#howToEarnBox {
    background-color: #EAFAF0;
    margin-bottom: 20px;
}

.home-service-image {
    height: 200px;
    max-height: 250px;
    padding: 10px;
    max-width: 100%;
}

.home-service-image3 {
    margin: auto;
    max-width: 100%;
}

.route-home {
    color: rgba(0, 0, 0, 0.8);
    text-decoration: underline dashed 1px rgb(63, 145, 27);
    text-underline-offset: 10px;
    font-family: "Open Sans", sans-serif !important;
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    text-align: center;
}

.services-home {
    color: rgba(0, 0, 0, 0.8);
    text-decoration: underline dashed 1px rgb(63, 145, 27);
    text-underline-offset: 10px;
    font-family: "Open Sans", sans-serif !important;
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    text-align: center;
}

.we-make-a-diff-home-color {
    color: #083b7c;
}

.we-make-a-diff-home-color2 {
    color: #083b7c;
    text-decoration: underline dashed 1px rgb(63, 145, 27);
    text-underline-offset: 10px;
}

.we-make-a-diff-img {
    margin-top: 110px;
    width: 100%;
}

.lets-get-started-img {
    /* margin-top: 110px; */
    width: 100%;
}

.mobile-verify-img {
    margin-top: 50px;
    width: 100%;
}

.otp-verify-img {
    margin-top: 50px;
    width: 100%;
}

.download-app-image {
    max-width: 101%;
    margin-left: -1%;
}

.download-home-head {
    color: #189339;
    text-align: center;
    font-family: "Roboto", sans-serif !important;
    font-size: 35px;
    font-style: normal;
    font-weight: 500;
    line-height: 135.938%;
}

.download-home-sub-head {
    color: #000;
    font-family: "Roboto", sans-serif !important;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: 135.938%;
}

.scan-qr-to-download {
    color: #000;
    text-align: center;
    font-family: "Roboto", sans-serif !important;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 135.938%;
}

.mg-bottom-download {
    margin-bottom: 50px;
    margin-top: 30px;
}

.investment-partner-home {
    width: 300px;
    margin: auto;
}

.investment-partner-text {
    font-family: "Open Sans", sans-serif !important;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    text-align: center;

    color: #000000;
    padding-left: 50px;
    padding-right: 50px;
}

.footer-down-mission-image {
    width: 100%;
}

.footer-down-mission-statement {
    width: 100%;
    margin: auto;
}

.footer-down-content {
    padding-top: 80px;
}

.footer-background-image {
    width: 100%;
    height: 70%;
}

.footer-icons {
    font-size: 24px;
    color: blue;
}

.footer-icons {
    border: 1px solid black;
}

.footer-down-head {
    font-family: "Open Sans", sans-serif !important;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    text-align: center;
    color: #083b7c;
}

.footer-down-bottom {
    margin-top: 15px;
    margin-bottom: 15px;
}

.card-footer-down-text {
    color: #060606;
    text-align: center;
    font-family: "Roboto", sans-serif !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 135.938%;
}

.footer-down-mission-statement {
    color: #000;
    font-family: "Roboto", sans-serif !important;
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: 135.938%;
}

.footer-col-head {
    color: #189339;
    text-align: left;
    font-family: "Roboto", sans-serif !important;
    font-size: 22px;
    font-style: normal;
    font-weight: 800;
    line-height: 135.938%;
}

.footer-col-body {
    font-family: "Open Sans", sans-serif !important;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    text-align: left;
    color: #000000;
}

.mg-right-20 {
    margin-right: 20px;
}

.home-page-top-quote {
    font-family: "Open Sans", sans-serif !important;
    color: #FFF;
    text-align: center;
    font-style: normal;
    font-weight: 900;
    line-height: 135.938%;
}

.home-services-aboutour {
    font-family: "Open Sans", sans-serif !important;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    text-align: center;
    color: #102540;
}

.home-service-text {
    font-family: "Open Sans", sans-serif !important;
    font-style: normal;
    font-weight: 400;
    text-align: center;
    color: #000000;
}

.new-home-service-text-1 {
    font-size: 20px;
    align-items: center;
    display: "none";
    visibility: hidden;
}

.new-home-service-text-2 {
    font-size: 20px;
    align-items: center;
    display: "none";
}

.new-home-service-text-3 {
    font-size: 20px;
    align-items: center;
    display: "none";
}

.pt-20 {
    padding-top: 20px;
}

.footer-end-text {
    font-family: "Open Sans", sans-serif !important;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    text-align: center;
    color: #000000;
}

.blogs-top-heading {
    font-family: "Open Sans", sans-serif !important;
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    text-align: center;
    margin-top: 50px;
}

.clientspartners-top-heading {
    font-family: "Open Sans", sans-serif !important;
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    text-align: center;
    margin-top: 50px;
}

.clientspartners-top-text {
    font-family: "Open Sans", sans-serif !important;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: rgba(0, 0, 0, 0.75);
}

.clientspartners-sub-head {
    font-family: "Open Sans", sans-serif !important;
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 54px;
    width: 400px;
    max-width: 100%;
    text-align: center;
    color: #083b7c;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    margin: auto;
    margin-bottom: 30px;
}

.clpa1 {
    width: 200px;
    font-weight: 600;
}

.clpa2 {
    width: 400px;
    font-weight: 600;
}

.clpa3 {
    width: 299px;
    font-weight: 600;
}

.candp-text-card {
    font-family: "Open Sans", sans-serif !important;
    font-style: normal;
    font-weight: 500;
    text-align: center;
    padding: 10px 5px 10px 5px;
    color: #102540;
}

.clientspartners-hotel {
    font-family: "Open Sans", sans-serif !important;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    height: fit-content;
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: center;
    color: #ffffff;
    background: rgba(8, 59, 124, 0.83);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    min-height: 50px;
}

.clientspartners-bar {
    font-family: "Open Sans", sans-serif !important;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    height: fit-content;
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: center;
    color: #ffffff;
    background: rgba(13, 145, 26, 0.69);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    min-height: 50px;
}

.clientspartners-cafe {
    font-family: "Open Sans", sans-serif !important;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    height: fit-content;
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: center;
    color: #ffffff;
    background: rgba(8, 59, 124, 0.83);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    min-height: 50px;
}

.mediaawards-top-sub-head {
    font-family: "Open Sans", sans-serif !important;
    font-style: normal;
    font-weight: 500;
    line-height: 54px;
    color: #083b7c;
    text-align: left;
    text-decoration: underline dashed 2px;
    text-underline-offset: 10px;
}

.mediaawards-sub-head {
    font-family: "Open Sans", sans-serif !important;
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 54px;
    color: #083b7c;
    text-align: center;
    text-decoration: underline dashed 2px;
    text-underline-offset: 10px;
    padding-top: 60px;
    padding-bottom: 60px;
}

.mediaawards-top-text {
    font-family: "Open Sans", sans-serif !important;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: rgba(0, 0, 0, 0.75);
    margin: auto;
}

.mediaawards-top-text-margin {
    padding-left: 100px;
}

.mediaawards-awards-image {
    width: auto;
    height: 225px;
    z-index: 0;
    max-width: 460px;
    margin: auto;
}

.manda2 {
    padding-top: 50px;
}

.mediaawards-awards-text {
    color: #000000;
    background: rgba(217, 217, 217, 0.33);
    font-family: "Open Sans", sans-serif !important;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    text-align: center;
    height: 100px;
    max-width: 460px;
    margin: auto;
    padding-top: 20px;
    border-radius: 5px;
}

.awardsmedia-sub-sub-head {
    background: #ffffff;
    border: 1px solid rgba(29, 115, 225, 0.46);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font-family: "Open Sans", sans-serif !important;
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    text-align: center;
    color: #000000;
    width: 300px;
    max-width: 100%;
    margin: auto;
}

.aandm-head-1 {
    max-width: 100%;
    width: 475px;
    margin-top: 100px;
}

.awardsmedia-sub-sub-image {
    filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.25));
}

.aandm-image {
    max-width: 100%;
    width: 400px;
    margin: auto;
    border-radius: 5px;
}

.aandm-image-2 {
    width: 90%;
    height: 280px;
    border-radius: 5px;
}

.aandm-image-3 {
    width: 50%;
    max-height: 500px;
}

.awardsmedia-sub-sub-text {
    background: #ffffff;
    border: 1px solid rgba(29, 115, 225, 0.46);
    border-radius: 5px;
    font-family: "Open Sans", sans-serif !important;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    text-align: center;
    color: rgba(0, 0, 0, 0.75);
    width: 400px;
    max-width: 100%;
    margin: auto;
    padding: 20px;
    min-height: 100px;
}

.aandm-text-1 {
    width: 90%;
}

.rvw-photo-image {
    padding: 25px 0px 10px 10px;
    margin: auto;
}

.rvw-photo-image img {
    height: 5rem;
    width: 5rem;
    border-radius: 50%;
    margin: auto;
}

.what-they-say {
    background: linear-gradient(180deg, #cde6fe 0%, rgba(247, 251, 255, 0) 100%);
    margin: auto;
}

.wtkau-heading {
    font-family: "Open Sans", sans-serif !important;
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    text-align: center;
    margin: auto;
    margin-bottom: 50px;
    margin-top: 100px;
}

.content-review {
    padding: 50px 25px 0px 25px;
    font-family: "Open Sans", sans-serif !important;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    text-align: left;
    width: 100%;
    white-space: initial;
}

.head-review {
    font-family: "Open Sans", sans-serif !important;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    text-align: left;
    margin-top: 20px;
    white-space: initial;
}

.checked {
    color: orange;
}

.contactus-sub-image {
    width: 100%;
}

.contactus-getintouch-text {
    font-family: "Open Sans", sans-serif !important;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    text-align: center;
    color: rgba(0, 0, 0, 0.75);
}

.contactus-getintouch-head-text {
    font-family: "Open Sans", sans-serif !important;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    text-align: center;
    color: #0f4d9b;
}

.contactus-getintouch-head {
    font-family: "Open Sans", sans-serif !important;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    text-align: center;
    color: rgba(0, 0, 0, 0.75);
}

.recycleright-projects {
    background: url("../public/assets/images/projects/projects-recycle-right-background.png") no-repeat center center/cover;
    background-size: 100% 100%;
}

.muskaan-projects {
    background: url("../public/assets/images/projects/projects-muskaan-background.png") no-repeat center center/cover;
    background-size: 150% 100%;
}

.projects-sub-head {
    text-decoration: underline dashed 2px;
    font-family: "Open Sans", sans-serif !important;
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 48px;
    text-align: center;
    color: rgba(8, 59, 124, 1);
    margin-top: 100px;
}

.projects-sub-head-muskaan {
    text-decoration: underline dashed 2px;
    font-family: "Open Sans", sans-serif !important;
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 48px;
    text-align: center;
    color: rgba(8, 59, 124, 1);
    margin-top: 220px;
}

.projects-sub-text {
    font-family: "Open Sans", sans-serif !important;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 27px;
    color: #000000;
    text-align: left;
    margin-top: 20px;
}

.pd-left-100px {
    padding-left: 120px;
}

.btn-projects {
    background: #ffffff;
    border-bottom: 0.434812px solid rgba(43, 159, 55, 0.2);
    box-shadow: 3.47849px 1.73925px 3.47849px 1.73925px rgba(137, 137, 137, 0.56);
    border-radius: 5px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    text-align: center;
    color: #102540;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 10px;
}

.projects-sub-row {
    min-height: 450px;
}

.projects-sub-row-2 {
    min-height: 650px;
}

.project-muskaan-img {
    margin-top: 30px;
}

.project-recycle-img {
    margin-top: 160px;
}

.footer-gif {
    margin-top: 70px;
    width: 50%;
}

.btn-blog {
    color: #000;
    font-family: "Open Sans", sans-serif !important;
    font-style: normal;
    font-weight: bold;
    padding: 0px;
    /* text-align: center; */
    /* padding-left: 10px; */
    /* padding-right: 10px; */
    /* line-height: 18px; */
    /* border-radius: 30px; */
    /* background: rgba(8, 59, 124, 0.96); */
    /* border-bottom: 0.5px solid rgba(43, 159, 55, 0.2); */
    /* box-shadow: 4px 2px 4px 2px rgba(137, 137, 137, 0.56); */
}

.btn-blog:hover {
    color: #000;
    font-weight: bolder;
}

.trending-down-image {
    width: 200px;
}

.blogdetails-image {
    width: 100%;
}

.blog-blogs-image {
    width: 90%;
    max-height: 260px;
    border-radius: 5px;
    padding-bottom: 10px;
}

.blog-top-image {
    width: 100%;
}

.clientspartners-top-image {
    background: url("../public/assets/images/clientsAndPartners/clientspartners-top-bg.png") no-repeat center center/cover;
    background-size: 100% 100%;
}

.left-nav-list-item .active {
    background: #fbe9d8;
    border-radius: 2rem;
}

.left-nav-list-item {
    margin-left: 10px;
    font-family: "Open Sans", sans-serif !important;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    color: #455a64;
    backdrop-filter: blur(4px);
}

.left-nav-sub-history {
    color: #455a64;
}

.left-nav-list-logo {
    height: 60px;
}

.nav-dashboard-card {
    background: #fbe9d8;
    border: 2px solid #ec9986;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.nav-dashboard-sub-card {
    min-width: 150px;
    padding: 5px;
}

.nav-dashboard-sub-card-text {
    font-family: "Open Sans", sans-serif !important;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 34px;
    color: #eb9481;
}

.nav-dashboard-sub-card .active .nav-dashboard-sub-card-text {
    font-family: "Open Sans", sans-serif !important;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 34px;
    background: #eb9481;
    color: #fffdf2;
    border-radius: 5px;
}

.pt-0 {
    padding-top: 0px;
}

.wallet-top-card {
    margin: auto;
    max-width: 400px;
    background: rgba(217, 217, 217, 0.19);
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    height: 350px;
}

.wallet-page-top-image {
    margin: auto;
    width: 300px;
    padding: 20px;
}

.wallet-top-card-text {
    padding: 20px;
}

.wallet-page-top-text {
    font-family: "Open Sans", sans-serif !important;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    color: #356859;
    backdrop-filter: blur(4px);
}

.wallet-page-top-data {
    font-family: "Open Sans", sans-serif !important;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    color: #81b0ff;
}

.rotate-phone {
    transform: rotate(90deg);
}

.title-pbe {
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 33px;
    text-align: center;
    padding-top: 20px;
    color: #102540;
}

.designation-pbe {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #000000;
}

.muskaan-intext-image {
    width: 100%;
    padding-right: 100px;
}

.color-black {
    color: black;
}

.width-100percent {
    width: 100%;
}

.home-wmad-text {
    font-weight: 600;
}

.max-width-100percent {
    max-width: 100%;
}

.width-60percent {
    width: 60%;
}

.our-process {
    font-family: "Roboto", sans-serif !important;
    font-size: 30px;
    font-style: normal;
    font-weight: 800;
}

.our-process-span1 {
    color: #000;
}

.our-process-span2 {
    color: #189339;
}

.our-investors {
    font-family: "Roboto", sans-serif !important;
    font-size: 30px;
    font-style: normal;
    font-weight: 800;
    text-align: center;
}

.our-investors-span1 {
    color: #000;
}

.our-investors-span2 {
    color: #189339;
}


.home-page-headings {
    text-decoration: underline dashed 2px;
    text-underline-offset: 10px;
    font-weight: 500;
    font-size: 40px;
    line-height: 60px;
    text-align: center;
    color: #083b7c;
}

.img-route {
    margin: auto;
    width: 100%;
    max-height: 500px;
    max-width: 1500px;
}

.img-home-process {
    margin: auto;
    width: 100%;
    max-height: 700px;
    max-width: 1500px;
}

.max-height-200px {
    max-height: 200px;
}

.max-height-150px {
    max-height: 150px;
    min-height: 150px;
}

/* new csss */
.picture {
    position: relative;
    cursor: pointer;
    display: inline-grid;
    overflow: hidden;
}

.picture::after {
    content: "";
    position: absolute;
    inset: -1px;
    --_g: conic-gradient(at 50% 25%, rgb(0 0 0/68%) 75%, #0000 0) no-repeat;
    background: var(--_g) 0 calc(34% - var(--_p, 0%)) / 50.1% 400%;
    transition: 0.5s linear;

}

.picture.alt::after {
    transform: scale(-1);
}

.picture:hover::after {
    --_p: 134%;
}

.body1 {
    margin: 0;
    min-height: 100vh;
    display: grid;
    grid-auto-flow: column;
    grid-gap: 30px;
    place-content: center;
    background: #a8dba8;
}

.rating-icon {
    height: 20px;
    width: 20px;
    margin-bottom: 6px;
}

.graph_secion {
    width: 1000px;
}

.sidebar {
    width: 280px;
    min-height: 100vh;
    box-shadow: 0px 4px 8px rgb(0 0 0 / 16%);
    background-color: #fff;
    position: fixed;
    top: 0;
    left: -100%;
    z-index: 1;
    transition: 0.5s;
}

.sidebar.active {
    left: 0;
}

.sd-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
}

.sidebar-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    transition: 0.5s;
    opacity: 0;
    visibility: hidden;
}

.sidebar-overlay.active {
    opacity: 1;
    visibility: visible;
}

.sd-body {
    padding: 15px;
    max-height: calc(100vh - 67px);
    overflow-x: hidden;
}

.wallet-btn {
    float: right;
    margin-right: 25px;
}

.text-blue {
    color: #4191ff;
}

.text-green {
    color: #1bc943;
}

.text-warnings {
    color: #f4772e;
}

.text-red {
    color: #f83245;
}

/* .main_div {
    width: 50%;
    height: 100vh;
    background: #badc58;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
} */

.center_div {
    background-color: #000000;
    text-align: center;
}

.h1-tag {
    color: white;
    background: transparent;
    font-size: 50px;
    background-color: #30336b;
    margin-bottom: 10px;
    width: 100%;
    box-shadow: 5px 5px 15px -5px rgba(0, 0, 0, 0.3);
    font-size: 20px;
}

.confirm-tag {
    color: white;
    background: transparent;
    font-size: 50px;
    background-color: #30336b;
    margin-bottom: 10px;
    width: 100%;
    box-shadow: 5px 5px 15px -5px rgba(0, 0, 0, 0.3);
    font-size: 20px;
}

.btn_div {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.btn-tag {
    min-height: 40px;
    border-radius: 10%;
    border-color: transparent;
    background-color: #30336b;
    color: #fff;
    font-size: 20px;
    border: none;
    outline: none;
    padding: 5px 15px;
    margin-top: 30px;
    margin-bottom: 30px;
    box-shadow: 5px 5px 15px -5px rgba(0, 0, 0, 0.3);
}

.btn-tag:hover {
    background-color: #22a6b3;
}

.container-dashboard-left {
    width: 98%;
    padding-right: 10px;
    padding-left: 10px;
    margin-right: auto;
    margin-left: auto;
    border-radius: 5px;
    background-color: #e8eff3;
}

.container-dashboard-right {
    width: 100%;
    padding-right: 10px;
    padding-left: 10px;
    margin-right: auto;
    margin-left: auto;
    border-radius: 5px;
    background-color: #f2dede;
}

.rating {
    display: flex;
    width: 100%;
    justify-content: center;
    overflow: hidden;
    flex-direction: row-reverse;
    height: 150px;
    position: relative;
}

.rating-0 {
    filter: grayscale(100%);
}

.rating>input {
    display: none;
}

.rating>label {
    cursor: pointer;
    width: 40px;
    height: 40px;
    margin-top: auto;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='126.729' height='126.73'%3e%3cpath fill='%23e3e3e3' d='M121.215 44.212l-34.899-3.3c-2.2-.2-4.101-1.6-5-3.7l-12.5-30.3c-2-5-9.101-5-11.101 0l-12.4 30.3c-.8 2.1-2.8 3.5-5 3.7l-34.9 3.3c-5.2.5-7.3 7-3.4 10.5l26.3 23.1c1.7 1.5 2.4 3.7 1.9 5.9l-7.9 32.399c-1.2 5.101 4.3 9.3 8.9 6.601l29.1-17.101c1.9-1.1 4.2-1.1 6.1 0l29.101 17.101c4.6 2.699 10.1-1.4 8.899-6.601l-7.8-32.399c-.5-2.2.2-4.4 1.9-5.9l26.3-23.1c3.8-3.5 1.6-10-3.6-10.5z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 76%;
    transition: .3s;
}

.rating>input:checked~label,
.rating>input:checked~label~label {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='126.729' height='126.73'%3e%3cpath fill='%23fcd93a' d='M121.215 44.212l-34.899-3.3c-2.2-.2-4.101-1.6-5-3.7l-12.5-30.3c-2-5-9.101-5-11.101 0l-12.4 30.3c-.8 2.1-2.8 3.5-5 3.7l-34.9 3.3c-5.2.5-7.3 7-3.4 10.5l26.3 23.1c1.7 1.5 2.4 3.7 1.9 5.9l-7.9 32.399c-1.2 5.101 4.3 9.3 8.9 6.601l29.1-17.101c1.9-1.1 4.2-1.1 6.1 0l29.101 17.101c4.6 2.699 10.1-1.4 8.899-6.601l-7.8-32.399c-.5-2.2.2-4.4 1.9-5.9l26.3-23.1c3.8-3.5 1.6-10-3.6-10.5z'/%3e%3c/svg%3e");
}


.rating>input:not(:checked)~label:hover,
.rating>input:not(:checked)~label:hover~label {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='126.729' height='126.73'%3e%3cpath fill='%23d8b11e' d='M121.215 44.212l-34.899-3.3c-2.2-.2-4.101-1.6-5-3.7l-12.5-30.3c-2-5-9.101-5-11.101 0l-12.4 30.3c-.8 2.1-2.8 3.5-5 3.7l-34.9 3.3c-5.2.5-7.3 7-3.4 10.5l26.3 23.1c1.7 1.5 2.4 3.7 1.9 5.9l-7.9 32.399c-1.2 5.101 4.3 9.3 8.9 6.601l29.1-17.101c1.9-1.1 4.2-1.1 6.1 0l29.101 17.101c4.6 2.699 10.1-1.4 8.899-6.601l-7.8-32.399c-.5-2.2.2-4.4 1.9-5.9l26.3-23.1c3.8-3.5 1.6-10-3.6-10.5z'/%3e%3c/svg%3e");
}

.emoji-wrapper {
    width: 100%;
    text-align: center;
    height: 100px;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
}

.emoji-wrapper:before,
.emoji-wrapper:after {
    content: "";
    height: 15px;
    width: 100%;
    position: absolute;
    left: 0;
    z-index: 1;
}

.emoji-wrapper:before {
    top: 0;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 35%, rgba(255, 255, 255, 0) 100%);
}

.emoji-wrapper:after {
    bottom: 0;
    background: linear-gradient(to top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 35%, rgba(255, 255, 255, 0) 100%);
}

.emoji {
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: .3s;
}

.emoji>svg {
    margin: 15px 0;
    width: 70px;
    height: 70px;
    flex-shrink: 0;
}

#rating-1:checked~.emoji-wrapper>.emoji {
    transform: translateY(-100px);
}

#rating-2:checked~.emoji-wrapper>.emoji {
    transform: translateY(-200px);
}

#rating-3:checked~.emoji-wrapper>.emoji {
    transform: translateY(-300px);
}

#rating-4:checked~.emoji-wrapper>.emoji {
    transform: translateY(-400px);
}

#rating-5:checked~.emoji-wrapper>.emoji {
    transform: translateY(-500px);
}

.feedback {
    max-width: 360px;
    background-color: #fff;
    width: 100%;
    padding: 30px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    box-shadow: 0 4px 30px rgba(0, 0, 0, .05);
}

.social-icon {
    display: inline-block;
    margin: 0 8px;
    transition: transform 0.3s ease-in-out;
}

.social-icon:hover {
    transform: scale(1.08);
}

.a-divider.a-divider-break h5 {
    line-height: 1;
    font-size: 14px;
    color: #767676;
    font-weight: 400;
    z-index: 2;
    position: relative;
    display: inline-block;
    background-color: #fff;
    padding: 0 8px 0 8px;
}